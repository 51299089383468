import './AuthAndRegPage.css'
import React, {useEffect, useMemo, useState} from "react";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";
import PlainInput from "../../shared_components/inputs/plain_input/PlainInput";
import Loader from "../../shared_components/loader/Loader";
import {
  getPasswordErrorMessage,
  validateEmail,
  validatePassword
} from "../../shared_components/inputs/validators/InputValidators";
import PasswordInput from "../../shared_components/inputs/password_input/PasswordInput";
import CheckBox from "../../shared_components/buttons/checkbox/CheckBox";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {loginUser} from "../../../redux/user_info_reducer/UserInfoReducer";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import {displayFirstProfileSheet} from "../../../redux/profile_bottom_sheet_reducer/ProfileBottomSheetReducer";
import Logo from "../../shared_components/logo/Logo";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";

export enum AuthSteps {
  ENTER_EMAIL,
  ENTER_EXISTING_PASS,
  REGISTER_NEW_ACCOUNT
}

const AuthAndRegPage: React.FC = () => {
  // page actions
  const [authStep, setAuthStep] = useState(AuthSteps.ENTER_EMAIL)
  const [isLoading, setLoading] = useState(false)
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false)

  // user data
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeatedPassword] = useState('')
  const [rememberPass, setRememberPass] = useState(true)

  // pass hints
  const invalidPassHint = useMemo(() => getPasswordErrorMessage(password), [password])
  const [incorrectPassHint, setIncorrectPasswordHint] = useState<undefined | string>(undefined)
  const invalidRepeatedPasswordHint = 'Пароли не совпадают'

  // service functions
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0 })
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, []);

  const headerText = useMemo(() => {
    switch (authStep) {
      case AuthSteps.ENTER_EMAIL:
        return 'Вход или регистрация'
      case AuthSteps.ENTER_EXISTING_PASS:
        return 'Вход'
      case AuthSteps.REGISTER_NEW_ACCOUNT:
        return 'Регистрация'
    }
  }, [authStep])

  const onNextButtonClick = () => {
    setSubmitButtonPressed(true)
    if (authStep === AuthSteps.ENTER_EMAIL) {
      if (validateEmail(email)) {
        // TODO - replace with the actual backend request
        setLoading(true)
        setTimeout(() => {
          if (email === 'gameloftfun@gmail.com') {
            setAuthStep(AuthSteps.ENTER_EXISTING_PASS)
          } else {
            setAuthStep(AuthSteps.REGISTER_NEW_ACCOUNT)
          }
          setSubmitButtonPressed(false)
          setLoading(false)
        }, 1000)
      }
    } else if (authStep === AuthSteps.ENTER_EXISTING_PASS) {
      if (password?.length > 0) {
        // TODO - replace with the actual backend request
        setLoading(true)
        setTimeout(() => {
          if (password !== '123') {
            setIncorrectPasswordHint('Неверный пароль')
          } else {
            setSubmitButtonPressed(false)
            dispatch(loginUser(email, 'test-token-auth', name))
            navigate(RoutePaths.HOME)
          }
          setLoading(false)
        }, 1000)
      }
    } else {
      if (password === repeatedPassword && validatePassword(password)) {
        // TODO - replace with the actual backend request
        setLoading(true)
        setTimeout(() => {
          setSubmitButtonPressed(false)
          dispatch(loginUser(email, 'test-token-reg', name))
          navigate(RoutePaths.HOME)
          dispatch(displayFirstProfileSheet())
          setLoading(false)
        }, 1000)
      }
    }
  }

  const renderMobile = useMemo(() => {
    return(
      <div
        className={'auth-and-reg-page-wrapper'}
        style={{ height: window.innerHeight }}
        onKeyPress={(e) => {
          if (e?.key === 'Enter' && authStep === AuthSteps.ENTER_EMAIL) {
            onNextButtonClick()
          }
        }}
      >
        {isLoading ? (
          <div style={{ height: '100vh', display: "flex"}}>
            <Loader/>
          </div>
        ) : (
          <>
            <div className={'main-wrapper'}>
              <Logo/>
              <div className={'mobile-h3'}>{headerText}</div>
              <div style={{ marginTop: '36px', width: '100%', display: 'flex' }}>
                {authStep === AuthSteps.ENTER_EMAIL ? (
                  <PlainInput
                    placeholderText={'E-mail'}
                    onInputChange={email => setEmail(email)}
                    validationFunc={email => validateEmail(email)}
                    isSubmitButtonClicked={submitButtonPressed}
                    onFocus={() => setSubmitButtonPressed(false)}
                    invalidTextHint={'Email должен быть в формате example@ya.ru'}
                  />
                ) : (authStep === AuthSteps.ENTER_EXISTING_PASS ? (
                  <div style={{ display: 'flex', gap: '16px', width: '100%', flexDirection: 'column'}}>
                    <div
                      className={'greetings mobile-main-text'}
                      dangerouslySetInnerHTML={{__html: `Добро пожаловать,<br> ${email}` }}
                    />
                    <PasswordInput
                      placeholder={'Введите пароль'}
                      onInputChange={pass => setPassword(pass)}
                      isSubmitButtonClicked={submitButtonPressed}
                      validationFunc={pass => pass?.length > 0}
                      invalidHint={incorrectPassHint ? incorrectPassHint : 'Введите пароль'}
                      onFocus={() => {
                        setIncorrectPasswordHint(undefined)
                        setSubmitButtonPressed(false)
                      }}
                    />
                    <CheckBox
                      onCheckAction={() => setRememberPass(true)}
                      onUncheckAction={() => setRememberPass(false)}
                      checked={rememberPass}
                      text={'Запомнить меня'}
                    />
                  </div>
                ) : (
                  <div style={{ display: 'flex', gap: '8px', width: '100%', flexDirection: 'column'}}>
                    <PlainInput
                      placeholderText={'Ваше имя'}
                      onInputChange={name => setName(name)}
                      validationFunc={() => true}
                      isSubmitButtonClicked={submitButtonPressed}
                      onFocus={() => setSubmitButtonPressed(false)}
                      invalidTextHint={'ok'}
                    />
                    <PasswordInput
                      placeholder={'Введите пароль'}
                      onInputChange={pass => setPassword(pass)}
                      isSubmitButtonClicked={submitButtonPressed}
                      validationFunc={(pass) => validatePassword(pass)}
                      invalidHint={invalidPassHint}
                    />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        onNextButtonClick()
                      }}
                    >
                      <PasswordInput
                        placeholder={'Повторите пароль'}
                        onInputChange={pass => setRepeatedPassword(pass)}
                        isSubmitButtonClicked={submitButtonPressed}
                        validationFunc={(rp) => rp === password}
                        invalidHint={invalidRepeatedPasswordHint}
                      />
                    </form>
                  </div>
                ))}
              </div>
            </div>
            <div className={'reg-btns-wrapper'}>
              {authStep !== AuthSteps.ENTER_EMAIL && (
                <StandardButton
                  onClickAction={() => {
                    setSubmitButtonPressed(false)
                    setAuthStep(AuthSteps.ENTER_EMAIL)
                  }}
                  text={'Другой e-mail'}
                  color={StandardButtonColor.GRAY}
                  iconType={StandardButtonIconType.BLACK_LEFT_ARROW}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
              )}
              <StandardButton
                onClickAction={onNextButtonClick}
                text={'Продолжить'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.NO_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </>
        )}
      </div>
    )
  }, [authStep, email, headerText, incorrectPassHint, invalidPassHint, isLoading, password, rememberPass, submitButtonPressed, onNextButtonClick])

  const renderDesktop = useMemo(() => {
    return(
      <div
        className={'auth-and-reg-page-wrapper-desktop'}
        style={{ height: window.innerHeight }}
        onKeyPress={(e) => {
          if (e?.key === 'Enter' && authStep === AuthSteps.ENTER_EMAIL) {
            onNextButtonClick()
          }
        }}
      >
        <HeaderDesktop withBackButton={true}/>
        {isLoading ? (
          <div style={{ height: '100vh', display: "flex"}}>
            <Loader/>
          </div>
        ) : (
          <div className={'auth-and-reg-page-wrapper-desktop-popup'} style={{ width: Math.min(784, window.innerWidth / 1.4) }}>
            <div className={'header-desktop-wrapper'}>
              <div className={'desktop-h2'}>{headerText}</div>
            </div>
            {authStep === AuthSteps.ENTER_EMAIL ? (
              <PlainInput
                placeholderText={'E-mail'}
                onInputChange={email => setEmail(email)}
                validationFunc={email => validateEmail(email)}
                isSubmitButtonClicked={submitButtonPressed}
                onFocus={() => setSubmitButtonPressed(false)}
                invalidTextHint={'Email должен быть в формате example@ya.ru'}
              />
            ) : (authStep === AuthSteps.ENTER_EXISTING_PASS ? (
              <>
                <div className={'desktop-main-text'}>{`Добро пожаловать, ${email}`}</div>
                <PasswordInput
                  placeholder={'Введите пароль'}
                  onInputChange={pass => setPassword(pass)}
                  isSubmitButtonClicked={submitButtonPressed}
                  validationFunc={pass => pass?.length > 0}
                  invalidHint={incorrectPassHint ? incorrectPassHint : 'Введите пароль'}
                  onFocus={() => {
                    setIncorrectPasswordHint(undefined)
                    setSubmitButtonPressed(false)
                  }}
                />
                <CheckBox
                  onCheckAction={() => setRememberPass(true)}
                  onUncheckAction={() => setRememberPass(false)}
                  checked={rememberPass}
                  text={'Запомнить меня'}
                />
              </>
            ) : (
              <div style={{ display: 'flex', gap: '8px', width: '100%', flexDirection: 'column'}}>
                <PlainInput
                  placeholderText={'Ваше имя'}
                  onInputChange={name => setName(name)}
                  validationFunc={() => true}
                  isSubmitButtonClicked={submitButtonPressed}
                  onFocus={() => setSubmitButtonPressed(false)}
                  invalidTextHint={'ok'}
                />
                <PasswordInput
                  placeholder={'Введите пароль'}
                  onInputChange={pass => setPassword(pass)}
                  isSubmitButtonClicked={submitButtonPressed}
                  validationFunc={(pass) => validatePassword(pass)}
                  invalidHint={invalidPassHint}
                />
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    onNextButtonClick()
                  }}
                >
                  <PasswordInput
                    placeholder={'Повторите пароль'}
                    onInputChange={pass => setRepeatedPassword(pass)}
                    isSubmitButtonClicked={submitButtonPressed}
                    validationFunc={(rp) => rp === password}
                    invalidHint={invalidRepeatedPasswordHint}
                  />
                </form>
              </div>
            ))}
            <div className={`desktop-reg-buttons-wrapper ${authStep !== AuthSteps.ENTER_EMAIL && 'double'}`}>
              {authStep !== AuthSteps.ENTER_EMAIL && (
                <StandardButton
                  onClickAction={() => {
                    setSubmitButtonPressed(false)
                    setAuthStep(AuthSteps.ENTER_EMAIL)
                  }}
                  text={'Другой e-mail'}
                  color={StandardButtonColor.GRAY}
                  iconType={StandardButtonIconType.BLACK_LEFT_ARROW}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
              )}
              <StandardButton
                onClickAction={() => {
                  onNextButtonClick()
                }}
                text={'Продолжить'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.NO_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </div>
        )}
      </div>
    )
  }, [authStep, email, headerText, incorrectPassHint, invalidPassHint, isLoading, password, rememberPass, submitButtonPressed, onNextButtonClick])

  return isMobile ? renderMobile : renderDesktop
}

export default AuthAndRegPage
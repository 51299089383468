import {MenuSectionTag} from "./MenuSectionTag";
import {RestaurantInfoTag} from "./RestaurantInfoTag";
import {FoodPositionInfo} from "./FoodInfo";

export enum MediumCheck {
  SMALL,
  MEDIUM,
  BIG
}

export interface RestaurantInfo {
  name: string;
  image: string;
  address: string;
  description: string;
  openingTime: Date;
  closingTime: Date;
  rate: number;
  mediumCheck: MediumCheck;
  foodPositions: FoodPositionInfo[];
  menuSectionsTags: MenuSectionTag[];
  restaurantInfoTags: RestaurantInfoTag[];
}
import './DownloadButton.css'
import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";

export enum DownloadButtonType {
  GOOGLE_PLAY,
  APP_STORE
}

interface DownloadButtonProps {
  type: DownloadButtonType;
  linkToOpen: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ type, linkToOpen}) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      className={'animation-02s-all download-btn-wrapper'}
      onClick={() => window.open(linkToOpen, '_blank')}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing:'border-box',
        backgroundColor: isClicked
          ? 'var(--main-ym-green, #00DF5F)'
          : isHovered ? 'var(--background-light-green, #CCF9DF)' : 'var(--background-green-light-1, #E7FFF1)',
        cursor: isClicked || isHovered ? 'pointer' : undefined
      }}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
    >
      {type === DownloadButtonType.GOOGLE_PLAY && (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={'animation-02s-all'}>
          <path className={'animation-01s-all'} d="M1.95279 22.4629C0.990005 22.4629 0 23.244 0 24.6112C0 25.8611 0.870441 26.7439 1.95279 26.7439C2.84621 26.7439 3.24439 26.1368 3.24439 26.1368V26.4014C3.24439 26.5265 3.3612 26.666 3.50579 26.666H4.15159V22.5563H3.24439V23.0778C3.24439 23.0778 2.84287 22.4629 1.95279 22.4629ZM2.11439 23.3033C2.90697 23.3033 3.32272 24.009 3.32272 24.6109C3.32272 25.2813 2.82909 25.918 2.11607 25.918C1.52003 25.918 0.92297 25.4293 0.92297 24.602C0.92297 23.8553 1.43678 23.3033 2.11439 23.3033Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M5.41252 26.6658C5.27314 26.6658 5.15112 26.5646 5.15112 26.4012V22.5561H6.05832V23.0647C6.26389 22.7518 6.66551 22.4614 7.28196 22.4614C8.28953 22.4614 8.82605 23.2746 8.82605 24.035V26.6658H8.19562C8.03032 26.6658 7.91885 26.5257 7.91885 26.3856V24.2373C7.91885 23.8159 7.66378 23.304 7.07387 23.304C6.43734 23.304 6.05833 23.913 6.05833 24.4864V26.6658L5.41252 26.6658Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M11.4706 22.4629C10.5078 22.4629 9.51782 23.244 9.51782 24.6112C9.51782 25.8611 10.3883 26.7439 11.4706 26.7439C12.364 26.7439 12.7622 26.1368 12.7622 26.1368V26.4014C12.7622 26.5265 12.879 26.6661 13.0236 26.6661H13.6694V20.5015H12.7622V23.0778C12.7622 23.0778 12.3607 22.4629 11.4706 22.4629ZM11.6322 23.3033C12.4248 23.3033 12.8405 24.009 12.8405 24.6109C12.8405 25.2814 12.3469 25.9181 11.6339 25.9181C11.0378 25.9181 10.4408 25.4293 10.4408 24.6021C10.4408 23.8553 10.9546 23.3033 11.6322 23.3033Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M14.9303 26.6657C14.791 26.6657 14.6689 26.5645 14.6689 26.4011V22.556H15.5761V23.2409C15.7323 22.8572 16.0692 22.5093 16.6679 22.5093C16.8347 22.5093 16.9908 22.5404 16.9908 22.5404V23.49C16.9908 23.49 16.7959 23.4122 16.5602 23.4122C15.9237 23.4122 15.5761 24.0212 15.5761 24.5946V26.6657H14.9303Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M22.4953 26.6664C22.3559 26.6664 22.2339 26.5652 22.2339 26.4017V22.5566H23.1411V26.6664L22.4953 26.6664Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M25.8012 22.4629C24.8384 22.4629 23.8484 23.244 23.8484 24.6112C23.8484 25.8611 24.7188 26.7439 25.8012 26.7439C26.6946 26.7439 27.0928 26.1368 27.0928 26.1368V26.4014C27.0928 26.5265 27.2096 26.6661 27.3542 26.6661H28V20.5015H27.0928V23.0778C27.0928 23.0778 26.6913 22.4629 25.8012 22.4629ZM25.9628 23.3033C26.7554 23.3033 27.1711 24.009 27.1711 24.6109C27.1711 25.2814 26.6775 25.9181 25.9645 25.9181C25.3684 25.9181 24.7714 25.4293 24.7714 24.6021C24.7714 23.8553 25.2852 23.3033 25.9628 23.3033Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M22.6811 21.6702C23.0124 21.6702 23.281 21.3983 23.281 21.0629C23.281 20.7275 23.0124 20.4556 22.6811 20.4556C22.3499 20.4556 22.0813 20.7275 22.0813 21.0629C22.0813 21.3983 22.3499 21.6702 22.6811 21.6702Z" fill="#00DF5F"/>
          <path className={'animation-01s-all'} d="M19.3834 22.4614C18.3751 22.4614 17.2673 23.2234 17.2673 24.6038C17.2673 25.8616 18.2109 26.7439 19.3812 26.7439C20.8235 26.7439 21.5277 25.5701 21.5277 24.6117C21.5277 23.4358 20.6209 22.4614 19.3834 22.4614ZM19.3867 23.3191C20.084 23.3191 20.6041 23.8881 20.6041 24.6064C20.6041 25.3371 20.0519 25.9009 19.3888 25.9009C18.7731 25.9009 18.1747 25.3937 18.1747 24.6186C18.1747 23.8306 18.7439 23.3191 19.3867 23.3191Z" fill={isClicked ? 'white' : '#00DF5F'}/>
          <path className={'animation-01s-all'} d="M20.8334 6.78637L23.1545 2.71559C23.2855 2.48738 23.2063 2.19749 22.9839 2.06488C22.7616 1.93227 22.4722 2.00937 22.3443 2.23758L19.9927 6.36079C18.1985 5.53121 16.179 5.06862 14.0071 5.06862C11.8352 5.06862 9.81566 5.5312 8.0215 6.36079L5.66991 2.23758C5.53893 2.00937 5.2526 1.93227 5.02719 2.06488C4.80177 2.19749 4.72562 2.48738 4.85661 2.71559L7.17773 6.78637C3.17516 8.98213 0.464124 13.0838 0.0163574 17.8854H27.9979C27.5501 13.0837 24.839 8.98209 20.8334 6.78637ZM7.58287 13.9072C6.93405 13.9072 6.41012 13.3736 6.41012 12.7198C6.41012 12.063 6.93709 11.5325 7.58287 11.5325C8.23169 11.5325 8.75561 12.0661 8.75561 12.7198C8.75867 13.3736 8.23168 13.9072 7.58287 13.9072ZM20.4283 13.9072C19.7795 13.9072 19.2556 13.3736 19.2556 12.7198C19.2556 12.063 19.7825 11.5325 20.4283 11.5325C21.0771 11.5325 21.601 12.0661 21.601 12.7198C21.6041 13.3736 21.0771 13.9072 20.4283 13.9072Z" fill={isClicked ? 'white' : '#00DF5F'}/>
        </svg>
      )}
      {type === DownloadButtonType.APP_STORE && (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={'animation-01s-all'} >
          <path className={'animation-01s-all'} d="M21.3547 14.8755C21.3947 19.1128 25.1332 20.5229 25.1746 20.5408C25.143 20.6403 24.5773 22.5503 23.205 24.5232C22.0187 26.2289 20.7876 27.9284 18.8481 27.9636C16.9424 27.9981 16.3296 26.8518 14.1508 26.8518C11.9727 26.8518 11.2918 27.9284 9.48785 27.9981C7.61577 28.0678 6.1902 26.1536 4.99409 24.4542C2.54997 20.9779 0.68216 14.6311 3.19016 10.3468C4.43608 8.21928 6.66263 6.87202 9.07935 6.83747C10.9177 6.80297 12.6529 8.05419 13.7767 8.05419C14.8997 8.05419 17.0083 6.54949 19.225 6.77047C20.153 6.80847 22.7579 7.13925 24.4305 9.5479C24.2958 9.63009 21.3224 11.333 21.3547 14.8755ZM17.7731 4.47062C18.767 3.28704 19.436 1.6394 19.2535 0C17.8209 0.0566456 16.0885 0.939191 15.0609 2.12211C14.14 3.16965 13.3334 4.84629 13.5511 6.45324C15.1479 6.57478 16.7792 5.65494 17.7731 4.47062Z" fill={isClicked ? 'white' : '#00DF5F'} />
        </svg>
      )}
      <div className={`mobile-tags ${isClicked ? 'white' : 'green'} animation-01s-all`}>
        {type === DownloadButtonType.GOOGLE_PLAY ? 'Скачать на Android' : 'Скачать на iPhone'}
      </div>
    </div>
  )
}

export default DownloadButton
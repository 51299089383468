import {combineReducers, configureStore} from "@reduxjs/toolkit";
import preferencesReducer, {PreferencesState} from "./preferences_reducer/PreferencesReducer";
import displayedFoodReducer, {DisplayedFoodState} from "./displayed_food_reducer/DisplayedFoodReducer";
import userInfoReducer, {UserInfoState} from "./user_info_reducer/UserInfoReducer";
import profileBottomSheetReducer, {
  ProfileBottomSheetState
} from "./profile_bottom_sheet_reducer/ProfileBottomSheetReducer";
import orderReducer, {OrderState} from "./current_order_reducer/CurrentOrderReducer";
import lastProductWarningReducer, {LastProductWarningState} from "./last_product_warning/LastProductWarningReducer";
import displayedOrderInfoReducer, {DisplayedOrderState} from "./displayed_order_info_reducer/DisplayedOrderInfoReducer";

const rootReducer = combineReducers({
  preferences: preferencesReducer,
  displayedFood: displayedFoodReducer,
  userInfo: userInfoReducer,
  profileBottomSheet: profileBottomSheetReducer,
  order: orderReducer,
  lastProductWarning: lastProductWarningReducer,
  displayedOrder: displayedOrderInfoReducer
});

export type RootState = {
  preferences: PreferencesState,
  displayedFood: DisplayedFoodState
  userInfo: UserInfoState
  profileBottomSheet: ProfileBottomSheetState
  order: OrderState,
  lastProductWarning: LastProductWarningState,
  displayedOrder: DisplayedOrderState
};

const reduxStore = configureStore({
  reducer: rootReducer,
});

export default reduxStore;

import './FoodCard.css'
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDisplayedFood} from "../../../redux/displayed_food_reducer/DisplayedFoodReducer";
import {FoodPositionInfo, VariationType} from "../../../constants/content_types/FoodInfo";
import PressableImage from "../pressable_image/PressableImage";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import NumberTag, {NumberTagColor} from "../buttons/number_tag/NumberTag";
import {getAllProductVariationsCountInOrder} from "../../../redux/current_order_reducer/OrderOperations";
import {RootState} from "../../../redux/ReduxStore";

export enum FoodCardLocation {
  HOME_PAGE,
  RESTAURANT_PAGE
}

interface FoodCardProps {
  location: FoodCardLocation,
  info: FoodPositionInfo
}

const FoodCard: React.FC<FoodCardProps> = ({ location, info}) => {
  const dispatch = useDispatch()
  const orderState = useSelector((state: RootState) => state.order.orderState)

  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);

  const cardPrice = useMemo(() => {
    return info?.foodVariations.filter(v => v.variationType === VariationType.STANDARD)[0]?.price
  }, [info])

  const totalCount = useMemo(() => {
    return getAllProductVariationsCountInOrder(orderState, info.id)
  }, [orderState, info.id])

  return(
    <div
      className={`food-card-wrapper ${!isMobile && 'desktop'} ${location === FoodCardLocation.RESTAURANT_PAGE && 'restaurant'}`}
      onClick={() => {
        dispatch(setDisplayedFood(info))
      }}
      style={{
        width: location === FoodCardLocation.HOME_PAGE ? '148px' : isMobile ? `${window.innerWidth / 2 - 32}px` : '184px',
        cursor: isClicked || isHovered ? 'pointer' : undefined
      }}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
    >
      {totalCount > 0 && (
        <div
          className={'food-count-wrapper'}
          onClick={() => {
            dispatch(setDisplayedFood(info))
          }}
        >
          <NumberTag color={NumberTagColor.GREEN} number={totalCount} />
        </div>
      )}
      <PressableImage
        sideLength={!isMobile ? 184 : location === FoodCardLocation.HOME_PAGE ? 148 : window.innerWidth / 2 - 20}
        borderRadius={16}
        image={info?.image}
        isHoveredExternal={isHovered}
        isClickedExternal={isClicked}
      />
      <div
        className='card-info-wrapper'
      >
        <div className='card-price'>
          {`${cardPrice} ₽`}
        </div>
        <div className={`card-name ${isMobile ? 'mobile-subtext' : 'desktop-subtext'} text-left`}>
          {info?.name}
        </div>
      </div>
    </div>
  )
}

export default FoodCard
import React, {useState} from "react";
import './OpenRestaurantCard.css'
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../../constants/enums/MediaQueries";

export enum OpenRestaurantCardType {
  DESKTOP,
  MOBILE
}
interface OpenRestaurantCardProps {
  onClickAction: () => void;
  type: OpenRestaurantCardType;
}

const OpenRestaurantCard: React.FC<OpenRestaurantCardProps> = ({ onClickAction, type}) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      className={`open-rest-card-wrapper animation-02s-all ${type === OpenRestaurantCardType.DESKTOP ? 'desktop' : 'mobile'}`}
      onClick={onClickAction}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
      style={{
        cursor: isClicked || isHovered ? 'pointer' : undefined
      }}
    >
      {type === OpenRestaurantCardType.MOBILE ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 24H39M39 24L25 10M39 24L25 38" stroke="#C6C9D1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.25 41H66.625M66.625 41L42.7083 17.0833M66.625 41L42.7083 64.9167" stroke="#C6C9D1" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )}
    </div>
  )
}

export default OpenRestaurantCard
import './OrderProductInfoCard.css'
import React, {useMemo} from "react";
import {VariationType} from "../../../../constants/content_types/FoodInfo";
import {useDispatch} from "react-redux";
import {
  decrementOrderPositionCount,
  incrementOrderPositionCount,
  OrderPosition
} from "../../../../redux/current_order_reducer/CurrentOrderReducer";
import TagButton, {TagButtonType} from "../../../shared_components/buttons/tag_button/TagButton";
import {setDisplayedFood} from "../../../../redux/displayed_food_reducer/DisplayedFoodReducer";
import {showLastProductWarning} from "../../../../redux/last_product_warning/LastProductWarningReducer";
import PressableImage from "../../../shared_components/pressable_image/PressableImage";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";

interface OrderProductInfoCardProps {
  positionInfo: OrderPosition,
  staticCard?: boolean
}

const OrderProductInfoCard: React.FC<OrderProductInfoCardProps> = ({ positionInfo, staticCard}) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})

  const resolvedVariation = useMemo(() => {
    return positionInfo?.position?.foodVariations
      ?.filter(p => p.variationId === positionInfo?.position?.selectedVariationId)[0]
  }, [positionInfo])

  const resolvedHeader = useMemo(() => {
    if (resolvedVariation.variationType !== VariationType.STANDARD) {
      return `${positionInfo?.position?.name} (${resolvedVariation?.variationName?.toString().toLowerCase()})`
    }
    return positionInfo?.position?.name
  }, [positionInfo, resolvedVariation])

  return(
    <div className={`order-product-info-card-wrapper ${!isMobile && 'desktop'}`}>
      {staticCard ? (
        <img
          src={positionInfo?.position?.image}
          alt={positionInfo?.position?.name}
          style={{
            aspectRatio: 1,
            width: isMobile ? window.innerWidth / 5.8 : 128,
            borderRadius: 12
          }}
        />
      ) : (
        <PressableImage
          sideLength={isMobile ? window.innerWidth / 5.8 : 128}
          borderRadius={isMobile ? 8 : 12}
          image={positionInfo?.position?.image}
          onClickAction={() => {
            if (!staticCard) {
              dispatch(setDisplayedFood(positionInfo?.position))
            }
          }}
        />
      )}
      <div className={`name-and-description-wrapper ${!isMobile && 'desktop'}`}>
        <div
          className={`name-and-description-wrapper ${!isMobile && 'desktop'}`}
          onClick={() => {
            if (!staticCard) {
              dispatch(setDisplayedFood(positionInfo?.position))
            }
          }}
        >
          <div className={'text-info-wrapper'}>
            <div className={`${isMobile ? 'mobile-h4' : 'desktop-h4'} text-primary`}>{resolvedHeader}</div>
            <div className={`${isMobile ? 'mobile-subtext' : 'desktop-subtext'} text-secondary`}>
              Состав: {resolvedVariation?.ingredients}
            </div>
          </div>
          <div className={`price-and-weight-wrapper ${!isMobile && 'desktop'}`}>
            <div className={'total-price'}>
              <div className={`${isMobile ? 'mobile-squares' : 'desktop-h4'} text-primary`}>{positionInfo?.count} шт.</div>
              <div className={`${isMobile ? 'mobile-squares' : 'desktop-h4'} text-primary`}>
                {positionInfo?.count * resolvedVariation?.price || 0}
              </div>
              <div className={`${isMobile ? 'mobile-squares' : 'desktop-h4'} text-primary`}>₽</div>
            </div>
            <div className={'price-per-item'}>
              <div className={`${isMobile ? 'mobile-squares' : 'desktop-h4'} text-tetriary`}>
                {resolvedVariation?.price} ₽
              </div>
              <div className={`${isMobile ? 'mobile-squares' : 'desktop-h4'} text-tetriary`}>
                {resolvedVariation?.weight} г
              </div>
            </div>
          </div>
        </div>
        {!staticCard && (
          <div className={'card-btns-wrapper'}>
            <div style={{ marginRight: '8px', width: isMobile ? '38px' : '44px'}}>
              <TagButton
                onClickAction={() => dispatch(incrementOrderPositionCount(positionInfo?.position))}
                isActive={false}
                text={'+1'}
                type={isMobile ? TagButtonType.MOBILE : TagButtonType.DESKTOP}
              />
            </div>
            <div
              className={'animation-02s-all minus-btn-wrapper'}
              style={{
                maxWidth: positionInfo?.count === 1 ? '0' : isMobile ? '42px' : '50px',
                marginRight: positionInfo?.count === 1 ? '0' : '8px',
                width: positionInfo?.count === 1 ? '0' : isMobile ? '36px' : '44px',
              }}
            >
              <TagButton
                onClickAction={() => {
                  if (positionInfo?.count > 1) {
                    dispatch(decrementOrderPositionCount(positionInfo?.position))
                  } else {
                    dispatch(showLastProductWarning(positionInfo?.position))
                  }
                }}
                isActive={false}
                text={'-1'}
                type={isMobile ? TagButtonType.MOBILE : TagButtonType.DESKTOP}
              />
            </div>
            <TagButton
              onClickAction={() => dispatch(showLastProductWarning(positionInfo?.position))}
              isActive={false}
              text={'Убрать'}
              withCross={true}
              type={isMobile ? TagButtonType.MOBILE : TagButtonType.DESKTOP}
              fullWidth={isMobile}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderProductInfoCard
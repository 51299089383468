import {FoodPositionInOrderInfo} from "../../constants/content_types/FoodInfo";

export interface LastProductWarningState {
  isShown: boolean;
  position: FoodPositionInOrderInfo | undefined;
}

enum LastProductWarningActionTypes {
  SHOW_LAST_PRODUCT_WARNING = 'SHOW_LAST_PRODUCT_WARNING',
  HIDE_LAST_PRODUCT_WARNING = 'HIDE_LAST_PRODUCT_WARNING'
}

const initialState: LastProductWarningState = {
  isShown: false,
  position: undefined
};

export const showLastProductWarning = (position: FoodPositionInOrderInfo) => ({
  type: LastProductWarningActionTypes.SHOW_LAST_PRODUCT_WARNING ,
  position: position
});

export const hideLastProductWarning = () => ({
  type: LastProductWarningActionTypes.HIDE_LAST_PRODUCT_WARNING ,
});

const lastProductWarningReducer = (
  state = initialState,
  action: ReturnType<typeof showLastProductWarning> | ReturnType<typeof hideLastProductWarning>
): LastProductWarningState => {
  switch (action.type) {
    case LastProductWarningActionTypes.SHOW_LAST_PRODUCT_WARNING:
      const castedShowWarningAction = action as ReturnType<typeof showLastProductWarning>
      return { isShown: true, position: castedShowWarningAction.position };
    case LastProductWarningActionTypes.HIDE_LAST_PRODUCT_WARNING:
      return { isShown: false, position: undefined };
    default:
      return state;
  }
};

export default lastProductWarningReducer

import {CompletedOrderState} from "../../ui/pages/order_history_page/order_history_item/OrderHistoryItem";

export interface DisplayedOrderState {
  orderInfo: CompletedOrderState | undefined
}

enum DisplayedOrderActionTypes {
  SET_DISPLAYED_ORDER = 'SET_DISPLAYED_ORDER',
  CLEAR_DISPLAYED_ORDER = 'CLEAR_DISPLAYED_ORDER',
}

const initialState: DisplayedOrderState = {
  orderInfo: undefined
};

export const setDisplayedOrder = (newOrder: CompletedOrderState) => ({
  type: DisplayedOrderActionTypes.SET_DISPLAYED_ORDER,
  newOrder: newOrder
});

export const clearDisplayedOrder = () => ({
  type: DisplayedOrderActionTypes.CLEAR_DISPLAYED_ORDER,
});

const displayedOrderInfoReducer = (
  state = initialState,
  action: ReturnType<typeof setDisplayedOrder> | ReturnType<typeof clearDisplayedOrder>
): DisplayedOrderState => {
  switch (action.type) {
    case DisplayedOrderActionTypes.SET_DISPLAYED_ORDER:
      return { orderInfo: (action as ReturnType<typeof setDisplayedOrder>).newOrder };
    case DisplayedOrderActionTypes.CLEAR_DISPLAYED_ORDER:
      return { orderInfo: undefined };
    default:
      return state;
  }
};

export default displayedOrderInfoReducer
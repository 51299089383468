import React from "react";
import './Logo.css'
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";

interface LogoProps {
  navigateHomeOnClick?: boolean;
}

const Logo: React.FC<LogoProps> = ({ navigateHomeOnClick}) => {
  const navigate = useNavigate()
  const location = useLocation()

  return(
    <div
      className={`logo ${navigateHomeOnClick && 'hovered'}`}
      onClick={() => {
        if (navigateHomeOnClick && location.pathname !== RoutePaths.HOME) {
          navigate(RoutePaths.HOME)
        }
      }}
    >
      Yourmeal
    </div>
  )
}

export default Logo
import './ImagePicker.css'
import React, {useEffect, useRef, useState} from "react";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../../shared_components/buttons/standard_button/StandardButton";
import {useDispatch, useSelector} from "react-redux";
import {setUserImage} from "../../../../redux/user_info_reducer/UserInfoReducer";
import Sheet from "react-modal-sheet";
import {RootState} from "../../../../redux/ReduxStore";
import Avatar, {AvatarType} from "../../../shared_components/avatar/Avatar";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";

export enum ImagePickerType {
  AVATAR,
  BUTTON
}

interface ImagePickerProps {
  type: ImagePickerType
}

const ImagePicker: React.FC<ImagePickerProps> = ({ type }) => {
  const [isOpened, setOpened] = useState(false)
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})
  const actualImg = useSelector((state: RootState) => state.userInfo.userInfo.image)

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleChangeRequest = () => {
    if (actualImg) {
      setOpened(true)
    } else {
      inputRef?.current?.click()
    }
  }

  useEffect(() => {
    if (!isOpened) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('img-picker-btn-all-wrapper');
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('img-picker-btn-all-wrapper')
    }

    return() => {
      document.body.classList.remove('img-picker-btn-all-wrapper')
      document.body.classList.remove('hidden')
    }
  }, [isOpened]);

  useEffect(() => {
    const handleResize = () => {
      setOpened(false)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setOpened(false)
  }, [actualImg]);

  const imagePickerBtn = () => {
    return(
      <div className={'image-picker-btn-wrapper'}>
        <input
          type="file"
          accept="image/*"
          onChange={e => {
            if (e.target.files && e.target.files[0]) {
              // TODO - replace with backend request and get direct link to image
              dispatch(setUserImage(URL.createObjectURL(e?.target?.files[0])));
            }
          }}
          style={{ display: 'none' }}
          ref={inputRef}
        />
        {type === ImagePickerType.AVATAR ? (
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <Avatar onClickAction={handleChangeRequest} type={AvatarType.MOBILE}/>
          </div>
        ) : (
          <StandardButton
            onClickAction={handleChangeRequest}
            text={'Изменить фото'}
            color={StandardButtonColor.GRAY}
            iconType={StandardButtonIconType.BLACK_USER_SQUARE_ICON}
            iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
          />
        )}
      </div>
    )
  }

  const actionBtns = () => {
    return(
      <>
        <StandardButton
          onClickAction={() => dispatch(setUserImage(undefined))}
          text={'Удалить фото'}
          color={StandardButtonColor.GRAY}
          iconType={StandardButtonIconType.TRASH}
          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
        />
        <StandardButton
          onClickAction={() => inputRef?.current?.click()}
          text={'Загрузить новое'}
          color={StandardButtonColor.GREEN}
          iconType={StandardButtonIconType.WHITE_PLUS}
          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
        />
      </>
    )
  }

  const renderMobile = () => {
    return(
      <div className={'image-picker-all-wrapper'}>
        {isOpened && (
          <div
            style={{ position: 'fixed', top: 0, left: 0, width: window.innerWidth, height: window.innerHeight, zIndex: 999 }}
            onClick={(e) => {
              e.preventDefault()
              setOpened(false)
            }}
          />
        )}
        {imagePickerBtn()}
        <Sheet
          isOpen={isOpened}
          onClose={() => setOpened(false)}
          detent={"content-height"}
          snapPoints={[500, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content disableDrag={true} >
              <div className={'image-picker-sheet-wrapper'}>
                {actionBtns()}
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop/>
        </Sheet>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        {imagePickerBtn()}
        <div
          className={`image-picker-absolute-wrapper-desktop ${isOpened && 'open'}`}
          onClick={e => {
            if (e.target === e.currentTarget) {
              setOpened(false)
            }
          }}
        >
          <div
            className={'picker-popup'}
            style={{ width: Math.min(window.innerWidth - 400, 514) }}
            onClick={e => e.preventDefault()}
          >
            {actionBtns()}
          </div>
        </div>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default ImagePicker
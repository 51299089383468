import React, {useEffect, useState} from "react";
import {QrReader} from "react-qr-reader";
import './QrScannerPage.css'
import QrAimCorner from "./qr_aim_corner/QrAimCorner";
import {useNavigate} from "react-router-dom";
import Sheet from "react-modal-sheet";
import SquareButton, {SquareButtonIconType} from "../../shared_components/buttons/square_button/SquareButton";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";

const QrScannerPage: React.FC = () => {
  const [data, setData] = useState('No result');
  const [isError, setError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0 })
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, []);

  useEffect(() => {
    if (data !== 'No result') {
      if (data?.length > 20 && data?.includes('/restaurants/')) {
        window.open(data)
      } else {
        setError(true)
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isError) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('qr-err-all-wrapper');
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('qr-err-all-wrapper')
    }

    return() => {
      document.body.classList.add('qr-err-all-wrapper')
      document.body.classList.remove('hidden')
    }
  }, [isError]);

  useEffect(() => {
    const handleResize = () => {
      setError(false)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(data)
  }, [data]);

  return(
    <div className="qr-main-wrapper">
      <div style={{position: 'fixed', top: '8px', left: '16px', zIndex: 99, pointerEvents: isError ? 'none' : 'auto'}}>
        <SquareButton
          onClickAction={() => navigate(RoutePaths.HOME)}
          iconType={SquareButtonIconType.ARROW_BACK}
        />
      </div>
      <div style={{ width: '100%', height: (window.innerHeight / 2) - 94 , backgroundColor: 'rgba(0, 0, 0, 0.35)', position: 'absolute', zIndex: 9, top: 0, left: 0}}/>
      <div style={{ width: '100%', height: (window.innerHeight / 2) - 94 , backgroundColor: 'rgba(0, 0, 0, 0.35)', position: 'absolute', zIndex: 9, bottom: 0, left: 0}}/>
      <div style={{ width: (window.innerWidth / 2) - 94, height: 188, backgroundColor: 'rgba(0, 0, 0, 0.35)', position: 'absolute', zIndex: 9, top: (window.innerHeight / 2) - 94, left: 0}}/>
      <div style={{ width: (window.innerWidth / 2) - 94, height: 188, backgroundColor: 'rgba(0, 0, 0, 0.35)', position: 'absolute', zIndex: 9, bottom: (window.innerHeight / 2) - 94, right: 0}}/>
      <QrReader
        onResult={(result) => {
          if (result) {
            setData(result?.getText());
          }
        }}
        constraints={{ facingMode: 'environment' }}
        containerStyle={{ width: '100%', height: window.innerHeight, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        videoStyle={{ width: '100%', height: window.innerHeight, objectFit: "cover" }}
        videoContainerStyle={{ padding: 0, height: window.innerHeight}}
      />
      <div className='target-wrapper'>
        <div className="qr-wrapper">
          <QrAimCorner rotation={0} additionalStyle={{ position: 'absolute', top: 0, left: 0 }} />
          <QrAimCorner rotation={90} additionalStyle={{ position: 'absolute', top: 0, right: 0 }} />
          <QrAimCorner rotation={270} additionalStyle={{ position: 'absolute', bottom: 0, left: 0 }} />
          <QrAimCorner rotation={180} additionalStyle={{ position: 'absolute', bottom: 0, right: 0 }} />
        </div>
      </div>
      <Sheet
        isOpen={isError}
        onClose={() => setError(false)}
        detent={"content-height"}
        snapPoints={[500, 0]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content disableDrag={true}>
            <div className={'qr-error-sheet-wrapper'}>
              <div className={'mobile-h2 text-primary'}>Неверный QR-код</div>
              <div
                className={'mobile-main-text text-secondary'}
                dangerouslySetInnerHTML={{ __html: 'QR-код ведёт не на наш ресурс, поэтому мы не можем его открыть в&nbsp;YourMeal'}}
              />
              <div className={'btns-wrapper'}>
                <StandardButton
                  onClickAction={() => {
                    setError(false)
                    setData('No result')
                  }}
                  text={'Попробовать еще раз'}
                  color={StandardButtonColor.GRAY}
                  iconType={StandardButtonIconType.NO_ICON}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
                <StandardButton
                  onClickAction={() => navigate(RoutePaths.HOME)}
                  text={'Закрыть сканер'}
                  color={StandardButtonColor.GREEN}
                  iconType={StandardButtonIconType.NO_ICON}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop/>
      </Sheet>
    </div>
  )
}

export default QrScannerPage
import './PasswordInput.css'
import React, {useEffect, useMemo, useRef, useState} from "react";
import {TextField} from "@mui/material";

interface SearchFieldProps {
  placeholder: string;
  onInputChange: (value: string) => void;
  isSubmitButtonClicked: boolean;
  validationFunc: (e: string) => boolean;
  invalidHint: string;
  onFocus?: () => void;
}

const PasswordInput: React.FC<SearchFieldProps> = ({ onFocus, invalidHint, onInputChange, placeholder, isSubmitButtonClicked, validationFunc }) => {
  const [isActive, setIsActive] = useState(false)
  const [text, setText] = useState('')
  const inputRef = useRef<HTMLInputElement>();
  const [isPassHidden, setPassHidden] = useState(true)
  const [isActivated, setActivated] = useState(false)

  useEffect(() => {
    onInputChange(text)
  }, [onInputChange, text])

  useEffect(() => {
    setActivated(false)
  }, [])

  const isErrorDisplayed = useMemo(() => {
    return (!validationFunc(text) && ((isActivated && !isActive) || isSubmitButtonClicked))
    }, [validationFunc, isActivated, isActive, isSubmitButtonClicked, text])

  return(
    <div
      className={`password-input-wrapper mobile-and-desktop-hints ${isErrorDisplayed && 'error'} ${isActive && 'active'}`}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: isErrorDisplayed ? '12px' : 0,
        transition: "all .3s ease",
        WebkitTransition: "all .3s ease",
        MozTransition: "all .3s ease",
      }}
      onClick={() => inputRef?.current?.focus()}
    >
      <div
        className="invalid-text-hint"
        style={{
          opacity: isErrorDisplayed ? 1 : 0,
          transition: "all .1s ease",
          WebkitTransition: "all .1s ease",
          MozTransition: "all .1s ease",
          marginBottom: isErrorDisplayed ? '10px' : 0
        }}
      >
        {invalidHint}
      </div>
      <div
        className={`password-field-wrapper ${isErrorDisplayed && 'error'} ${isActive && 'active'} animation-01s-all`}
        style={{
          height: '48px',
          borderColor: !isErrorDisplayed ? (isActive
            ? 'var(--main-ym-green, #00DF5F)'
            : 'var(--gray-light, #F3F4F6)') : 'var(--special-red, #F74061)',
          zIndex: '9999 !important',
          position: 'relative',
        }}
      >
        <div
          style={{ position: 'absolute', top: '10px', right: '12px', zIndex: 10, width: 24 }}
          onClick={() => {
            setPassHidden(!isPassHidden)
          }}
        >
          {isPassHidden ? (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.46479 13.2576C5.57734 22.1038 18.0818 22.1328 21.4807 13.3926C21.8267 12.503 21.8267 11.497 21.4807 10.6073C18.0818 1.8672 5.57734 1.8962 2.46479 10.7424C2.1784 11.5563 2.1784 12.4437 2.46479 13.2576Z" stroke="#87898F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.5 12C8.5 13.933 10.067 15.5 12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12Z" stroke="#87898F" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_126_4791)">
                <path d="M20.6992 9C20.9954 9.49801 21.2577 10.0338 21.4807 10.6073C21.8267 11.497 21.8267 12.503 21.4807 13.3926C19.5113 18.4568 14.4851 20.577 10 19.7438" stroke="#87898F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.5 5.68873C12.4034 2.43568 4.79718 4.11349 2.46479 10.7424C2.1784 11.5563 2.1784 12.4437 2.46479 13.2576C3.11894 15.1167 4.18791 16.5864 5.5 17.6672" stroke="#87898F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 3L3 21" stroke="#87898F" strokeWidth="2" strokeLinecap="round"/>
                <path d="M9.12734 14C8.73191 13.4331 8.5 12.7436 8.5 12C8.5 10.067 10.067 8.5 12 8.5C12.7436 8.5 13.4331 8.73191 14 9.12734" stroke="#87898F" strokeWidth="2" strokeLinecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_126_4791">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
        <TextField
          type={isPassHidden ? 'password' : undefined}
          inputRef={inputRef}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
            setIsActive(true)
            if (!isActivated) {
              setActivated(true)
            }
          }}
          onAnimationStart={(e) => {
            if (e.animationName === 'mui-auto-fill') {
              setIsActive(true)
            } else if (e.animationName === 'mui-auto-fill-cancel'){
              setIsActive(false)
            }
          }}
          onAnimationEnd={() => {
            inputRef?.current?.blur()
          }}
          onBlur={() => setIsActive(false)}
          onClick={() => setIsActive(true)}
          fullWidth={true}
          inputProps={{
            style: {
              fontSize: 18,
              fontFamily: 'Lato-Regular, sans-serif',
              lineHeight: 'normal',
              caretColor: 'var(--main-ym-green, #00DF5F)',
              caret: '5px',
            },
            sx: {
              autoComplete: 'off',
              '&::placeholder': {
                color: 'var(--text-tetriary, #87898F)',
                opacity: 1,
              },
            },
          }}
          onChange={(e) => {
            setText(e.target.value)
          }}
          size="small"
          placeholder={placeholder}
          variant="outlined"
          margin="none"
          multiline={false}
          value={text}
          autoComplete={'off'}
        />
      </div>
    </div>
  )
}

export default PasswordInput
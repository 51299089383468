import './SocialButton.css'
import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";

export enum SocialButtonType {
  VK,
  TG
}

interface SocialButtonProps {
  type: SocialButtonType;
  linkToOpen: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ type, linkToOpen}) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      className={'animation-02s-all social-btn-wrapper'}
      onClick={() => window.open(linkToOpen, '_blank')}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing:'border-box',
        backgroundColor: 'transparent',
        cursor: isClicked || isHovered ? 'pointer' : undefined
      }}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
    >
      {type === SocialButtonType.TG && (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className={'animation-01s-all'} d="M4.38674 15.744C12.7654 12.058 23.6081 7.564 25.1047 6.942C29.0307 5.314 30.2354 5.626 29.6347 9.23133C29.2034 11.8227 27.9587 20.402 26.9667 25.742C26.3781 28.9087 25.0574 29.284 22.9807 27.914C21.9821 27.2547 16.9414 23.9213 15.8474 23.1387C14.8487 22.4253 13.4714 21.5673 15.1987 19.8773C15.8134 19.2753 19.8427 15.428 22.9821 12.4333C23.3934 12.04 22.8767 11.394 22.4021 11.7093C18.1707 14.5153 12.3041 18.41 11.5574 18.9173C10.4294 19.6833 9.34607 20.0347 7.40141 19.476C5.93207 19.054 4.49674 18.5507 3.93807 18.3587C1.78674 17.62 2.29741 16.6633 4.38674 15.744Z" fill={isClicked ? '#00B54D' : isHovered ? '#00C956' : '#00DF5F'}/>
        </svg>

      )}
      {type === SocialButtonType.VK && (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g className={'animation-01s-all'} clipPath="url(#clip0_405_3873)">
            <path className={'animation-01s-all'} d="M18.0023 26.3772C7.06909 26.3772 0.83308 18.8819 0.573242 6.40966H6.04982C6.22971 15.564 10.2671 19.4416 13.4651 20.2411V6.40966H18.6221V14.3047C21.7801 13.965 25.0976 10.3672 26.2169 6.40966H31.3738C30.5143 11.2866 26.9166 14.8844 24.3582 16.3635C26.9166 17.5627 31.0142 20.7008 32.5732 26.3772H26.8966C25.6774 22.5796 22.6396 19.6414 18.6221 19.2417V26.3772H18.0023Z" fill={isClicked ? '#00B54D' : isHovered ? '#00C956' : '#00DF5F'}/>
          </g>
          <defs>
            <clipPath id="clip0_405_3873">
              <rect className={'animation-01s-all'} width="32" height="32.0001" fill="white" transform="translate(0.5)"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  )
}

export default SocialButton
import './ProfilePage.css'
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/ReduxStore";
import PlainInput from "../../shared_components/inputs/plain_input/PlainInput";
import CheckBox from "../../shared_components/buttons/checkbox/CheckBox";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";
import ImagePicker, {ImagePickerType} from "./image_picker/ImagePicker";
import {setUserAdditionalInfo, setUserAllowPushes, setUserName} from "../../../redux/user_info_reducer/UserInfoReducer";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";
import Footer from "../../shared_components/footer/Footer";
import Avatar, {AvatarType} from "../../shared_components/avatar/Avatar";

const ProfilePage: React.FC = () => {
  // service functions
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  // user info
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const [allowPushes, setAllowPushes] = useState(userInfo?.allowPushNotifications)
  const [prefs, setPrefs] = useState(userInfo?.userAdditionalInfo)
  const [name, setName] = useState(userInfo?.name)

  // changes handler
  const hasChanged = useMemo(() => {
    return allowPushes !== userInfo?.allowPushNotifications || prefs !== userInfo?.userAdditionalInfo || name !== userInfo?.name
  }, [allowPushes, prefs, name, userInfo?.allowPushNotifications, userInfo?.userAdditionalInfo, userInfo?.name])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  const renderMobile = () => {
    return(
      <div className={'profile-page-wrapper'}>
        <ImagePicker type={ImagePickerType.AVATAR}/>
        <div className={'mobile-h2'}>Это ваш профиль</div>
        <div className={'inputs-wrapper'}>
          <form
            style={{ width: '100%', display: 'flex'}}
            onSubmit={e => {
              e.preventDefault()
              const inputElement = document.activeElement as HTMLElement;
              if (inputElement) {
                inputElement.blur();
              }
            }}
          >
            <PlainInput
              placeholderText={'Имя'}
              onInputChange={(n) => setName(n)}
              validationFunc={() => true}
              isSubmitButtonClicked={false}
              onFocus={() => true}
              initialText={userInfo?.name}
            />
          </form>
          <PlainInput
            placeholderText={'E-mail'}
            onInputChange={() => true}
            validationFunc={() => true}
            isSubmitButtonClicked={false}
            onFocus={() => true}
            disabled={true}
            initialText={userInfo?.email}
          />
        </div>
        <div className={'preferences-wrapper'}>
          <div className={'mobile-subtext text-secondary'}>
            Оставьте комментарий о себе, своих особых предпочтениях или особенностях. Официант будет видеть этот комментарий всегда, когда будет получать заказ от вас
          </div>
          <form
            style={{ width: '100%', display: 'flex'}}
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <PlainInput
              placeholderText={'Комментарий'}
              onInputChange={(c) => setPrefs(c)}
              validationFunc={() => true}
              isSubmitButtonClicked={false}
              onFocus={() => true}
              multiline={true}
              initialText={userInfo?.userAdditionalInfo}
              blurOnEnter={true}
            />
          </form>
        </div>
        <CheckBox
          onCheckAction={() => setAllowPushes(true)}
          onUncheckAction={() => setAllowPushes(false)}
          checked={allowPushes}
          text={'Присылать push-уведомления'}
        />
        <div style={{ height: '230px'}}></div>
        <div className={'grad'} style={{ bottom: hasChanged ? '235px' : '178px'}}/>
        <div className={'btns-wrapper'} style={{ maxHeight: hasChanged ? '176px' : '120px'}}>
          <div className={`save-btn-wrapper ${!hasChanged && 'opacity0'}`}>
            <StandardButton
              onClickAction={() => {
                dispatch(setUserName(name))
                dispatch(setUserAdditionalInfo(prefs))
                dispatch(setUserAllowPushes(allowPushes))
              }}
              text={'Сохранить изменения'}
              color={StandardButtonColor.GREEN}
              iconType={StandardButtonIconType.NO_ICON}
              iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
            />
          </div>
          <ImagePicker type={ImagePickerType.BUTTON}/>
          <StandardButton
            onClickAction={() => window.history.back()}
            text={!hasChanged ? 'Вернуться' : "Отменить и вернуться"}
            color={!hasChanged ? StandardButtonColor.GREEN : StandardButtonColor.GRAY}
            iconType={hasChanged ? StandardButtonIconType.BLACK_LEFT_ARROW : StandardButtonIconType.WHITE_LEFT_ARROW}
            iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
          />
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <HeaderDesktop withProfileButton={true}/>
          <div className={'profile-page-wrapper-desktop'}>
            <div
              className={'profile-info-popup'}
              style={{ width: Math.min(784, window.innerWidth / 1.8) }}
            >
              <div className={'profile-desktop-avatar-wrapper'}>
                <Avatar type={AvatarType.DESKTOP} />
              </div>
              <div className={'desktop-h2 text-primary'}>
                Это ваш профиль
              </div>
              <div className={'profile-desktop-inputs-wrapper'}>
                <form
                  style={{ width: '100%', display: 'flex'}}
                  onSubmit={e => {
                    e.preventDefault()
                    const inputElement = document.activeElement as HTMLElement;
                    if (inputElement) {
                      inputElement.blur();
                    }
                  }}
                >
                  <PlainInput
                    placeholderText={'Имя'}
                    onInputChange={(n) => setName(n)}
                    validationFunc={() => true}
                    isSubmitButtonClicked={false}
                    onFocus={() => true}
                    initialText={userInfo?.name}
                  />
                </form>
                <PlainInput
                  placeholderText={'E-mail'}
                  onInputChange={() => true}
                  validationFunc={() => true}
                  isSubmitButtonClicked={false}
                  onFocus={() => true}
                  disabled={true}
                  initialText={userInfo?.email}
                />
              </div>
              <div className={'profile-desktop-comment-wrapper'}>
                <div className={'desktop-subtext text-secondary'}>
                  Оставьте комментарий о себе, своих особых предпочтениях или особенностях. Официант будет видеть этот комментарий всегда, когда будет получать заказ от вас
                </div>
                <form
                  style={{ width: '100%', display: 'flex'}}
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                >
                  <PlainInput
                    placeholderText={'Комментарий'}
                    onInputChange={(c) => setPrefs(c)}
                    validationFunc={() => true}
                    isSubmitButtonClicked={false}
                    onFocus={() => true}
                    multiline={true}
                    initialText={userInfo?.userAdditionalInfo}
                    blurOnEnter={true}
                  />
                </form>
              </div>
              <CheckBox
                onCheckAction={() => setAllowPushes(true)}
                onUncheckAction={() => setAllowPushes(false)}
                checked={allowPushes}
                text={'Присылать push-уведомления'}
              />
              <div className={'profile-desktop-btns-wrapper'}>
                <div>
                  <ImagePicker type={ImagePickerType.BUTTON}/>
                </div>
                {hasChanged && (
                  <div>
                    <StandardButton
                      onClickAction={() => window.history.back()}
                      text={"Отменить и вернуться"}
                      color={StandardButtonColor.GRAY}
                      iconType={StandardButtonIconType.BLACK_LEFT_ARROW}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                  </div>
                )}
                <div>
                  <StandardButton
                    onClickAction={() => {
                      if (hasChanged) {
                        dispatch(setUserName(name))
                        dispatch(setUserAdditionalInfo(prefs))
                        dispatch(setUserAllowPushes(allowPushes))
                      } else {
                        window.history.back()
                      }
                    }}
                    text={hasChanged ? 'Сохранить изменения' : 'Вернуться'}
                    color={StandardButtonColor.GREEN}
                    iconType={hasChanged ? StandardButtonIconType.NO_ICON : StandardButtonIconType.WHITE_LEFT_ARROW}
                    iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default ProfilePage
import React, {useEffect, useState} from "react";
import './assets/styles/colors.css'
import './index.css'
import './App.css'
import './assets/styles/animation_durations.css'
import {RoutePaths} from "./constants/enums/RoutePaths";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import HomePage from "./ui/pages/home_page/HomePage";
import NotFoundPage from "./ui/pages/not_found_page/NotFoundPage";
import QrScannerPage from "./ui/pages/qr_scanner_page/QrScannerPage";
import OrderPage from "./ui/pages/order_page/OrderPage";
import ProfilePage from "./ui/pages/profile_page/ProfilePage";
import NavigationBar from "./ui/shared_components/navigation_bar/NavigationBar";
import RestaurantPage from "./ui/pages/restaurant_page/RestaurantPage";
import FoodDetailedView from "./ui/shared_components/food_detailed_view/FoodDetailedView";
import {useSelector} from "react-redux";
import {RootState} from "./redux/ReduxStore";
import AuthAndRegPage from "./ui/pages/auth_and_reg_page/AuthAndRegPage";
import OrderHistoryPage from "./ui/pages/order_history_page/OrderHistoryPage";
import ScrollBackButton from "./ui/shared_components/buttons/scroll_back_button/ScrollBackButton";
import OrderSquareFloatingButton, {
  OrderSquareFloatingButtonType
} from "./ui/shared_components/buttons/order_button/OrderSquareFloatingButton";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "./constants/enums/MediaQueries";
import ProfileModal from "./ui/shared_components/profile_modal/ProfileModal";
import AboutPage from "./ui/pages/about_page/AboutPage";

const App: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})
  const [isScrollBtnDisplayed, setScrollBtnDisplayed] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 2) {
        setScrollBtnDisplayed(true)
      } else {
        setScrollBtnDisplayed(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={RoutePaths.HOME} />} />
        <Route path={RoutePaths.RESTAURANT} element={<RestaurantPage />} />
        <Route path={RoutePaths.HOME} element={<HomePage />} />
        <Route path={RoutePaths.MY_ORDER} element={<OrderPage />} />
        <Route
          path={RoutePaths.PROFILE}
          element={userInfo.bearerToken !== ''
            ? <ProfilePage />
            : <Navigate to={RoutePaths.LOGIN} />
          }
        />
        <Route
          path={RoutePaths.ORDERS_HISTORY}
          element={userInfo.bearerToken !== ''
            ? <OrderHistoryPage />
            : <Navigate to={RoutePaths.LOGIN} />
          }
        />
        <Route path={RoutePaths.LOGIN} element={<AuthAndRegPage />} />
        <Route path={RoutePaths.SCAN_QR} element={<QrScannerPage />} />
        <Route path={RoutePaths.ABOUT} element={<AboutPage />} />
        <Route path={RoutePaths.NOT_FOUND} element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} />} />
      </Routes>
      {isMobile && <NavigationBar/>}
      {!isMobile && <ProfileModal/>}
      <FoodDetailedView/>
      {isMobile &&
        location?.pathname !== RoutePaths.MY_ORDER &&
        location?.pathname !== RoutePaths.HOME &&
        location?.pathname !== RoutePaths.LOGIN &&
        <OrderSquareFloatingButton type={OrderSquareFloatingButtonType.COMMON}/>
      }
      {!location?.pathname?.includes(RoutePaths.RESTAURANT.slice(0, -4)) && <ScrollBackButton isDisplayed={isScrollBtnDisplayed} />}
    </>
  );
}

export default App;

import React, {useEffect, useMemo, useState} from "react";
import QrCodeButton from "./qr_code_button/QrCodeButton";
import './HomePage.css'
import SearchInput from "../../shared_components/inputs/search_input/SearchInput";
import Footer from "../../shared_components/footer/Footer";
import RestaurantSection, {RestaurantSectionProps} from "./restaurant_section/RestaurantSection";
import {MockHomepageData} from "./MockHomepageData";
import Loader from "../../shared_components/loader/Loader";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import {useMediaQuery} from "react-responsive";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";

const HomePage: React.FC = () => {
  const [isLoading, setLoading] = useState(true)
  const [restaurantsInfo, setRestaurantsInfo] = useState<RestaurantSectionProps[]>([])
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  useEffect(() => {
    // TODO - replace with the actual backend request - homepage content
    setTimeout(() => {
      setRestaurantsInfo(MockHomepageData)
      setLoading(false)
    }, 1000)
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0 }), 100)
  }, []);

  const renderMobile = useMemo(() => {
    return(
      <div className={'homepage-wrapper'}>
        <div className={'top-container'}>
          <SearchInput onInputChange={() => true} placeholder={'Заведение или блюдо...'} />
          <QrCodeButton/>
        </div>
        {isLoading ? (
          <div style={{ minHeight: window.innerHeight - 100, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Loader/>
          </div>
        ) : (
          <div className='restaurant-sections-wrapper'>
            {restaurantsInfo?.map(r => (
              <RestaurantSection key={`${r.id}-ri`} id={r.id} name={r.name} positions={r.positions} restaurantInfoTags={r.restaurantInfoTags}/>
            ))}
          </div>
        )}
        <Footer/>
      </div>
    )
  }, [restaurantsInfo, isLoading])

  const renderDesktop = useMemo(() => {
    return(
      <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <HeaderDesktop
            withSearchBar={true}
            withProfileButton={true}
            searchBar={<SearchInput onInputChange={() => true} placeholder={'Заведение или блюдо...'} />}
          />
          <div className={'homepage-wrapper-desktop'}>
            {isLoading ? (
              <div style={{ height: window.innerHeight - 500, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                <Loader/>
              </div>
            ) : (
              <div className='restaurant-sections-wrapper-desktop'>
                {restaurantsInfo?.map(r => (
                  <RestaurantSection key={`${r.id}-ri`} id={r.id} name={r.name} positions={r.positions}  restaurantInfoTags={r.restaurantInfoTags}/>
                ))}
              </div>
            )}
          </div>
        </div>
        <Footer/>
      </>
    )
  }, [restaurantsInfo, isLoading])

  return isMobile ? renderMobile : renderDesktop
}

export default HomePage

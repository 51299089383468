import './DetailedOrderHistoryView.css'
import React, {useEffect, useMemo, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import Sheet from "react-modal-sheet";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import {clearDisplayedOrder} from "../../../../redux/displayed_order_info_reducer/DisplayedOrderInfoReducer";
import {formatOrderType} from "../../../../redux/current_order_reducer/OrderOperations";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../../shared_components/buttons/standard_button/StandardButton";
import OrderProductInfoCard from "../../order_page/order_product_info_card/OrderProductInfoCard";
import CloseButton, {CloseButtonSize} from "../../../shared_components/buttons/close_button/CloseButton";

const DetailedOrderHistoryView: React.FC = () => {
  const dispatch = useDispatch()
  const displayedOrderState = useSelector((state: RootState) => state.displayedOrder.orderInfo)
  const [resizeTimeouts, setResizeTimeouts] = useState<NodeJS.Timeout[]>([])
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  useEffect(() => {
    const handleResize = () => {
      dispatch(clearDisplayedOrder())
    }

    if (displayedOrderState === undefined) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('detailed-order-all-wrapper');
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('detailed-order-all-wrapper')
      setResizeTimeouts([...resizeTimeouts, setTimeout(() => {
        if (displayedOrderState !== undefined) {
          window.addEventListener('resize', handleResize)
        }
      }, 1000)])
    }

    return() => {
      document.body.classList.remove('detailed-order-all-wrapper')
      document.body.classList.remove('hidden')
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    }
  }, [displayedOrderState]);

  const formattedTime = useMemo(() => {
    return formatOrderType(displayedOrderState?.timestamp || 0)
  }, [displayedOrderState]);

  const renderMobile = () => {
    return(
      <div className={'detailed-order-all-wrapper'}>
        {(displayedOrderState !== undefined) && (
          <div
            style={{ position: 'fixed', top: 0, left: 0, width: window.innerWidth, height: window.innerHeight, zIndex: 999 }}
            onClick={(e) => {
              e.preventDefault()
              dispatch(clearDisplayedOrder())
            }}
          />
        )}
        <Sheet
          isOpen={displayedOrderState !== undefined}
          onClose={() => dispatch(clearDisplayedOrder())}
          detent={"content-height"}
          snapPoints={[window.innerHeight - 64, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content disableDrag={true} >
              <div className={'detailed-order-all-sheet-wrapper'}>
                <div className={'top-grad'}/>
                <div className={'bottom-grad'}/>
                <div style={{ display: 'flex', flexDirection: "column", width: '100%', gap: '16px'}}>
                  <div className={'mobile-h2 text-primary'}>
                    {displayedOrderState?.restaurantName}
                  </div>
                  <div className={'mobile-main-text text-secondary'}>
                    {`Заказ\u00A0${formattedTime}`}
                  </div>
                </div>
                <div className={'order-pos-wrapper'} style={{ maxHeight: window.innerHeight - 250 }}>
                  {displayedOrderState?.orderPositions?.map(op =>
                    <OrderProductInfoCard
                      positionInfo={op}
                      staticCard={true}
                      key={`${op?.position?.id} ${op?.position?.selectedVariationId}-opic`}
                    />
                  )}
                </div>
                <div style={{ display: 'flex', width: '100%', boxSizing: 'border-box', paddingRight: '12px'}}>
                  <StandardButton
                    onClickAction={() => dispatch(clearDisplayedOrder())}
                    text={'Закрыть'}
                    color={StandardButtonColor.GREEN}
                    iconType={StandardButtonIconType.WHITE_CROSS}
                    iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                  />
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop/>
        </Sheet>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <div
        className={`order-history-detailed-absolute-wrapper-desktop ${displayedOrderState !== undefined && 'open'}`}
        onClick={e => {
          if (e.target === e.currentTarget) {
            dispatch(clearDisplayedOrder())
          }
        }}
      >
        <div
          className={'order-history-detailed-popup-desktop'}
          style={{ width: Math.min(window.innerWidth - 200, 1168), maxHeight: window.innerHeight - 100 }}
          onClick={e => e.preventDefault()}
        >
          <div className={'order-history-detailed-wrapper-desktop'}>
            <div className={'top-grad'}/>
            <div className={'bottom-grad'}/>
            <div className={'header-and-close-wrapper'}>
              <div className={'desktop-h2'}>
                {displayedOrderState?.restaurantName}
              </div>
              <div style={{ marginLeft: '12px', marginRight: '20px'}}>
                <CloseButton
                  size={CloseButtonSize.BIG}
                  onClickAction={() => dispatch(clearDisplayedOrder())}
                  defaultColor={'transparent'}
                />
              </div>
            </div>
            <div className={'desktop-main-text text-secondary'}>
              {`Заказ\u00A0${formattedTime}`}
            </div>
            <div className={'pos-wrapper'} style={{ maxHeight: window.innerHeight - 300 }}>
              {displayedOrderState?.orderPositions?.map(op =>
                <OrderProductInfoCard
                  positionInfo={op}
                  staticCard={true}
                  key={`${op?.position?.id} ${op?.position?.selectedVariationId}-opic`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default DetailedOrderHistoryView
import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";

interface PressableImageProps {
  sideLength: number;
  borderRadius: number;
  image: any;
  onClickAction?: () => void;
  isHoveredExternal?: boolean;
  isClickedExternal?: boolean;
}

const PressableImage: React.FC<PressableImageProps> = ({ sideLength, image, onClickAction, borderRadius, isClickedExternal, isHoveredExternal}) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      style={{
        position: "relative",
        width: `${sideLength}px`,
        aspectRatio: 1,
        cursor: isClicked || isHovered || isClickedExternal || isHoveredExternal ? 'pointer' : undefined
      }}
      onClick={() => {
        if (onClickAction) {
          onClickAction()
        }
      }}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
    >
      <img
        src={image}
        alt={'card-img'}
        style={{ borderRadius: `${borderRadius}px`, width: `${sideLength}px`, aspectRatio: 1, position: 'absolute', zIndex: 2 }}
      />
      <div
        className={'animation-02s-all'}
        style={{
          borderRadius: `${borderRadius}px` ,
          position: 'absolute',
          backgroundColor: (isClicked || isClickedExternal)
            ? 'var(--special-dark-layer, rgba(0, 0, 0, 0.35))'
            : (isHovered || isHoveredExternal) ? 'var(--special-dark-layer, rgba(0, 0, 0, 0.35))' : 'transparent',
          zIndex: 3,
          width: `${sideLength}px`,
          aspectRatio: 1,
        }}
      />
    </div>
  )
}

export default PressableImage

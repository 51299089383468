import './ScrollBAckButton.css'
import React, {useMemo} from "react";
import SquareButton, {SquareButtonIconType} from "../square_button/SquareButton";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import {getTotalProductCountInOrder} from "../../../../redux/current_order_reducer/OrderOperations";

interface ScrollBackButtonProps {
  isDisplayed: boolean;
  onClickAdditionalAction?: () => void;
}

const ScrollBackButton: React.FC<ScrollBackButtonProps> = ({ isDisplayed, onClickAdditionalAction }) => {
  const orderState = useSelector((state: RootState) => state.order.orderState)

  const totalCount = useMemo(() => {
    return getTotalProductCountInOrder(orderState)
  }, [orderState])

  return(
    <div className={`scroll-btn-wrapper ${isDisplayed && 'opened'} ${totalCount > 0 ? 'shifted' : 'normal'}`}>
      <SquareButton
        onClickAction={() => {
          if (onClickAdditionalAction) {
            onClickAdditionalAction()
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        iconType={SquareButtonIconType.ARROW_UP}
      />
    </div>
  )
}

export default ScrollBackButton
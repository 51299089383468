export enum RoutePaths {
  HOME = '/home',
  RESTAURANT = '/restaurants/:id',
  MY_ORDER = '/order',
  PROFILE = '/profile',
  SCAN_QR = '/qr',
  NOT_FOUND = '/not-found',
  LOGIN = '/login',
  ORDERS_HISTORY = 'order-history',
  POLICY = '/policy',
  ABOUT = '/about'
}

import React, {useEffect} from "react";
import Footer from "../../shared_components/footer/Footer";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import SquareButton, {SquareButtonIconType} from "../../shared_components/buttons/square_button/SquareButton";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";

const AboutPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return(
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: isMobile ? '16px' : '88px', paddingTop: isMobile ? '16px' : '32px', boxSizing: 'border-box'}}>
        <SquareButton onClickAction={() => navigate(RoutePaths.HOME)} iconType={SquareButtonIconType.ARROW_BACK}/>
        <div className={'mobile-h2'}>YOURMEAL: Здоровое питание без компромиссов</div>
        <div className={'mobile-h4'}>Ваш персональный гид в мире ресторанов</div>
        <div className={'mobile-main-text'}>
          YOURMEAL — это инновационный сервис, который помогает людям с особыми диетическими потребностями наслаждаться едой вне дома без риска для здоровья.
        </div>
        <div className={'mobile-h4'}>Для кого создан YOURMEAL?</div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <div className={'mobile-main-text'}>
            • Аллергиков
          </div>
          <div className={'mobile-main-text'}>
            • Людей с пищевой непереносимостью
          </div>
          <div className={'mobile-main-text'}>
            • Веганов и вегетарианцев
          </div>
          <div className={'mobile-main-text'}>
            • Пациентов с сердечно-сосудистыми заболеваниями
          </div>
          <div className={'mobile-main-text'}>
            • Пациентов с заболеваниями желудочно-кишечного тракта и мочевыделительной системы
          </div>
          <div className={'mobile-main-text'}>
            • Приверженцев здорового образа жизни, спортсменов
          </div>
          <div className={'mobile-main-text'}>
            • Всех, кто хочет питаться осознанно и безопасно
          </div>
        </div>
        <div className={'mobile-h4'}>Как YOURMEAL меняет вашу жизнь?</div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              1. Персонализированный поиск.
            </div>
            <div className={'mobile-main-text'}>
              Находите рестораны с блюдами, идеально подходящими вашей диете.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              2. Прозрачность состава.
            </div>
            <div className={'mobile-main-text'}>
              Получайте полную информацию о составе, пищевой ценности каждого блюда, включая хлебные единицы.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              3. Простота заказа.
            </div>
            <div className={'mobile-main-text'}>
              Бронируйте столики и заказывайте еду прямо через приложение.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              4. Отзывы сообщества.
            </div>
            <div className={'mobile-main-text'}>
              Принимайте решения на основе опыта других пользователей.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              5. Экономия времени.
            </div>
            <div className={'mobile-main-text'}>
              Забудьте о долгих разговорах с официантами о составе блюд.
            </div>
          </div>
        </div>

        <div className={'mobile-h4'}>Почему YOURMEAL — это больше, чем просто сервис?</div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              • Свобода выбора!
            </div>
            <div className={'mobile-main-text'}>
              Питайтесь где угодно, не беспокоясь о своём здоровье.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              • Уверенность!
            </div>
            <div className={'mobile-main-text'}>
              Знайте точно, что в вашей тарелке.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              • Разнообразие!
            </div>
            <div className={'mobile-main-text'}>
              Откройте для себя новые блюда и рестораны, безопасные для вас.
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <div className={'mobile-main-text'} style={{ fontWeight: 'bold'}}>
              • Поддержка сообщества!
            </div>
            <div className={'mobile-main-text'}>
              Станьте частью растущего комьюнити людей, заботящихся о своём питании.
            </div>
          </div>
        </div>

        <div className={'mobile-h4'}>Присоединяйтесь к революции в сфере здорового питания!</div>
        <div className={'mobile-main-text'}>
          Скачайте YOURMEAL сейчас и откройте для себя мир вкусной и безопасной еды в ресторанах. Ваше здоровье и удовольствие от еды — наш главный приоритет.
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default AboutPage;

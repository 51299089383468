import './PlainInput.css'
import React, {useEffect, useMemo, useRef, useState} from "react";
import {TextField} from "@mui/material";

interface PlainInputProps {
  placeholderText: string;
  onInputChange: (value: string) => void;
  validationFunc : (text: string) => boolean;
  isSubmitButtonClicked: boolean;
  onFocus: () => any;
  multiline? : boolean;
  invalidTextHint ? : string;
  autoFocus ? : boolean;
  disabled ? : boolean;
  initialText ? : string;
  blurOnEnter?: boolean;
}

const PlainInput: React.FC<PlainInputProps> =
  ({ placeholderText, multiline, validationFunc, invalidTextHint,
     onInputChange, isSubmitButtonClicked , autoFocus, initialText,
     onFocus, disabled, blurOnEnter}) => {
    const [isActive, setIsActive] = useState(false)
    const [text, setText] = useState(initialText ? initialText : '')
    const [padding, setPadding] = useState(0)
    const [isActivated, setActivated] = useState(false)
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      setActivated(false)
    }, [])

    useEffect(() => {
      onInputChange(text)
    }, [onInputChange, text])

    const isErrorDisplayed = useMemo(() => {
      return !validationFunc(text) && ((isActivated && !isActive) || isSubmitButtonClicked)
    }, [validationFunc, isActivated, isActive, isSubmitButtonClicked, text])

    useEffect(() => {
      if (initialText) {
        setText(initialText)
      }
    }, [initialText]);

    return(
      <div
        onKeyDown={(e) => {
          if ((e?.key === 'Enter') && blurOnEnter) {
            inputRef?.current?.blur()
          }
        }}
        className={`custom-input-wrapper ${isErrorDisplayed && 'error'} ${isActive && 'active'}`}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: isErrorDisplayed ? '14px' : '0',
          transition: "all .3s ease",
          WebkitTransition: "all .3s ease",
          MozTransition: "all .3s ease",
        }}
        onClick={() => inputRef?.current?.focus()}
      >
        <div
          className="invalid-text-hint"
          style={{
            opacity: isErrorDisplayed ? 1 : 0,
            transition: "all .1s ease",
            WebkitTransition: "all .1s ease",
            MozTransition: "all .1s ease",
            marginBottom: isErrorDisplayed ? '4px' : 0
          }}
        >
          {invalidTextHint}
        </div>
        <div
          className={`custom-input-field-wrapper ${disabled && 'disabled'} ${isActive && 'active'} ${isErrorDisplayed && 'error'}`}
          style={{
            height: !multiline ? '45px' : '96px',
            borderColor:
              !disabled ?
                !isErrorDisplayed ? (isActive
                  ? 'var(--main-ym-green, #00DF5F)'
                  : 'var(--gray-light, #F3F4F6)') : 'var(--special-red, #F74061)'
                : 'var(--gray-light, #F3F4F6)',
            zIndex: '9999 !important',
          }}
        >
          <TextField
            inputRef={inputRef}
            autoFocus={autoFocus}
            onBlur={() => setIsActive(false)}
            onFocus={() => {
              onFocus()
              setIsActive(true)
              if (!isActivated) {
                setActivated(true)
              }
            }}
            fullWidth={true}
            onAnimationStart={(e) => {
              if (e.animationName === 'mui-auto-fill') {
                setIsActive(true)
                setPadding(11)
              } else if (e.animationName === 'mui-auto-fill-cancel'){
                setPadding(0)
                setIsActive(false)
              }
            }}
            onAnimationEnd={() => {
              if (padding === 11) {
                inputRef?.current?.blur()
              }
              setPadding(0)
            }}
            onAnimationEndCapture={() => setPadding(1)}
            inputProps={{
              maxLength: multiline ? 150 : 30,
              style: {
                fontSize: 18,
                fontFamily: 'Lato-Regular, sans-serif',
                lineHeight: 'normal',
                caretColor: 'var(--main-ym-green, #00DF5F)',
                caret: '5px',
              },
              sx: {
                '&::placeholder': {
                  color: 'var(--text-tetriary, #87898F)',
                  opacity: 1,
                },
              },
            }}
            InputLabelProps={{
              style:
                {
                  marginTop: isActive || text !== '' ? '2px' : 0,
                  fontSize: isActive || text !== '' ? 14 : 18,
                  fontFamily: 'Lato-Regular, sans-serif',
                  color: 'var(--text-tetriary, #87898F)'
                }
            }}
            sx={{
              autoComplete: 'off',
              marginTop: isActive || text !== '' ? '11px !important' : `${padding}px !important`,
              fontFamily: 'Lato-Regular, sans-serif',
              marginBottom: '0px !important',
              paddingTop: '0px !important',
              transition: "all .1s ease",
              WebkitTransition: "all .1s ease",
              MozTransition: "all .1s ease",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "var(--text-secondary, #5D5E64)",
              },
            }}
            onChange={(e) => {
              onFocus()
              setText(e.target.value)
            }}
            size="small"
            label={placeholderText}
            variant="outlined"
            margin="none"
            multiline={multiline}
            maxRows={3}
            defaultValue={initialText ? initialText : text.length > 0 || isActive ? text : undefined}
            disabled={disabled}
            autoComplete="off"
          />
        </div>
      </div>
    )
  }

export default PlainInput

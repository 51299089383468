import {FoodPositionInfo, VariationType} from "../../../constants/content_types/FoodInfo";
import {DietTag} from "../../../constants/content_types/DietTag";
import {MenuSectionTag} from "../../../constants/content_types/MenuSectionTag";
import {MediumCheck} from "../../..//constants/content_types/RestaurantInfo";
import {RestaurantInfoTag} from "../../..//constants/content_types/RestaurantInfoTag";

export const mockFood1: FoodPositionInfo[] = [
  {
    id: '1',
    restaurantId: '1',
    name: 'Картофель фри с трюфельным маслом',
    image: 'https://i.imgur.com/6dtZI35.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 150,
        ingredients: 'картофель фри, трюфельное масло',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С сыром',
        variationType: VariationType.CUSTOM,
        price: 800,
        weight: 170,
        ingredients: 'картофель фри, трюфельное масло, сыр',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.HEALTHY_HEART, DietTag.VEGAN],
        restrictedAllergyTags: [],
      }
    ],
  },
  {
    id: '2',
    restaurantId: '1',
    name: 'Авторский салат с авокадо и гранатом',
    image: 'https://i.imgur.com/RWer6Wh.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 150,
        ingredients: 'салатный лист, авокадо, гранат, гренки, петрушка, лук, капуста',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '3',
    restaurantId: '1',
    name: 'Тыквенный суп-пюре',
    image: 'https://i.imgur.com/nLjUw2s.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 444,
        weight: 150,
        ingredients: 'суп',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
      {
        variationId: '2',
        variationName: 'С семечками',
        variationType: VariationType.CUSTOM,
        price: 586,
        weight: 170,
        ingredients: 'суп, семечки',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 222,
        fats: 333,
        carbohydrates: 444,
        calories: 555,
        allowedDietsTags: [DietTag.DIABETIC, DietTag.LESS_PROTEIN],
        restrictedAllergyTags: [],
      }
    ],
  },
  {
    id: '4',
    restaurantId: '1',
    name: 'Чай',
    image: 'https://i.imgur.com/uuiNeFb.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 100,
        weight: 150,
        ingredients: 'чай',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '5',
    restaurantId: '1',
    name: 'Кофе',
    image: 'https://i.imgur.com/pt5XXEj.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 200,
        weight: 200,
        ingredients: 'кофе',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '6',
    restaurantId: '1',
    name: 'Лимонад',
    image: 'https://i.imgur.com/tX5OI7y.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 250,
        weight: 200,
        ingredients: 'лимонад',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '7',
    restaurantId: '1',
    name: 'Пирожок с вишней',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 210,
        weight: 200,
        ingredients: 'вишня, тесто',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '8',
    restaurantId: '1',
    name: 'Пирог с яблоком и корицей',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 400,
        weight: 200,
        ingredients: 'яблоко, корица, тесто',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '9',
    restaurantId: '1',
    name: 'Торт тирамису',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS, MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 200,
        ingredients: 'че там в составе я не знаю',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
]

export const mockFood2: FoodPositionInfo[] = [{
  id: '10',
  restaurantId: '2',
  name: 'Салат с помидорами и куриной грудкой',
  image: 'https://i.imgur.com/lv62Sr6.jpg',
  isOnStartPage: true,
  menuSectionTags: [MenuSectionTag.SALADS],
  foodVariations: [
    {
      variationId: '1',
      variationName: 'Стандартный',
      variationType: VariationType.STANDARD,
      price: 700,
      weight: 150,
      ingredients: 'салат, помидор, куриная грудка',
      CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
      proteins: 666,
      fats: 777,
      carbohydrates: 888,
      calories: 999,
      allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
      restrictedAllergyTags: [],
    },
  ],
},
  {
    id: '11',
    restaurantId: '2',
    name: 'Мраморная говядина со свежей зеленью',
    image: 'https://i.imgur.com/2L4kbPy.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 745,
        weight: 150,
        ingredients: 'куриная грудка, зелень',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 999,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '12',
    restaurantId: '2',
    name: 'Лосось под цитрусовым соусом',
    image: 'https://i.imgur.com/N6OedK8.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.RECOMMENDED],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 1300,
        weight: 400,
        ingredients: 'лосось из пруда ОРУДЬЕВО, цитрусовый соус',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 999,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '13',
    restaurantId: '2',
    name: 'Сырники ванильные с ягодным соусом',
    image: 'https://i.imgur.com/VCGF1IQ.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 350,
        weight: 279,
        ingredients: 'Творог, ягоды, ваниль',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '14',
    restaurantId: '2',
    name: 'Ролл Филадельфия',
    image: 'https://i.imgur.com/ioUk2YV.jpg',
    isOnStartPage: true,
    menuSectionTags: [MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 850,
        weight: 370,
        ingredients: 'Ролл Филадельфия',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '15',
    restaurantId: '2',
    name: 'Яичница с креветками',
    image: 'https://i.imgur.com/9aL6MeV.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.RECOMMENDED, MenuSectionTag.SEASON],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 380,
        weight: 250,
        ingredients: 'яйцо, масло, креветки, зелень',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '16',
    restaurantId: '1',
    name: 'Лимонад',
    image: 'https://i.imgur.com/FUibMv0.jpg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DRINKS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 390,
        weight: 230,
        ingredients: 'лимонад',
        CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
]

export const mockFood3: FoodPositionInfo[] = [
  {
    id: '24',
    restaurantId: '3',
    name: 'Карпаччо из говядины с рукколой и пармезаном',
    image: 'https://i.imgur.com/kSkhtZJ.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 2000,
        weight: 205,
        ingredients: 'Говяжья вырезка: 150 г; Руккола: 20 г; Пармезан: 20 г; Оливковое масло: 10 мл; Лимонный сок: 5 мл; Соль, перец: по вкусу; ',
        CFCB: 'КБЖУ на все блюдо: 302 ккал, 31.55 г белков, 19.3 г жиров, 0.96 г углеводов<br>' +
          'КБЖУ на 100 грамм: 147.32 ккал, 15.39 г белков, 9.41 г жиров, 0.47 г углеводов<br>' +
          'ХЕ: 0',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC, DietTag.HEALTHY_HEART, DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '25',
    restaurantId: '1',
    name: 'Тар-тар из лосося с авокадо и огурцом',
    image: 'https://i.imgur.com/p85CDql.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 405,
        ingredients: 'Филе лосося: 200 г; ' +
          'Авокадо: 100 г; ' +
          'Огурец: 50 г; ' +
          'Красный лук: 30 г; ' +
          'Каперсы: 10 г; ' +
          'Оливковое масло: 10 мл; ' +
          'Лимонный сок: 5 мл; ' +
          'Соль, перец: по вкусу; ',
        CFCB: 'КБЖУ на все блюдо: 726.2 ккал, 33.4 г белков, 56.95 г жиров, 20.85 г углеводов<br>' +
          'КБЖУ на 100 грамм: 179.07 ккал, 8.24 г белков, 14.06 г жиров, 5.15 г углеводов<br>' +
          'ХЕ: 2',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '26',
    restaurantId: '3',
    name: 'Антипасти (ассорти маринованных овощей и оливок)',
    image: 'https://i.imgur.com/JnybYig.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 250,
        CFCB:
          'КБЖУ на все блюдо: 420 ккал, 8.5 г белков, 36 г жиров, 9.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 168 ккал, 3.4 г белков, 14.4 г жиров, 3.8 г углеводов<br>' +
          'ХЕ: 1',
        ingredients: 'Маринованные овощи: 150 г; ' +
          'Оливки: 50 г; ' +
          'Артишоки: 50 г; ' +
          'Сыр фета: 50 г; ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN, DietTag.LESS_500, DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '27',
    restaurantId: '1',
    name: 'Паштет из куриной печени с клюквенным соусом и тостами',
    image: 'https://i.imgur.com/3k8U5uD.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 1000,
        weight: 630,
        ingredients: 'Куриная печень: 300 г; ' +
          'Лук: 100 г; ' +
          'Морковь: 50 г; ' +
          'Сливочное масло: 50 г; ' +
          'Клюквенный соус: 30 мл; ' +
          'Тосты: 100 г; ',
        CFCB: 'Масса блюда: 630 г\n' +
          'КБЖУ на все блюдо: 1407.5 ккал, 58.5 г белков, 86.7 г жиров, 84.2 г углеводов<br>' +
          'КБЖУ на 100 грамм: 223.41 ккал, 9.29 г белков, 13.77 г жиров, 13.37 г углеводов<br>' +
          'ХЕ: 7,6\n',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '28',
    restaurantId: '1',
    name: 'Капрезе с моцареллой, помидорами и базиликом',
    image: 'https://i.imgur.com/cGZH47s.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 400,
        weight: 345,
        ingredients: 'Моцарелла: 150 г; ' +
          'Помидоры: 150 г; ' +
          'Базилик: 10 г; ' +
          'Оливковое масло: 10 мл; ' +
          'Бальзамический уксус: 5 мл; ' +
          'Соль: по вкусу; ',
        CFCB: 'КБЖУ на все блюдо: 519.8 ккал, 22.2 г белков, 41.6 г жиров, 14.45 г углеводов<br>' +
          'КБЖУ на 100 грамм: 150.52 ккал, 6.43 г белков, 12.06 г жиров, 4.19 г углеводов<br>' +
          'ХЕ: 1,3',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN, DietTag.HEALTHY_STOMACH],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '29',
    restaurantId: '1',
    name: 'Рийет из утки с маринованными овощами и багетом',
    image: 'https://i.imgur.com/NW81jqr.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 450,
        CFCB: 'КБЖУ на все блюдо: 1051 ккал, 43.6 г белков, 67.5 г жиров, 50 г углеводов<br>' +
          'КБЖУ на 100 грамм: 233.56 ккал, 9.69 г белков, 15 г жиров, 11.11 г углеводов<br>' +
          'ХЕ: 5',
        ingredients: 'Утиная ножка: 200 г; ' +
          'Лук: 50 г; ' +
          'Морковь: 50 г; ' +
          'Багет: 100 г; ' +
          'Маринованные овощи: 50 г; ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.LESS_PROTEIN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '30',
    restaurantId: '3',
    name: 'Ассорти сыров с медом и орехами',
    image: 'https://i.imgur.com/RAQunph.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.COLD],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 280,
        CFCB: 'КБЖУ на все блюдо: 1244.5 ккал, 63.4 г белков, 95.2 г жиров, 38.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 444.46 ккал, 22.64 г белков, 34 г жиров, 13.75 г углеводов<br>' +
          'ХЕ: 3,45',
        ingredients: 'Сыры (бри, камамбер, дор блю, чеддер): 200 г; ' +
          'Мед: 30 г; ' +
          'Орехи: 50 г; ',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.LESS_PROTEIN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '31',
    restaurantId: '3',
    name: 'Улитки по-бургундски в чесночном масле',
    image: 'https://i.imgur.com/HQuyA1t.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 700,
        weight: 80,
        ingredients: 'Улитки: 12 шт; ' +
          'Чеснок: 20 г; ' +
          'Петрушка: 10 г; ' +
          'Сливочное масло: 50 г; ',
        CFCB: 'КБЖУ на все блюдо: 517.5 ккал, 17.5 г белков, 45.4 г жиров, 10.2 г углеводов<br>' +
          'КБЖУ на 100 грамм: 646.88 ккал, 21.88 г белков, 56.75 г жиров, 12.75 г углеводов<br>' +
          'ХЕ: 1\n',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '31',
    restaurantId: '3',
    name: 'Жюльен из грибов и курицы с сырной корочкой',
    image: 'https://i.imgur.com/6X3d4Xn.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 1000,
        weight: 550,
        ingredients: 'Грибы: 200 г; ' +
          'Куриное филе: 150 г; ' +
          'Лук: 50 г; ' +
          'Сливки: 100 мл; ' +
          'Сыр: 50 г; ',
        CFCB: 'КБЖУ на все блюдо: 968 ккал, 54.8 г белков, 65.5 г жиров, 32.3 г углеводов<br>' +
          'КБЖУ на 100 грамм: 176 ккал, 9.96 г белков, 11.91 г жиров, 5.87 г углеводов<br>' +
          'ХЕ: 3',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '32',
    restaurantId: '3',
    name: 'Креветки на гриле с чесночным соусом',
    image: 'https://i.imgur.com/0U4sezT.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 999,
        weight: 290,
        ingredients: 'Креветки: 200 г ' +
          'Чеснок: 10 г ' +
          'Оливковое масло: 20 мл ' +
          'Лимон: 50 г ' +
          'Петрушка: 10 г ',
        CFCB: 'КБЖУ на все блюдо: 462 ккал, 31.5 г белков, 26.5 г жиров, 9.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 159.31 ккал, 10.86 г белков, 9.14 г жиров, 3.28 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '33',
    restaurantId: '3',
    name: 'Жареный камамбер с клюквенным соусом',
    image: 'https://i.imgur.com/luce1KH.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 1111,
        weight: 230,
        ingredients: 'Сыр камамбер: 150 г; ' +
          'Панировочные сухари: 50 г; ' +
          'Яйцо: 1 шт; ' +
          'Клюквенный соус: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 462 ккал, 31.5 г белков, 26.5 г жиров, 9.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 159.31 ккал, 10.86 г белков, 9.14 г жиров, 3.28 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '34',
    restaurantId: '3',
    name: 'Соте из мидий в винно-сливочном соусе',
    image: 'https://i.imgur.com/wp1XojS.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 599,
        weight: 360,
        ingredients: 'Мидии: 300 г; ' +
          'Белое вино: 100 мл; ' +
          'Сливочное масло: 30 г; ' +
          'Чеснок: 20 г; ' +
          'Петрушка: 10 г; ',
        CFCB: 'КБЖУ на все блюдо: 462 ккал, 31.5 г белков, 26.5 г жиров, 9.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 159.31 ккал, 10.86 г белков, 9.14 г жиров, 3.28 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '35',
    restaurantId: '3',
    name: 'Пирог Киш Лорен с беконом и луком-пореем',
    image: 'https://i.imgur.com/ZhMggGh.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 699,
        weight: 500,
        ingredients: 'Тесто слоеное: 200 г; ' +
          'Бекон: 100 г; ' +
          'Лук-порей: 100 г; ' +
          'Яйца: 2 шт; ' +
          'Сливки: 100 мл; ' +
          'Сыр (груйер или эмменталь): 100 г; ',
        CFCB: 'КБЖУ на все блюдо: 462 ккал, 31.5 г белков, 26.5 г жиров, 9.5 г углеводов<br>' +
          'КБЖУ на 100 грамм: 159.31 ккал, 10.86 г белков, 9.14 г жиров, 3.28 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '36',
    restaurantId: '3',
    name: 'Цезарь с курицей и чесночными крутонами',
    image: 'https://i.imgur.com/e0czSTE.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 699,
        weight: 500,
        ingredients: 'Куриное филе: 150 г; ' +
          'Салат романо: 100 г; ' +
          'Помидоры черри: 50 г; ' +
          'Пармезан: 20 г; ' +
          'Чесночные крутоны: 50 г; ' +
          'Соус Цезарь: 50 мл; ',
        CFCB: 'КБЖУ на все блюдо: 643.2 ккал, 61.55 г белков, 26.6 г жиров, 36.9 г углеводов<br>' +
          'КБЖУ на 100 грамм: 153.14 ккал, 14.65 г белков, 6.33 г жиров, 8.79 г углеводов<br>' +
          'ХЕ: 3,4<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '37',
    restaurantId: '3',
    name: 'Греческий салат',
    image: 'https://i.imgur.com/lQvIgpx.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 499,
        weight: 440,
        ingredients: 'Огурцы: 100 г; ' +
          'Помидоры: 100 г; ' +
          'Красный лук: 30 г; ' +
          'Перец: 50 г; ' +
          'Оливки: 30 г; ' +
          'Фета: 100 г; ' +
          'Оливковое масло: 30 мл; ' +
          'Орегано: по вкусу;',
        CFCB: 'КБЖУ на все блюдо: 554 ккал, 17 г белков, 48 г жиров, 18 г углеводов<br>' +
          'КБЖУ на 100 грамм: 126.14 ккал, 3.86 г белков, 10.91 г жиров, 4.09 г углеводов<br>' +
          'ХЕ: 1,7<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '38',
    restaurantId: '3',
    name: 'Салат Нисуаз',
    image: 'https://i.imgur.com/Y6t0Lkg.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 998,
        weight: 430,
        ingredients: 'Тунец консервированный: 150 г; ' +
          'Зеленая фасоль: 50 г; ' +
          'Помидоры черри: 50 г; ' +
          'Картофель: 100 г; ' +
          'Яйцо: 1 шт; ' +
          'Анчоусы: 20 г; ' +
          'Оливки: 30 г; ' +
          'Оливковое масло: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 495 ккал, 28.5 г белков, 30 г жиров, 18.6 г углеводов<br>' +
          'КБЖУ на 100 грамм: 115.12 ккал, 6.63 г белков, 6.98 г жиров, 4.33 г углеводов<br>' +
          'ХЕ: 1,7<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '39',
    restaurantId: '3',
    name: 'Салат с жареной грушей и сыром дор блю',
    image: 'https://i.imgur.com/HbZYTfE.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 399,
        weight: 700,
        ingredients: 'Груша: 150 г; ' +
          'Сыр дор блю: 50 г; ' +
          'Салатный микс: 100 г; ' +
          'Орехи грецкие: 30 г; ' +
          'Мед: 20 мл; ',
        CFCB: 'КБЖУ на все блюдо: 480 ккал, 11.8 г белков, 33.2 г жиров, 31.2 г углеводов<br>' +
          'КБЖУ на 100 грамм: 160 ккал, 3.93 г белков, 11.07 г жиров, 10.4 г углеводов<br>' +
          'ХЕ: 3<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '41',
    restaurantId: '3',
    name: 'Салат с киноа и авокадо',
    image: 'https://i.imgur.com/oDHRiE4.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SALADS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 340,
        weight: 444,
        ingredients: 'Киноа: 100 г; ' +
          'Авокадо: 100 г; ' +
          'Огурец: 50 г; ' +
          'Помидоры черри: 50 г; ' +
          'Лимонный сок: 10 мл; ' +
          'Оливковое масло: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 481 ккал, 9.8 г белков, 34.7 г жиров, 28.6 г углеводов<br>' +
          'КБЖУ на 100 грамм: 141.47 ккал, 2.88 г белков, 10.21 г жиров, 8.41 г углеводов<br>' +
          'ХЕ: 2,7<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '42',
    restaurantId: '3',
    name: 'Крем-суп из тыквы',
    image: 'https://i.imgur.com/pTsWPCx.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SOUP],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 444,
        weight: 530,
        ingredients: 'Тыква: 300 г; ' +
          'Лук: 50 г; ' +
          'Морковь: 50 г; ' +
          'Сливки: 100 мл; ' +
          'Трюфельное масло: 5 мл; ',
        CFCB: 'КБЖУ на все блюдо: 328 ккал, 6.5 г белков, 19.5 г жиров, 31 г углеводов<br>' +
          'КБЖУ на 100 грамм: 61.89 ккал, 1.23 г белков, 3.68 г жиров, 5.85 г углеводов<br>' +
          'ХЕ: 3<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT, DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '43',
    restaurantId: '3',
    name: 'Французский луковый суп',
    image: 'https://i.imgur.com/unU2A5x.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SOUP],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 888,
        weight: 1000,
        ingredients: 'Лук: 300 г; ' +
          'Говяжий бульон: 500 мл; ' +
          'Белое вино: 100 мл; ' +
          'Багет: 50 г; ' +
          'Сыр (грюйер): 50 г; ',
        CFCB: 'КБЖУ на все блюдо: 480 ккал, 15 г белков, 18 г жиров, 56 г углеводов<br>' +
          'КБЖУ на 100 грамм: 48 ккал, 1.5 г белков, 1.8 г жиров, 5.6 г углеводов<br>' +
          'ХЕ: 5<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '44',
    restaurantId: '3',
    name: 'Минестроне',
    image: 'https://i.imgur.com/z7WMXLr.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.SOUP],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 790,
        weight: 480,
        ingredients: 'Кабачки: 100 г; ' +
          'Морковь: 100 г; ' +
          'Помидоры: 100 г; ' +
          'Лук: 50 г; ' +
          'Сельдерей: 50 г; ' +
          'Белая фасоль: 100 г; ' +
          'Оливковое масло: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 400 ккал, 17 г белков, 12 г жиров, 59 г углеводов<br>' +
          'КБЖУ на 100 грамм: 83.33 ккал, 3.54 г белков, 2.5 г жиров, 12.29 г углеводов<br>' +
          'ХЕ: 5,8<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '45',
    restaurantId: '3',
    name: 'Минестроне',
    image: 'https://i.imgur.com/z7WMXLr.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 790,
        weight: 480,
        ingredients: 'Кабачки: 100 г; ' +
          'Морковь: 100 г; ' +
          'Помидоры: 100 г; ' +
          'Лук: 50 г; ' +
          'Сельдерей: 50 г; ' +
          'Белая фасоль: 100 г; ' +
          'Оливковое масло: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 400 ккал, 17 г белков, 12 г жиров, 59 г углеводов<br>' +
          'КБЖУ на 100 грамм: 83.33 ккал, 3.54 г белков, 2.5 г жиров, 12.29 г углеводов<br>' +
          'ХЕ: 5,8<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.LESS_500],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '46',
    restaurantId: '3',
    name: 'Утка по-пекински',
    image: 'https://i.imgur.com/zxLVBDb.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 777,
        weight: 370,
        ingredients: 'Утка: 200 г; ' +
          'Апельсиновый соус: 100 мл; ' +
          'Соевый соус: 50 мл; ' +
          'Имбирь: 20 г; ',
        CFCB: 'КБЖУ на все блюдо: 716 ккал, 29 г белков, 61 г жиров, 11 г углеводов<br>' +
          'КБЖУ на 100 грамм: 193 ккал, 8 г белков, 16,5 г жиров, 3 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '47',
    restaurantId: '3',
    name: 'Рататуй',
    image: 'https://i.imgur.com/uquo8J9.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 750,
        weight: 600,
        ingredients: 'Баклажаны: 100 г; ' +
          'Кабачки: 100 г; ' +
          'Помидоры: 100 г; ' +
          'Перец: 100 г; ' +
          'Лук: 50 г; ' +
          'Прованские травы: 10 г; ' +
          'Оливковое масло: 30 мл; ' +
          'Утка: 500 г; ' +
          'Апельсиновый соус: 100 мл; ' +
          'Соевый соус: 50 мл; ' +
          'Имбирь: 20 г; ',
        CFCB: 'КБЖУ на все блюдо: 716 ккал, 29 г белков, 61 г жиров, 11 г углеводов<br>' +
          'КБЖУ на 100 грамм: 193 ккал, 8 г белков, 16,5 г жиров, 3 г углеводов<br>' +
          'ХЕ: 1<br>',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.DIABETIC],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '48',
    restaurantId: '3',
    name: 'Рибай-стейк',
    image: 'https://i.imgur.com/oO8Bujz.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 600,
        ingredients: 'Рибай-стейк: 300 г; ' +
          'Соус демиглас: 50 мл; ' +
          'Картофельное пюре: 200 г; ',
        CFCB: 'Масса блюда: 550 г<br>' +
          'КБЖУ на все блюдо: 1265 ккал, 71 г белков, 98 г жиров, 22 г углеводов<br>' +
          'КБЖУ на 100 грамм: 230 ккал, 12.91 г белков, 17.82 г жиров, 4 г углеводов<br>' +
          'ХЕ: 2',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '49',
    restaurantId: '3',
    name: 'Филе судака на гриле',
    image: 'https://i.imgur.com/lww5N1B.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 600,
        weight: 380,
        ingredients: 'Судак: 200 г; ' +
          'Овощи (спаржа, морковь, брокколи): 150 г; ' +
          'Лимонный соус: 30 мл; ',
        CFCB: 'КБЖУ на все блюдо: 520 ккал, 48 г белков, 29 г жиров, 14 г углеводов<br>' +
          'КБЖУ на 100 грамм: 136.84 ккал, 12.63 г белков, 7.63 г жиров, 3.68 г углеводов<br>' +
          'ХЕ: 1,3',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '50',
    restaurantId: '3',
    name: 'Лазанья Болоньезе',
    image: 'https://i.imgur.com/77F2l1C.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 900,
        weight: 560,
        ingredients: 'Листы лазаньи: 100 г; ' +
          'Фарш говяжий: 150 г; ' +
          'Лук: 50 г; ' +
          'Морковь: 50 г; ' +
          'Томатный соус: 80 мл; ' +
          'Соус бешамель: 80 мл; ',
        CFCB: 'КБЖУ на все блюдо: 1177 ккал, 54 г белков, 58 г жиров, 111 г углеводов<br>' +
          'КБЖУ на 100 грамм: 9,6 ккал, 12.63 г белков, 10,3 г жиров, 20 г углеводов<br>' +
          'ХЕ: 10',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '51',
    restaurantId: '3',
    name: 'Куриное филе по-французски',
    image: 'https://i.imgur.com/WYM17b8.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.HOT_HOT],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 900,
        weight: 500,
        ingredients: 'Куриное филе: 200 г; ' +
          'Грибы: 100 г; ' +
          'Сыр (моцарелла или чеддер): 100 г; ' +
          'Лук: 50 г; ' +
          'Майонез: 50 г; ',
        CFCB: 'КБЖУ на все блюдо: 1100 ккал, 55 г белков, 75 г жиров, 40 г углеводов<br>' +
          'КБЖУ на 100 грамм: 220 ккал, 11 г белков, 15 г жиров, 8 г углеводов<br>' +
          'ХЕ: 4',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.SPORT],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '52',
    restaurantId: '3',
    name: 'Тирамису',
    image: 'https://i.imgur.com/5OTwKNm.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 360,
        ingredients: 'Сыр маскарпоне: 100 г; ' +
          'Яйца: 1 шт; ' +
          'Сахар: 20 г; ' +
          'Печенье савоярди: 100 г; ' +
          'Кофе: 30 мл; ' +
          'Амаретто: 30 мл; ' +
          'Какао: 20 г; ',
        CFCB: 'КБЖУ на все блюдо: 864 ккал, 12 г белков, 39 г жиров, 111 г углеводов<br>' +
          'КБЖУ на 100 грамм: 240 ккал, 3,5 г белков, 11 г жиров, 31 г углеводов<br>' +
          'ХЕ: 10',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '53',
    restaurantId: '3',
    name: 'Шоколадный фондан',
    image: 'https://i.imgur.com/XPaJvYy.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 300,
        ingredients: 'Шоколад: 60 г; ' +
          'Сливочное масло: 40 г; ' +
          'Яйца: 1 шт; ' +
          'Сахар: 20 г; ' +
          'Мука: 25 г; ' +
          'Ванильное мороженое: 100 г; ',
        CFCB: 'КБЖУ на все блюдо: 1053 ккал, 16,8 г белков, 69 г жиров, 90 г углеводов<br>' +
          'КБЖУ на 100 грамм: 351 ккал, 5,6 г белков, 23 г жиров, 30 г углеводов<br>' +
          'ХЕ: 9',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '54',
    restaurantId: '3',
    name: 'Панна-котта',
    image: 'https://i.imgur.com/AtsGXBz.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 360,
        ingredients: 'Сливки: 250 мл; ' +
          'Желатин: 10 г; ' +
          'Сахар: 50 г; ' +
          'Ваниль: 1 стручок; ' +
          'Ягодный соус: 50 мл; ',
        CFCB: 'КБЖУ на все блюдо: 1190 ккал, 11 г белков, 92 г жиров, 72 г углеводов<br>' +
          'КБЖУ на 100 грамм: 330.56 ккал, 3.06 г белков, 25.56 г жиров, 20 г углеводов<br>' +
          'ХЕ: 7',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
  {
    id: '55',
    restaurantId: '3',
    name: 'Яблочный штрудель',
    image: 'https://i.imgur.com/IBgRQZH.jpeg',
    isOnStartPage: false,
    menuSectionTags: [MenuSectionTag.DESERTS],
    foodVariations: [
      {
        variationId: '1',
        variationName: 'Стандартный',
        variationType: VariationType.STANDARD,
        price: 500,
        weight: 405,
        ingredients: 'Яблоки: 130 г; ' +
          'Слоеное тесто: 50 г; ' +
          'Сахар: 30 г; ' +
          'Корица: 5 г; ' +
          'Изюм: 20 г; ' +
          'Орехи: 20 г; ' +
          'Ванильный соус: 50 мл; ' +
          'Ванильное мороженое: 100 г; ',
        CFCB: 'КБЖУ на все блюдо: 846 ккал, 11 г белков, 36 г жиров, 118 г углеводов<br>' +
          'КБЖУ на 100 грамм: 209 ккал, 3 г белков, 9 г жиров, 29 г углеводов<br>' +
          'ХЕ: 10,5',
        proteins: 666,
        fats: 777,
        carbohydrates: 888,
        calories: 999,
        allowedDietsTags: [DietTag.VEGETARIAN],
        restrictedAllergyTags: [],
      },
    ],
  },
]

export const rest1 = {
  menuSectionsTags: [MenuSectionTag.RECOMMENDED, MenuSectionTag.SEASON, MenuSectionTag.SALADS, MenuSectionTag.DRINKS, MenuSectionTag.DESERTS],
  address: "Дмитровское ш. д 68, пом. 1, Москва",
  closingTime: new Date(0, 0, 0, 22, 0),
  openingTime: new Date(0, 0, 0, 8, 0),
  description: "В вихре современных вкусов и стильного дизайна заслуживает внимания наш модный ресторан, где гастрономические изыски и эстетика сочетаются в гармонии. Отпустите свои чувства на волнение инноваций, наслаждаясь изысканными блюдами, созданными нашим талантливым шеф-поваром, чьи кулинарные творения становятся настоящим произведением искусства.",
  foodPositions: mockFood1,
  image: "https://i.imgur.com/JDTMWA2.jpg",
  mediumCheck: MediumCheck.MEDIUM,
  restaurantInfoTags: [RestaurantInfoTag.DIET_DISHES, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
  name: "Futur bar",
  rate: 4.8
}

export const rest2 = {
  menuSectionsTags: [MenuSectionTag.SEASON, MenuSectionTag.RECOMMENDED, MenuSectionTag.SALADS],
  address: "Дмитровское ш. д 68, пом. 1, Москва",
  closingTime: new Date(0, 0, 0, 23, 0),
  openingTime: new Date(0, 0, 0, 10, 0),
  description: "В вихре современных вкусов и стильного дизайна заслуживает внимания наш модный ресторан, где гастрономические изыски и эстетика сочетаются в гармонии. Отпустите свои чувства на волнение инноваций, наслаждаясь изысканными блюдами, созданными нашим талантливым шеф-поваром, чьи кулинарные творения становятся настоящим произведением искусства.",
  foodPositions: mockFood2,
  image: "https://i.imgur.com/JDTMWA2.jpg",
  mediumCheck: MediumCheck.MEDIUM,
  restaurantInfoTags: [RestaurantInfoTag.DIET_DISHES, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
  name: "Кофемания",
  rate: 4.6
}

export const rest3 = {
  menuSectionsTags: [MenuSectionTag.COLD, MenuSectionTag.HOT, MenuSectionTag.SALADS, MenuSectionTag.SOUP, MenuSectionTag.HOT_HOT, MenuSectionTag.DESERTS],
  address: "Новослободская ул., 68а",
  closingTime: new Date(0, 0, 0, 0, 0),
  openingTime: new Date(0, 0, 0, 11, 0),
  description: "Добро пожаловать в наш ресторан, где гастрономические шедевры сочетаются с уютной атмосферой! Мы предлагаем разнообразное меню, созданное с любовью и из самых свежих продуктов. У нас вы найдете как классические блюда, так и авторские изыски от шеф-повара, способные удивить даже самых требовательных гурманов. Каждое блюдо сопровождается изысканными напитками, а внимание к деталям сделает ваше посещение незабываемым. Окунитесь в мир вкусов и наслаждений!",
  foodPositions: mockFood3,
  image: "https://i.imgur.com/k4UBFqj.png",
  mediumCheck: MediumCheck.BIG,
  restaurantInfoTags: [RestaurantInfoTag.ITALIAN, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
  name: "Ресторан европейской кухни",
  rate: 4.6
}


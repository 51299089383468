import {VariationType} from "../../../constants/content_types/FoodInfo";
import {DietTag} from "../../../constants/content_types/DietTag";
import {RestaurantSectionProps} from "./restaurant_section/RestaurantSection";
import {RestaurantInfoTag} from "../../../constants/content_types/RestaurantInfoTag";
import {MenuSectionTag} from "../../../constants/content_types/MenuSectionTag";

export const MockHomepageData: RestaurantSectionProps[] = [
  {
    id: '1',
    name: 'Futur bar',
    restaurantInfoTags: [RestaurantInfoTag.DIET_DISHES, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
    positions: [
      {
        id: '1',
        restaurantId: '1',
        name: 'Картофель фри с трюфельным маслом',
        image: 'https://i.imgur.com/6dtZI35.jpg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 700,
            weight: 150,
            ingredients: 'картофель фри, трюфельное масло',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
            restrictedAllergyTags: [],
          },
          {
            variationId: '2',
            variationName: 'С сыром',
            variationType: VariationType.CUSTOM,
            price: 800,
            weight: 170,
            ingredients: 'картофель фри, трюфельное масло, сыр',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 222,
            fats: 333,
            carbohydrates: 444,
            calories: 555,
            allowedDietsTags: [DietTag.HEALTHY_HEART, DietTag.VEGAN],
            restrictedAllergyTags: [],
          }
        ],
      },
      {
        id: '2',
        restaurantId: '1',
        name: 'Авторский салат с авокадо и гранатом',
        image: 'https://i.imgur.com/RWer6Wh.jpg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 600,
            weight: 150,
            ingredients: 'салатный лист, авокадо, гранат, гренки, петрушка, лук, капуста',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '3',
        restaurantId: '1',
        name: 'Тыквенный суп-пюре',
        image: 'https://i.imgur.com/nLjUw2s.jpg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 444,
            weight: 150,
            ingredients: 'суп',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH],
            restrictedAllergyTags: [],
          },
          {
            variationId: '2',
            variationName: 'С семечками',
            variationType: VariationType.CUSTOM,
            price: 586,
            weight: 170,
            ingredients: 'суп, семечки',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 222,
            fats: 333,
            carbohydrates: 444,
            calories: 555,
            allowedDietsTags: [DietTag.DIABETIC, DietTag.LESS_PROTEIN],
            restrictedAllergyTags: [],
          }
        ],
      },
      {
        id: '4',
        restaurantId: '1',
        name: 'Чай',
        image: 'https://i.imgur.com/uuiNeFb.jpg',
        isOnStartPage: false,
        menuSectionTags: [MenuSectionTag.DRINKS],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 100,
            weight: 150,
            ingredients: 'чай',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '5',
        restaurantId: '1',
        name: 'Кофе',
        image: 'https://i.imgur.com/pt5XXEj.jpg',
        isOnStartPage: false,
        menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.RECOMMENDED],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 200,
            weight: 200,
            ingredients: 'кофе',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '6',
        restaurantId: '1',
        name: 'Лимонад',
        image: 'https://i.imgur.com/tX5OI7y.jpg',
        isOnStartPage: false,
        menuSectionTags: [MenuSectionTag.DRINKS, MenuSectionTag.SEASON],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 250,
            weight: 200,
            ingredients: 'лимонад',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
    ]
  },
  {
    id: '2',
    name: 'Кофемания',
    restaurantInfoTags: [RestaurantInfoTag.DIET_DISHES, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
    positions: [{
      id: '10',
      restaurantId: '2',
      name: 'Салат с помидорами и куриной грудкой',
      image: 'https://i.imgur.com/lv62Sr6.jpg',
      isOnStartPage: true,
      menuSectionTags: [],
      foodVariations: [
        {
          variationId: '1',
          variationName: 'Стандартный',
          variationType: VariationType.STANDARD,
          price: 700,
          weight: 150,
          ingredients: 'салат, помидор, куриная грудка',
          CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
          proteins: 666,
          fats: 777,
          carbohydrates: 888,
          calories: 999,
          allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
          restrictedAllergyTags: [],
        },
      ],
    },
    {
      id: '11',
      restaurantId: '2',
      name: 'Мраморная говядина со свежей зеленью',
      image: 'https://i.imgur.com/2L4kbPy.jpg',
      isOnStartPage: false,
      menuSectionTags: [],
      foodVariations: [
        {
          variationId: '1',
          variationName: 'Стандартный',
          variationType: VariationType.STANDARD,
          price: 745,
          weight: 150,
          ingredients: 'куриная грудка, зелень',
          CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
          proteins: 999,
          fats: 777,
          carbohydrates: 888,
          calories: 999,
          allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
          restrictedAllergyTags: [],
        },
      ],
    },
    {
      id: '12',
      restaurantId: '2',
      name: 'Лосось под цитрусовым соусом',
      image: 'https://i.imgur.com/N6OedK8.jpg',
      isOnStartPage: true,
      menuSectionTags: [],
      foodVariations: [
        {
          variationId: '1',
          variationName: 'Стандартный',
          variationType: VariationType.STANDARD,
          price: 1300,
          weight: 400,
          ingredients: 'лосось из пруда ОРУДЬЕВО, цитрусовый соус',
          CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
          proteins: 999,
          fats: 777,
          carbohydrates: 888,
          calories: 999,
          allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_HEART],
          restrictedAllergyTags: [],
        },
      ],
    },
      {
        id: '13',
        restaurantId: '2',
        name: 'Сырники ванильные с ягодным соусом',
        image: 'https://i.imgur.com/VCGF1IQ.jpg',
        isOnStartPage: true,
        menuSectionTags: [MenuSectionTag.DESERTS],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 350,
            weight: 279,
            ingredients: 'Творог, ягоды, ваниль',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '14',
        restaurantId: '2',
        name: 'Ролл Филадельфия',
        image: 'https://i.imgur.com/ioUk2YV.jpg',
        isOnStartPage: true,
        menuSectionTags: [MenuSectionTag.SEASON, MenuSectionTag.RECOMMENDED],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 850,
            weight: 370,
            ingredients: 'Ролл Филадельфия',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.LESS_500],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '15',
        restaurantId: '2',
        name: 'Яичница с креветками',
        image: 'https://i.imgur.com/9aL6MeV.jpg',
        isOnStartPage: false,
        menuSectionTags: [MenuSectionTag.RECOMMENDED, MenuSectionTag.SEASON],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 380,
            weight: 250,
            ingredients: 'яйцо, масло, креветки, зелень',
            CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
            restrictedAllergyTags: [],
          },
        ],
      },
    ]
  },

  {
    id: '3',
    name: 'Ресторан европейской кухни',
    restaurantInfoTags: [RestaurantInfoTag.DIET_DISHES, RestaurantInfoTag.SPECIAL_KITCHEN, RestaurantInfoTag.VEGAN_FRIENDLY],
    positions: [
      {
        id: '24',
        restaurantId: '3',
        name: 'Карпаччо из говядины с рукколой и пармезаном',
        image: 'https://i.imgur.com/kSkhtZJ.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 2000,
            weight: 205,
            ingredients: 'Говяжья вырезка: 150 г; Руккола: 20 г; Пармезан: 20 г; Оливковое масло: 10 мл; Лимонный сок: 5 мл; Соль, перец: по вкусу; ',
            CFCB: 'КБЖУ на все блюдо: 302 ккал, 31.55 г белков, 19.3 г жиров, 0.96 г углеводов<br>' +
              'КБЖУ на 100 грамм: 147.32 ккал, 15.39 г белков, 9.41 г жиров, 0.47 г углеводов<br>' +
              'ХЕ: 0',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.HEALTHY_STOMACH, DietTag.DIABETIC, DietTag.HEALTHY_HEART, DietTag.LESS_500],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '25',
        restaurantId: '1',
        name: 'Тар-тар из лосося с авокадо и огурцом',
        image: 'https://i.imgur.com/p85CDql.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 600,
            weight: 405,
            ingredients: 'Филе лосося: 200 г; ' +
              'Авокадо: 100 г; ' +
              'Огурец: 50 г; ' +
              'Красный лук: 30 г; ' +
              'Каперсы: 10 г; ' +
              'Оливковое масло: 10 мл; ' +
              'Лимонный сок: 5 мл; ' +
              'Соль, перец: по вкусу; ',
            CFCB: 'КБЖУ на все блюдо: 726.2 ккал, 33.4 г белков, 56.95 г жиров, 20.85 г углеводов<br>' +
              'КБЖУ на 100 грамм: 179.07 ккал, 8.24 г белков, 14.06 г жиров, 5.15 г углеводов<br>' +
              'ХЕ: 2',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH, DietTag.DIABETIC],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '26',
        restaurantId: '3',
        name: 'Антипасти (ассорти маринованных овощей и оливок)',
        image: 'https://i.imgur.com/JnybYig.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 500,
            weight: 250,
            CFCB:
              'КБЖУ на все блюдо: 420 ккал, 8.5 г белков, 36 г жиров, 9.5 г углеводов<br>' +
              'КБЖУ на 100 грамм: 168 ккал, 3.4 г белков, 14.4 г жиров, 3.8 г углеводов<br>' +
              'ХЕ: 1',
            ingredients: 'Маринованные овощи: 150 г; ' +
              'Оливки: 50 г; ' +
              'Артишоки: 50 г; ' +
              'Сыр фета: 50 г; ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN, DietTag.LESS_500, DietTag.DIABETIC],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '27',
        restaurantId: '1',
        name: 'Паштет из куриной печени с клюквенным соусом и тостами',
        image: 'https://i.imgur.com/3k8U5uD.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 1000,
            weight: 630,
            ingredients: 'Куриная печень: 300 г; ' +
              'Лук: 100 г; ' +
              'Морковь: 50 г; ' +
              'Сливочное масло: 50 г; ' +
              'Клюквенный соус: 30 мл; ' +
              'Тосты: 100 г; ',
            CFCB: 'Масса блюда: 630 г\n' +
              'КБЖУ на все блюдо: 1407.5 ккал, 58.5 г белков, 86.7 г жиров, 84.2 г углеводов<br>' +
              'КБЖУ на 100 грамм: 223.41 ккал, 9.29 г белков, 13.77 г жиров, 13.37 г углеводов<br>' +
              'ХЕ: 7,6\n',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.HEALTHY_STOMACH],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '28',
        restaurantId: '1',
        name: 'Капрезе с моцареллой, помидорами и базиликом',
        image: 'https://i.imgur.com/cGZH47s.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 400,
            weight: 345,
            ingredients: 'Моцарелла: 150 г; ' +
              'Помидоры: 150 г; ' +
              'Базилик: 10 г; ' +
              'Оливковое масло: 10 мл; ' +
              'Бальзамический уксус: 5 мл; ' +
              'Соль: по вкусу; ',
            CFCB: 'КБЖУ на все блюдо: 519.8 ккал, 22.2 г белков, 41.6 г жиров, 14.45 г углеводов<br>' +
              'КБЖУ на 100 грамм: 150.52 ккал, 6.43 г белков, 12.06 г жиров, 4.19 г углеводов<br>' +
              'ХЕ: 1,3',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN, DietTag.HEALTHY_STOMACH],
            restrictedAllergyTags: [],
          },
        ],
      },
      {
        id: '29',
        restaurantId: '1',
        name: 'Рийет из утки с маринованными овощами и багетом',
        image: 'https://i.imgur.com/NW81jqr.jpeg',
        isOnStartPage: false,
        menuSectionTags: [],
        foodVariations: [
          {
            variationId: '1',
            variationName: 'Стандартный',
            variationType: VariationType.STANDARD,
            price: 600,
            weight: 450,
            CFCB: 'КБЖУ на все блюдо: 1051 ккал, 43.6 г белков, 67.5 г жиров, 50 г углеводов<br>' +
              'КБЖУ на 100 грамм: 233.56 ккал, 9.69 г белков, 15 г жиров, 11.11 г углеводов<br>' +
              'ХЕ: 5',
            ingredients: 'Утиная ножка: 200 г; ' +
              'Лук: 50 г; ' +
              'Морковь: 50 г; ' +
              'Багет: 100 г; ' +
              'Маринованные овощи: 50 г; ',
            proteins: 666,
            fats: 777,
            carbohydrates: 888,
            calories: 999,
            allowedDietsTags: [DietTag.SPORT, DietTag.LESS_PROTEIN],
            restrictedAllergyTags: [],
          },
        ],
      },
    ]
  },
]

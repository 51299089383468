import './QrCodeButton.css'
import '../../../../assets/styles/animation_durations.css'
import React from "react";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../../constants/enums/RoutePaths";

const QrCodeButton: React.FC = () => {
  const navigate = useNavigate()

  return(
    <div
      className='qr-btn-wrapper animation-02s-all'
      onClick={() => navigate(RoutePaths.SCAN_QR)}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.0351562 0H8.75195V8.69438H0.0351562V0ZM21.7773 21.8093H24V24H21.7773V21.8093ZM17.5059 21.8054H19.7285V23.8924H17.5059H17.5039H15.2832V19.6245H17.4355V17.4611H19.625V13.1482H21.8477V15.2685H23.9648V17.4592H21.8477V19.6499H19.6582H19.625H17.5059V21.8054ZM10.9062 17.4259H13.0586V15.2352H10.9766V13.0445H13.0586V10.8538H10.9414V13.0445H8.7168V10.8538H10.9043V4.34817H13.127V10.8518H15.2793V13.0425H17.3965V10.8518H19.6191V13.0425H17.5059V15.2333H15.2832V19.5462H13.1289V23.8944H10.9062V17.4259ZM21.7402 10.8518H23.9629V13.0425H21.7402V10.8518ZM4.37695 10.8518H6.59961V13.0425H4.37695V10.8518ZM0.0351562 10.8518H2.25781V13.0425H0.0351562V10.8518ZM10.9062 0H13.1289V2.19071H10.9062V0ZM0 15.2685H8.7168V23.9628H0V15.2685ZM2.11719 17.3809H6.59961V21.8523H2.11719V17.3809ZM15.2461 0H23.9629V8.69438H15.2461V0ZM17.3652 2.11247H21.8477V6.58386H17.3652V2.11247ZM2.15234 2.11247H6.63477V6.58386H2.15234V2.11247Z" fill="white"/>
      </svg>
    </div>
  )
}

export default QrCodeButton
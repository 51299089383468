import React, {useMemo, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import './../../../../assets/styles/animation_durations.css'

export enum SquareButtonIconType {
  ARROW_BACK,
  ARROW_UP
}

interface SquareButtonProps {
  onClickAction: () => void;
  iconType: SquareButtonIconType;
}

const SquareButton: React.FC<SquareButtonProps> = ({ onClickAction, iconType }) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  const resolvedIcon = useMemo(() => {
    switch (iconType) {
      case SquareButtonIconType.ARROW_BACK:
        return(
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.5 24L15 24M15 24L22 31M15 24L22 17" stroke="#2C2D2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )
      case SquareButtonIconType.ARROW_UP:
        return (
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 20.75L11.25 4.25M11.25 4.25L4.25 11.25M11.25 4.25L18.25 11.25" stroke="#2C2D2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )
      default:
        return <></>
    }
  }, [iconType])

  return(
    <div
      className={'animation-02s-all'}
      onClick={onClickAction}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing:'border-box',
        width: '48px',
        height: '48px',
        borderRadius: '12px',
        cursor: isClicked || isHovered ? 'pointer' : undefined,
        backgroundColor: isClicked ? 'var(--on-click-gray-light, #CFD2DB)' : isHovered ? 'var(--on-hover-gray-light, #E7E9F0)' : 'var(--gray-light, #F3F4F6)'
      }}
    >
      {resolvedIcon}
    </div>
  )
}

export default SquareButton
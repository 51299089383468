import {OrderStatus} from "../../../../constants/content_types/OrderStatus";
import {OrderPosition} from "../../../../redux/current_order_reducer/CurrentOrderReducer";
import React, {useMemo, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import './OrderHistoryItem.css'
import {formatOrderType, getTotalOrderPrice} from "../../../../redux/current_order_reducer/OrderOperations";
import {useDispatch} from "react-redux";
import {setDisplayedOrder} from "../../../../redux/displayed_order_info_reducer/DisplayedOrderInfoReducer";
import TagButton, {TagButtonType} from "../../../shared_components/buttons/tag_button/TagButton";

export interface CompletedOrderState {
  orderId: string;
  restaurantId: string;
  orderStatus: OrderStatus;
  timestamp: number;
  restaurantName: string,
  restaurantAddress: string;
  orderPositions: OrderPosition[];
  withButton?: boolean;
}

const OrderHistoryItem: React.FC<CompletedOrderState> = ({ restaurantAddress, orderPositions, timestamp, restaurantName, orderStatus, restaurantId, orderId, withButton}) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })
  const dispatch = useDispatch()

  const formattedTime = useMemo(() => {
    return formatOrderType(timestamp)
  }, [timestamp]);

  const totalPrice = useMemo(() => {
    return getTotalOrderPrice(orderPositions)
  }, [orderPositions])

  return(
    <div
      className={`order-history-item-wrapper ${!isMobile && 'desktop'} animation-02s-all`}
      style={{
        backgroundColor: isClicked ? 'var(--gray-light, #F3F4F6)' : isHovered ? 'var(--light-colors-light, #F6F9FA)' : 'white'
      }}
      onClick={() => dispatch(setDisplayedOrder({
        timestamp: timestamp,
        orderStatus:  orderStatus,
        orderId: orderId,
        orderPositions: orderPositions,
        restaurantId: restaurantId,
        restaurantName: restaurantName,
        restaurantAddress: restaurantAddress
      }))}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
    >
      <div className={'h-wrpr'}>
        <div className={`text-primary ${isMobile ? 'mobile-h4' : 'desktop-h4'}`}>
          {`Заказ ${formattedTime}`}
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className={'animation-02s-all'} d="M10 17L14.2929 12.7071C14.6834 12.3166 14.6834 11.6834 14.2929 11.2929L10 7" stroke={isClicked ? 'var(--on-click-ym-green, #00C956)' : isHovered ? 'var(--on-hover-ym-green, #00C956)' : '#C6C9D1'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className={`text-secondary ${isMobile ? 'mobile-subtext' : 'desktop-subtext'}`}>
        {`${restaurantName},\u00A0 ${restaurantAddress}`}
      </div>
      <div className={`total-price-wrapper ${!isMobile && 'desktop'}`}>
        <div className={`text-secondary ${isMobile ? 'mobile-h4' : 'desktop-h4'}`}>
          Сумма
        </div>
        <div className={`text-secondary ${isMobile ? 'mobile-h4' : 'desktop-h4'}`}>
          {totalPrice}
        </div>
        <div className={`text-secondary ${isMobile ? 'mobile-h4' : 'desktop-h4'}`}>
          ₽
        </div>
      </div>
      {withButton && (
        <div style={{ maxWidth: '175px'}}>
          <TagButton
            onClickAction={() => dispatch(setDisplayedOrder({
              timestamp: timestamp,
              orderStatus:  orderStatus,
              orderId: orderId,
              orderPositions: orderPositions,
              restaurantId: restaurantId,
              restaurantName: restaurantName,
              restaurantAddress: restaurantAddress
            }))}
            isActive={false}
            text={'Посмотреть заказ'}
            type={TagButtonType.MOBILE}
            withArrow={true}
          />
        </div>
      )}
    </div>
  )
}

export default OrderHistoryItem
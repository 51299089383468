import './NavBarItem.css'
import '../../../../assets/styles/fonts.css'
import '../../../../assets/styles/animation_durations.css'
import React from "react";
import useRipple from "use-ripple-hook";

interface NavBarItemProps {
  activeImage: any;
  inactiveImage: any;
  name: string;
  onClickAction: () => void;
  isActive: boolean;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ activeImage, inactiveImage, name, onClickAction, isActive}) => {
  const [ripple, event] = useRipple({
    duration: 800,
    color: "rgba(145,145,145,0.09)",
    cancelAutomatically: true,
  });

  return(
    <div
      ref={ripple}
      onPointerDown={event}
      className='navbar-item-wrapper'
      onClick={onClickAction}
    >
      {isActive ? activeImage : inactiveImage}
      <div className={`mobile-subtext ${isActive && 'green'} noselect`}>{name}</div>
    </div>
  )
}

export default NavBarItem
import './NavigationBar.css'
import '../../../assets/styles/animation_durations.css'
import React from "react";
import NavBarItem from "./nav_bar_item/NavBarItem";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import ProfileButton from "./profile_button/ProfileButton";
import MyOrderNavButton from "./order_button/MyOrderNavButton";

const NavigationBar: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isHidden =
    location.pathname !== RoutePaths.HOME &&
    location.pathname !== RoutePaths.MY_ORDER &&
    location.pathname !== RoutePaths.PROFILE &&
    location.pathname !== RoutePaths.LOGIN

  return(
    <div className={`navbar-wrapper ${isHidden && 'closed'} animation-02s-all`}>
      <NavBarItem
        activeImage={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_35_2163)">
              <path d="M13.341 22.7448H10.659C9.95368 22.7448 9.32111 22.7448 8.75 22.7386V16C8.75 14.7574 9.75736 13.75 11 13.75H13C14.2426 13.75 15.25 14.7574 15.25 16V22.7386C14.6789 22.7448 14.0463 22.7448 13.341 22.7448Z" fill="#00DF5F"/>
              <path d="M16.75 22.6974V16C16.75 13.9289 15.0711 12.25 13 12.25H11C8.92893 12.25 7.25 13.9289 7.25 16V22.6974C5.92073 22.6284 5.00849 22.4587 4.24435 22.0414C3.27686 21.5132 2.48161 20.7179 1.95333 19.7504C1.25 18.4623 1.25 16.7535 1.25 13.3357C1.25 11.4158 1.25 10.4559 1.50578 9.5713C1.69979 8.90029 2.00069 8.26494 2.39691 7.6897C2.91925 6.93133 3.66192 6.32313 5.14727 5.10672L5.39074 4.90734C8.06398 2.71812 9.4006 1.62351 10.9295 1.34627C11.6374 1.21791 12.3626 1.21791 13.0705 1.34627C14.5994 1.62351 15.936 2.71811 18.6093 4.90733L18.8527 5.10672C20.3381 6.32313 21.0808 6.93133 21.6031 7.6897C21.9993 8.26494 22.3002 8.90029 22.4942 9.5713C22.75 10.4559 22.75 11.4158 22.75 13.3357C22.75 16.7535 22.75 18.4623 22.0467 19.7504C21.5184 20.7179 20.7231 21.5132 19.7556 22.0414C18.9915 22.4587 18.0793 22.6284 16.75 22.6974Z" fill="#00DF5F"/>
            </g>
            <defs>
              <clipPath id="clip0_35_2163">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        }
        inactiveImage={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_46_3221)">
              <path d="M13.341 22.7448H10.659C9.95368 22.7448 9.32111 22.7448 8.75 22.7386V16C8.75 14.7574 9.75736 13.75 11 13.75H13C14.2426 13.75 15.25 14.7574 15.25 16V22.7386C14.6789 22.7448 14.0463 22.7448 13.341 22.7448Z" fill="#C6C9D1"/>
              <path opacity="0.5" d="M16.75 22.6974V16C16.75 13.9289 15.0711 12.25 13 12.25H11C8.92893 12.25 7.25 13.9289 7.25 16V22.6974C5.92073 22.6284 5.00849 22.4587 4.24435 22.0414C3.27686 21.5132 2.48161 20.7179 1.95333 19.7504C1.25 18.4623 1.25 16.7535 1.25 13.3357C1.25 11.4158 1.25 10.4559 1.50578 9.5713C1.69979 8.90029 2.00069 8.26494 2.39691 7.6897C2.91925 6.93133 3.66192 6.32313 5.14727 5.10672L5.39074 4.90734C8.06398 2.71812 9.4006 1.62351 10.9295 1.34627C11.6374 1.21791 12.3626 1.21791 13.0705 1.34627C14.5994 1.62351 15.936 2.71811 18.6093 4.90733L18.8527 5.10672C20.3381 6.32313 21.0808 6.93133 21.6031 7.6897C21.9993 8.26494 22.3002 8.90029 22.4942 9.5713C22.75 10.4559 22.75 11.4158 22.75 13.3357C22.75 16.7535 22.75 18.4623 22.0467 19.7504C21.5184 20.7179 20.7231 21.5132 19.7556 22.0414C18.9915 22.4587 18.0793 22.6284 16.75 22.6974Z" fill="#C6C9D1"/>
            </g>
            <defs>
              <clipPath id="clip0_46_3221">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        }
        name={'Рестораны'}
        onClickAction={() => navigate(RoutePaths.HOME)}
        isActive={location.pathname === RoutePaths.HOME}
      />
      <MyOrderNavButton/>
      <ProfileButton/>
    </div>
  )
}

export default NavigationBar

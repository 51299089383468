export enum MenuSectionTag {
  RECOMMENDED = 'Рекомендуем',
  SEASON = 'Сезонное меню',
  SALADS = 'Салаты и закуски',
  DRINKS = 'Напитки',
  DESERTS = 'Десерты',
  COLD = 'Холодные закуки',
  HOT = 'Горячие закуски',
  SOUP = 'Супы',
  HOT_HOT = 'Горячие блюда'
}

import './ProfileButton.css'
import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../../constants/enums/RoutePaths";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import Sheet from "react-modal-sheet";
import {
  displayCommonProfileSheet,
  hideProfileSheet,
  ProfileBottomSheetType
} from "../../../../redux/profile_bottom_sheet_reducer/ProfileBottomSheetReducer";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../buttons/standard_button/StandardButton";
import {logoutUser} from "../../../../redux/user_info_reducer/UserInfoReducer";
import Avatar, {AvatarType} from "../../avatar/Avatar";
import useRipple from "use-ripple-hook";
import {clearAllPreferencesTags} from "../../../../redux/preferences_reducer/PreferencesReducer";
import {clearAllOrderPositions} from "../../../../redux/current_order_reducer/CurrentOrderReducer";

const ProfileButton: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [resizeTimeouts, setResizeTimeouts] = useState<NodeJS.Timeout[]>([])

  const userState = useSelector((state: RootState) => state.userInfo.userInfo)
  const profileSheetState = useSelector((state: RootState) => state.profileBottomSheet)
  const isActive = useMemo(() => location.pathname === RoutePaths.PROFILE || location.pathname === RoutePaths.LOGIN, [location.pathname])

  const [ripple, event] = useRipple({
    duration: 800,
    color: "rgba(145,145,145,0.09)",
    cancelAutomatically: true,
  });

  useEffect(() => {
    const handleResize = () => {
      dispatch(hideProfileSheet())
    }

    if (!profileSheetState.isOpened) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('profile-btn-all-wrapper');
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('profile-btn-all-wrapper')
      setResizeTimeouts([...resizeTimeouts, setTimeout(() => {
        if (profileSheetState.isOpened) {
          window.addEventListener('resize', handleResize)
        }
      }, 1000)])
    }

    return() => {
      document.body.classList.add('profile-btn-all-wrapper')
      document.body.classList.remove('hidden')
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    }
  }, [profileSheetState.isOpened]);

  useEffect(() => {
    if (profileSheetState?.type !== ProfileBottomSheetType.FIRST) {
      dispatch(hideProfileSheet())
    }
  }, [location?.pathname]);

  const icon = useMemo(() => {
    if (userState?.bearerToken !== '') {
      return <Avatar type={AvatarType.NAV_BAR} />
    } else {
      if (isActive) {
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_46_3174)">
              <path d="M6.19898 21.052C7.87246 22.1267 9.86344 22.75 12 22.75C14.1366 22.75 16.1275 22.1267 17.801 21.052C16.726 19.0482 14.6201 17.75 12.2798 17.75H11.7202C9.37994 17.75 7.27405 19.0482 6.19898 21.052Z" fill="#00DF5F"/>
              <path d="M12 6.75C10.2051 6.75 8.75 8.20507 8.75 10C8.75 11.7949 10.2051 13.25 12 13.25C13.7949 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.7949 6.75 12 6.75Z" fill="#00DF5F"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M4.98597 20.1468C2.69829 18.1754 1.25 15.2568 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 15.2568 21.3017 18.1754 19.014 20.1468C17.6517 17.7748 15.1034 16.25 12.2798 16.25H11.7202C8.89663 16.25 6.34834 17.7748 4.98597 20.1468ZM7.25 10C7.25 7.37665 9.37665 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37665 14.75 7.25 12.6234 7.25 10Z" fill="#00DF5F"/>
            </g>
            <defs>
              <clipPath id="clip0_46_3174">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )
      } else {
        return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_101_5455)">
              <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M4.98597 20.1468C2.69829 18.1754 1.25 15.2568 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 15.2568 21.3017 18.1754 19.014 20.1468C17.6517 17.7748 15.1034 16.25 12.2798 16.25H11.7202C8.89663 16.25 6.34834 17.7748 4.98597 20.1468ZM7.25 10C7.25 7.37665 9.37665 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37665 14.75 7.25 12.6234 7.25 10Z" fill="#C6C9D1"/>
              <path d="M12 6.75C10.2051 6.75 8.75 8.20507 8.75 10C8.75 11.7949 10.2051 13.25 12 13.25C13.7949 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.7949 6.75 12 6.75Z" fill="#C6C9D1"/>
              <path d="M6.19922 21.052C7.8727 22.1267 9.86368 22.75 12.0002 22.75C14.1368 22.75 16.1278 22.1267 17.8013 21.052C16.7262 19.0482 14.6203 17.75 12.2801 17.75H11.7204C9.38017 17.75 7.27428 19.0482 6.19922 21.052Z" fill="#C6C9D1"/>
            </g>
            <defs>
              <clipPath id="clip0_101_5455">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )
      }
    }
  }, [userState, isActive])

  return(
    <>
      {profileSheetState.isOpened && (
        <div
          style={{ position: 'absolute', top: -1000, left: -10000, width: window.innerWidth * 10000, height: window.innerHeight * 100000, zIndex: 99999999 }}
          onClick={(e) => {
            e.preventDefault()
            dispatch(hideProfileSheet())
          }}
        />
      )}
      <div
        ref={ripple}
        onPointerDown={event}
        className='profile-button-wrapper'
        onClick={() => {
          if (userState.bearerToken !== '') {
            dispatch(displayCommonProfileSheet())
          } else {
            navigate(RoutePaths.LOGIN)
          }
        }}
      >
        {icon}
        <div className={`mobile-subtext ${isActive && 'green'} noselect`}>{userState.bearerToken !== '' ? 'Профиль' : 'Войти'}</div>
      </div>
      <Sheet
        isOpen={profileSheetState.isOpened}
        onClose={() => dispatch(hideProfileSheet())}
        detent={"content-height"}
        snapPoints={[500, 0]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content disableDrag={true}>
            <div className={'profile-sheet-wrapper'}>
              {profileSheetState.type === ProfileBottomSheetType.COMMON ? (
                <>
                  <div className={'avatar-section-wrapper'}>
                    <Avatar type={AvatarType.MOBILE} onClickAction={() => navigate(RoutePaths.PROFILE)}/>
                    <div className={'mobile-h3 name'}>
                      {(userState?.name?.length > 0
                          ? userState?.name : userState?.email?.length > 0
                            ? userState?.email
                            : ''
                      )}
                    </div>
                  </div>
                  <div className={'btns-wrapper'}>
                    <StandardButton
                      onClickAction={() => {
                        navigate(RoutePaths.PROFILE)
                        dispatch(hideProfileSheet())
                      }}
                      text={'Мои данные'}
                      color={StandardButtonColor.GRAY}
                      iconType={StandardButtonIconType.BLACK_USER_ROUND_ICON}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                    <StandardButton
                      onClickAction={() => {
                        dispatch(hideProfileSheet())
                        dispatch(logoutUser())
                        dispatch(clearAllPreferencesTags())
                        dispatch(clearAllOrderPositions())
                      }}
                      text={'Выйти из аккаунта'}
                      color={StandardButtonColor.GRAY}
                      iconType={StandardButtonIconType.BLACK_LOGOUT}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                    <StandardButton
                      onClickAction={() => {
                        navigate(RoutePaths.ORDERS_HISTORY)
                        dispatch(hideProfileSheet())
                      }}
                      text={'История заказов'}
                      color={StandardButtonColor.GREEN}
                      iconType={StandardButtonIconType.NO_ICON}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                  </div>
                </>
              ) : (
                <div className={'greetings-sheet-wrapper'}>
                  <div className={'mobile-h2 greetings'}>
                    {`Добро пожаловать${userState?.name?.length > 0 ? `, ${userState?.name}!` : '!'}`}
                  </div>
                  <div style={{ display: "flex", flexDirection: 'column', width: '100%', gap: '8px' }}>
                    <div
                      className={'mobile-main-text gray-sec'}
                      dangerouslySetInnerHTML={{
                        __html: `Мы отправили письмо на адрес ${userState?.email}\u00A0– откройте его и подтвердите почту, чтоб мы знали, что всё в порядке.`
                      }}
                    />
                    <div
                      className={'mobile-main-text gray-sec'}
                      dangerouslySetInnerHTML={{ __html: `Кроме того, для нас важно знать ваши предпочтения. Заполнение профиля займет не больше пары минут.<br>Но можно сделать это и потом.`}}
                    />
                  </div>
                  <div className={'btns-wrapper'}>
                    <StandardButton
                      onClickAction={() => {
                        dispatch(hideProfileSheet())
                      }}
                      text={'Перейти к ресторанам'}
                      color={StandardButtonColor.GRAY}
                      iconType={StandardButtonIconType.NO_ICON}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                    <StandardButton
                      onClickAction={() => {
                        dispatch(hideProfileSheet())
                        navigate(RoutePaths.PROFILE)
                      }}
                      text={'Рассказать о себе'}
                      color={StandardButtonColor.GREEN}
                      iconType={StandardButtonIconType.NO_ICON}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                  </div>
                </div>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop/>
      </Sheet>
    </>
  )
}

export default ProfileButton
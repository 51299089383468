import './RestaurantSection.css'
import React, {useState} from "react";
import FoodCard, {FoodCardLocation} from "../../../shared_components/food_card/FoodCard";
import SmallArrowButton from "./small_arrow_button/SmallArrowButton";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../../constants/enums/RoutePaths";
import {FoodPositionInfo} from "../../../../constants/content_types/FoodInfo";
import OpenRestaurantCard, {OpenRestaurantCardType} from "./open_restaurant_card/OpenRestaurantCard";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import {RestaurantInfoTag} from "../../../../constants/content_types/RestaurantInfoTag";
import TagButton, {TagButtonType} from "../../../shared_components/buttons/tag_button/TagButton";

export interface RestaurantSectionProps {
  id: string;
  name: string;
  restaurantInfoTags: RestaurantInfoTag[];
  positions: FoodPositionInfo[];
}

const RestaurantSection: React.FC<RestaurantSectionProps> = ({ name, positions, id, restaurantInfoTags}) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      className={`restaurant-section-wrapper ${!isMobile && 'desktop'}`}
    >
      <div
        className={`name-and-btn-wrapper ${!isMobile && 'desktop'}`}
        onClick={() => navigate(RoutePaths.RESTAURANT.replace(':id', id))}
        onMouseEnter={() => {
          if (!isTouchable) {
            setHovered(true)
          }
        }}
        onMouseLeave={() => {
          if (!isTouchable) {
            setHovered(false)
            setClicked(false)
          }
        }}
        onTouchStart={() => setClicked(true)}
        onTouchEnd={() => setClicked(false)}
        onTouchCancel={() => setClicked(false)}
        onMouseDown={() => {
          if (!isTouchable) {
            setClicked(true)
          }
        }}
        onMouseUp={() => {
          if (!isTouchable) {
            setClicked(false)
          }
        }}
      >
        <div className={`${isMobile ? 'mobile-h3' : 'desktop-h3'}`}>
          {name}
        </div>
        <SmallArrowButton
          onClickAction={() => navigate(RoutePaths.RESTAURANT.replace(':id', id))}
          isClickedExternal={isClicked}
          isHoveredExternal={isHovered}
        />
        {!isMobile &&
          <div className={'rest-section-tags-wrapper'}>
            {restaurantInfoTags?.map(rt =>
              <TagButton
                key={`${rt}-tag-rest`}
                onClickAction={() => true}
                isActive={false}
                text={rt}
                type={TagButtonType.DESKTOP}
                disabled={true}
                transparent={true}
              />
            )}
          </div>
        }
      </div>
      <div className={`positions-wrapper ${!isMobile && 'desktop'}`}>
        {positions.map(p =>
          <FoodCard
            key={p.id}
            location={FoodCardLocation.HOME_PAGE}
            info={{
              id: p.id,
              image: p.image,
              name: p.name,
              foodVariations: p.foodVariations,
              restaurantId: p.restaurantId,
              menuSectionTags: p.menuSectionTags
            }}
          />
        )}
        <div
          onMouseEnter={() => {
            if (!isTouchable) {
              setHovered(true)
            }
          }}
          onMouseLeave={() => {
            if (!isTouchable) {
              setHovered(false)
              setClicked(false)
            }
          }}
          onTouchStart={() => setClicked(true)}
          onTouchEnd={() => setClicked(false)}
          onTouchCancel={() => setClicked(false)}
          onMouseDown={() => {
            if (!isTouchable) {
              setClicked(true)
            }
          }}
          onMouseUp={() => {
            if (!isTouchable) {
              setClicked(false)
            }
          }}
        >
          <OpenRestaurantCard
            onClickAction={() => navigate(RoutePaths.RESTAURANT.replace(':id', id))}
            type={isMobile ? OpenRestaurantCardType.MOBILE : OpenRestaurantCardType.DESKTOP}
          />
        </div>
      </div>
    </div>
  )
}

export default RestaurantSection
import './Avatar.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/ReduxStore";

export enum AvatarType {
  DESKTOP,
  MOBILE,
  NAV_BAR,
  DESKTOP_PROFILE_BTN
}

interface AvatarProps {
  type: AvatarType;
  onClickAction?: () => void;
}

const Avatar: React.FC<AvatarProps> = ({ onClickAction, type }) => {
  const userState = useSelector((state: RootState) => state.userInfo.userInfo)
  const [aspectRatio, setAspectRatio] = useState(1)

  useEffect(() => {
    const getAspectRatio = (src: string): Promise<number> => {
      return new Promise((resolve) => {
        try {
          const img = new Image();

          img.onload = () => {
            resolve(img.width / img.height);
          };

          img.src = src;
        } catch (e) {
          console.log((e as Error).message)
        }
      });
    }

    getAspectRatio(userState?.image).then(x => setAspectRatio(x))
  }, [userState?.image]);

  return(
    <div
      className={`
        avatar-wrapper 
        ${type === AvatarType.DESKTOP && 'desktop'}
        ${type === AvatarType.NAV_BAR && 'navtiem'} 
        ${type === AvatarType.DESKTOP_PROFILE_BTN && 'desktop-btn'} 
        ${(!userState?.image || userState?.image === '') && 'nopic'}
      `}
      onClick={() => {
        if (onClickAction) {
          onClickAction()
        }
      }}
    >
      {userState?.image ? (
          <div
            className={`selected-img-wrapper 
            ${type === AvatarType.NAV_BAR && 'navitem'} 
            ${type === AvatarType.DESKTOP && 'desktop'}
            ${type === AvatarType.DESKTOP_PROFILE_BTN && 'desktop-btn'} `}
          >
            <img
              src={userState?.image}
              alt={'avatar'}
              className={'selected-img'}
              style={{
                width: aspectRatio < 1 ? '100%' : 'auto',
                height: aspectRatio >= 1 ? '100%' : 'auto',
              }}
            />
          </div>
      ) : (
        <div
          className={`noselect avatar-static-wrapper 
          ${type === AvatarType.NAV_BAR && 'navitem'} 
          ${type === AvatarType.DESKTOP && 'desktop'}
          ${type === AvatarType.DESKTOP_PROFILE_BTN && 'desktop-btn'}`}
        >
          {(userState?.name?.length > 0
              ? userState?.name[0] : userState?.email?.length > 0
                ? userState?.email[0]
                : ''
          )?.toUpperCase()}
        </div>
      )}
    </div>
  )
}
export default Avatar
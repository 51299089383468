import './ProfileModal.css'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/ReduxStore";
import {
  hideProfileSheet,
  ProfileBottomSheetType
} from "../../../redux/profile_bottom_sheet_reducer/ProfileBottomSheetReducer";
import {useNavigate} from "react-router-dom";
import CloseButton, {CloseButtonSize} from "../buttons/close_button/CloseButton";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../buttons/standard_button/StandardButton";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import Avatar, {AvatarType} from "../avatar/Avatar";
import {logoutUser} from "../../../redux/user_info_reducer/UserInfoReducer";
import {clearAllPreferencesTags} from "../../../redux/preferences_reducer/PreferencesReducer";
import {clearAllOrderPositions} from "../../../redux/current_order_reducer/CurrentOrderReducer";

const ProfileModal: React.FC = () => {
  const dispatch = useDispatch()
  const greetingState = useSelector((state: RootState) => state.profileBottomSheet)
  const userState = useSelector((state: RootState) => state.userInfo.userInfo)
  const navigate = useNavigate()

  return(
    <div
      className={`profile-modal-wrapper-desktop ${greetingState.isOpened && 'open'}`}
      onClick={e => {
        if (e.target === e.currentTarget) {
          dispatch(hideProfileSheet())
        }
      }}
    >
      <div
        className={'profile-modal-desktop'}
        style={{ width: Math.min(window.innerWidth - 200, 784), maxHeight: window.innerHeight - 100}}
        onClick={e => e.preventDefault()}
      >
        {greetingState?.type === ProfileBottomSheetType.FIRST ? (
          <>
            <div className={'header-and-close-wrapper'}>
              <div className={'desktop-h2 greetings'}>
                {`Добро пожаловать${userState?.name?.length > 0 ? `, ${userState?.name}!` : '!'}`}
              </div>
              <div style={{ marginLeft: '12px'}}>
                <CloseButton size={CloseButtonSize.BIG} onClickAction={() => dispatch(hideProfileSheet())} defaultColor={'transparent'} />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: 'column', width: '100%', gap: '8px' }}>
              <div
                className={'desktop-main-text text-secondary'}
                dangerouslySetInnerHTML={{
                  __html: `Мы отправили письмо на адрес ${userState?.email}\u00A0– откройте его и подтвердите почту, чтоб мы знали, что всё в порядке.`
                }}
              />
              <div
                className={'desktop-main-text text-secondary'}
                dangerouslySetInnerHTML={{ __html: `Кроме того, для нас важно знать ваши предпочтения. Заполнение профиля займет не больше пары минут. Но можно сделать это и потом.`}}
              />
            </div>
            <div className={'profile-modal-first-btns-wrapper'}>
              <StandardButton
                onClickAction={() => {
                  dispatch(hideProfileSheet())
                }}
                text={'Перейти к ресторанам'}
                color={StandardButtonColor.GRAY}
                iconType={StandardButtonIconType.NO_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
              <StandardButton
                onClickAction={() => {
                  dispatch(hideProfileSheet())
                  navigate(RoutePaths.PROFILE)
                }}
                text={'Рассказать о себе'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.NO_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </>
        ) : (
          <div className={'common-modal-wrapper'}>
            <div className={'header-and-close-wrapper'}>
              <div className={'desktop-h2'}>
                {userState?.name?.length > 0 ? `${userState?.name}` : `${userState?.email}`}
              </div>
              <div style={{ marginLeft: '12px'}}>
                <CloseButton size={CloseButtonSize.BIG} onClickAction={() => dispatch(hideProfileSheet())} defaultColor={'transparent'} />
              </div>
            </div>
            <div className={'avatar-desktop-wrapper'}>
              <Avatar type={AvatarType.DESKTOP} onClickAction={() => {
                dispatch(hideProfileSheet())
                navigate(RoutePaths.PROFILE)
              }}/>
            </div>
            <div className={'btns-wrapper'}>
              <StandardButton
                onClickAction={() => {
                  navigate(RoutePaths.PROFILE)
                  dispatch(hideProfileSheet())
                }}
                text={'Мои данные'}
                color={StandardButtonColor.GRAY}
                iconType={StandardButtonIconType.BLACK_USER_ROUND_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
              <StandardButton
                onClickAction={() => {
                  dispatch(hideProfileSheet())
                  dispatch(logoutUser())
                  dispatch(clearAllPreferencesTags())
                  dispatch(clearAllOrderPositions())
                }}
                text={'Выйти из аккаунта'}
                color={StandardButtonColor.GRAY}
                iconType={StandardButtonIconType.BLACK_LOGOUT}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
              <StandardButton
                onClickAction={() => {
                  navigate(RoutePaths.ORDERS_HISTORY)
                  dispatch(hideProfileSheet())
                }}
                text={'История заказов'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.NO_ICON}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileModal
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

export const validatePassword = (password: string): boolean => /^(?=.*[A-ZА-ЯЁ])(?=.*\d).{8,}$/i.test(password);

export const getPasswordErrorMessage = (password: string): string => {
  if (password.length < 8) {
    return 'Пароль должен содержать не менее 8 символов.';
  }

  if (!/(?=.*[A-ZА-ЯЁ])/i.test(password)) {
    return 'Пароль должен содержать хотя бы одну заглавную букву.';
  }

  if (!/(?=.*\d)/.test(password)) {
    return 'Пароль должен содержать хотя бы одну цифру.';
  }

  return 'ok';
};

import './PreferencesButton.css'
import React, {useEffect, useMemo, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import {DietTag} from "../../../../constants/content_types/DietTag";
import Sheet from "react-modal-sheet";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../../shared_components/buttons/standard_button/StandardButton";
import {
  addAllergyTag,
  addDietTag,
  clearAllPreferencesTags,
  removeAllergyTag,
  removeDietTag
} from "../../../../redux/preferences_reducer/PreferencesReducer";
import CheckBox from "../../../shared_components/buttons/checkbox/CheckBox";
import {AllergyTag} from "../../../../constants/content_types/AllergyTag";
import {useLocation} from "react-router-dom";
import CloseButton, {CloseButtonSize} from "../../../shared_components/buttons/close_button/CloseButton";

const PreferencesButton: React.FC = () => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const [isOpened, setOpened] = useState(false)

  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  const preferences = useSelector((state: RootState) => state.preferences)
  const dispatch = useDispatch()
  const location = useLocation()


  useEffect(() => {
    if (!isOpened) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('pref-btn-all-wrapper');
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('pref-btn-all-wrapper')
    }

    return() => {
      document.body.classList.add('pref-btn-all-wrapper')
      document.body.classList.remove('hidden')
    }
  }, [isOpened]);

  useEffect(() => {
    const handleResize = () => {
      setOpened(false)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setOpened(false)
  }, [location?.pathname]);

  const buttonBody = useMemo(() => {
    return(
      <div
        onClick={() => {
          setOpened(true)
        }}
        className={'animation-02s-all preferences-btn-wrapper'}
        onMouseEnter={() => {
          if (!isTouchable) {
            setHovered(true)
          }
        }}
        onMouseLeave={() => {
          if (!isTouchable) {
            setHovered(false)
            setClicked(false)
          }
        }}
        onTouchStart={() => setClicked(true)}
        onTouchEnd={() => setClicked(false)}
        onTouchCancel={() => setClicked(false)}
        onMouseDown={() => {
          if (!isTouchable) {
            setClicked(true)
          }
        }}
        onMouseUp={() => {
          if (!isTouchable) {
            setClicked(false)
          }
        }}
        style={{
          backgroundColor: isClicked
            ? 'var(--on-click-gray-light, #CFD2DB)'
            : isHovered ? 'var(--on-hover-gray-light, #E7E9F0)' : 'var(--gray-light, #F3F4F6)',
          cursor: isClicked || isHovered ? 'pointer' : undefined,
          flexShrink: 0,
        }}
      >
        <div
          className={'count-circle animation-02s-all mobile-and-desktop-btns white'}
          style={{ opacity: (preferences?.diet?.length > 0 || preferences?.allergy?.length > 0) ? 1 : 0 }}
        >
          {(preferences?.diet?.length || 0) + (preferences?.allergy?.length || 0)}
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="11" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <rect x="14" y="11" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <circle cx="10" cy="12" r="2" fill="#2C2D2E"/>
          <rect x="14" y="17" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <rect x="4" y="17" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <circle cx="14" cy="18" r="2" fill="#2C2D2E"/>
          <rect x="14" y="5" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <rect x="4" y="5" width="6" height="2" rx="1" fill="#2C2D2E"/>
          <circle cx="14" cy="6" r="2" fill="#2C2D2E"/>
        </svg>
      </div>
    )
  }, [isClicked, isHovered, isTouchable, preferences?.allergy?.length, preferences?.diet?.length])

  const renderMobile = () => {
    return(
      <div className={'pref-btn-all-wrapper'}>
        {isOpened && (
          <div
            style={{ position: 'absolute', top: 0, left: 0, width: window.innerWidth * 1000, height: window.innerHeight * 1000, zIndex: 99999 }}
            onClick={(e) => {
              e.preventDefault()
              setOpened(false)
            }}
          />
        )}
        {buttonBody}
        <Sheet
          isOpen={isOpened}
          onClose={() => setOpened(false)}
          detent={"content-height"}
          snapPoints={[window.innerHeight - 64, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content disableDrag={true}>
              <div className={'diet-sheet-wrapper'} id={'diet-sheet-wrapper'}>
                <div className={'top-grad'}/>
                <div className={'mobile-h2 diet-header'}>Диетические предпочтения</div>
                <div className={'diet-tags-wrapper'} style={{ maxHeight: window.innerHeight - 280 }}>
                  {
                    Object.keys(DietTag).map(key => {
                      const val =  DietTag[key as keyof typeof DietTag];
                      return(
                        <CheckBox
                          key={`${val}-checkbox`}
                          onCheckAction={() => dispatch(addDietTag(val))}
                          onUncheckAction={() => dispatch(removeDietTag(val))}
                          checked={preferences?.diet?.includes(val)}
                          text={val}
                        />
                      )
                    })
                  }
                  <div className={'mobile-subtext gray-secondary'}>У меня аллергия на:</div>
                  {
                    Object.keys(AllergyTag).map(key => {
                      const val =  AllergyTag[key as keyof typeof AllergyTag];
                      return(
                        <CheckBox
                          key={`${val}-checkbox`}
                          onCheckAction={() => dispatch(addAllergyTag(val))}
                          onUncheckAction={() => dispatch(removeAllergyTag(val))}
                          checked={preferences?.allergy?.includes(val)}
                          text={val}
                        />
                      )
                    })
                  }
                </div>
                <div className={'btns-wrapper'}>
                  <div className={'bottom-grad'}/>
                  <div
                    className={'animation-02s-all'}
                    style={{
                      width: '100%',
                      display: 'flex',
                      opacity: (preferences?.diet?.length > 0 || preferences?.allergy?.length > 0) ? 1 : 0 ,
                      overflow: "hidden",
                      maxHeight: (preferences?.diet?.length > 0 || preferences?.allergy?.length > 0) ? '60px' : 0,
                      paddingTop: (preferences?.diet?.length > 0 || preferences?.allergy?.length > 0) ? '4px' : 0
                    }}
                  >
                    <StandardButton
                      onClickAction={() => {
                        dispatch(clearAllPreferencesTags())
                        setOpened(false)
                      }}
                      text={'Сбросить фильтрацию'}
                      color={StandardButtonColor.GRAY}
                      iconType={StandardButtonIconType.DARK_CROSS}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                  </div>
                  <StandardButton
                    onClickAction={() => {
                      setOpened(false)
                    }}
                    text={'Показать подходящее'}
                    color={StandardButtonColor.GREEN}
                    iconType={StandardButtonIconType.WHITE_RIGHT_ARROW}
                    iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                  />
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop/>
        </Sheet>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        {buttonBody}
        <div
          className={`pref-btn-desktop-absolute-wrapper ${isOpened && 'open'}`}
          onClick={e => {
            if (e.target === e.currentTarget) {
              setOpened(false)
            }
          }}
        >
          <div
            className={'pref-popup'}
            style={{ width: isOpened ? Math.min(Math.max(600, window.innerWidth - 350), 768) : 0 }}
          >
            <div className={'header-and-close-wrapper'}>
              <div className={'header desktop-h2'}>
                Диетические предпочтения
              </div>
              <div style={{ marginLeft: '12px'}}>
                <CloseButton
                  size={CloseButtonSize.BIG}
                  onClickAction={() => setOpened(false)}
                  defaultColor={'transparent'}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: "column", width: '100%', gap: '8px'}}>
              {
                Object.keys(DietTag).map(key => {
                  const val =  DietTag[key as keyof typeof DietTag];
                  return(
                    <CheckBox
                      key={`${val}-checkbox`}
                      onCheckAction={() => dispatch(addDietTag(val))}
                      onUncheckAction={() => dispatch(removeDietTag(val))}
                      checked={preferences?.diet?.includes(val)}
                      text={val}
                    />
                  )
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: "column", width: '100%', gap: '8px'}}>
              <div className={'desktop-subtext text-secondary'}>У меня аллергия на:</div>
              {
                Object.keys(AllergyTag).map(key => {
                  const val =  AllergyTag[key as keyof typeof AllergyTag];
                  return(
                    <CheckBox
                      key={`${val}-checkbox`}
                      onCheckAction={() => dispatch(addAllergyTag(val))}
                      onUncheckAction={() => dispatch(removeAllergyTag(val))}
                      checked={preferences?.allergy?.includes(val)}
                      text={val}
                    />
                  )
                })
              }
            </div>
            <div style={{ display: 'flex', flexDirection: "row", width: '100%', justifyContent: 'flex-end', gap: '8px'}}>
              <div className={'animation-01s-all'} style={{ overflow: "hidden", maxWidth: (preferences?.diet?.length > 0 || preferences?.allergy?.length > 0) ? '300px' : 0}}>
                <StandardButton
                  onClickAction={() => {
                    dispatch(clearAllPreferencesTags())
                    setOpened(false)
                  }}
                  text={'Сбросить фильтрацию'}
                  color={StandardButtonColor.GRAY}
                  iconType={StandardButtonIconType.DARK_CROSS}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
              </div>
              <div>
                <StandardButton
                  onClickAction={() => {
                    setOpened(false)
                  }}
                  text={'Найти подходящее'}
                  color={StandardButtonColor.GREEN}
                  iconType={StandardButtonIconType.WHITE_RIGHT_ARROW}
                  iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default PreferencesButton
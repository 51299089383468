import {Checkbox} from "@mui/material";
import './CheckBox.css'
import React from "react";

interface CheckBoxProps {
  onCheckAction: () => void;
  onUncheckAction: () => void;
  checked: boolean;
  text: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onCheckAction, onUncheckAction, text }) => {
  return(
    <div
      className={'select-item-wrapper mobile-and-desktop-selectors'}
      onClick={() => {
        if (checked) {
          onUncheckAction()
        } else {
          onCheckAction()
        }
      }}
    >
      <Checkbox
        disabled={true}
        style={{ padding: 0, margin: 0, borderRadius: '6px'}}
        checked={checked}
        size={'medium'} icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="6" fill="#F3F4F6"/>
        </svg>
      }
        checkedIcon={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="6" fill="#00DF5F"/>
            <path d="M6.25 12.5L9.75 16L17.75 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
        sx={{
          bgcolor: '#F3F4F6',
          background: '#F3F4F6',
          borderRadius: 6,
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
          color: '#F3F4F6',
          '&.Mui-checked': {
            color: 'var(--main-ym-green, #00DF5F)',
            background: 'var(--main-ym-green, #00DF5F)',
          },
          "& .MuiSvgIcon-root": {
            fontSize: 24,
            borderRadius: 20
          },
        }}
      />
      {text}
    </div>
  )
}

export default CheckBox
import './MyOrderNavButton.css'
import React, {useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import {RoutePaths} from "../../../../constants/enums/RoutePaths";
import useRipple from "use-ripple-hook";
import {getTotalProductCountInOrder} from "../../../../redux/current_order_reducer/OrderOperations";
import NumberTag, {NumberTagColor} from "../../buttons/number_tag/NumberTag";

const MyOrderNavButton: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const orderState = useSelector((state: RootState) => state.order.orderState)

  const countInOrder = useMemo(() => {
    return getTotalProductCountInOrder(orderState)
  }, [orderState])

  const [ripple, event] = useRipple({
    duration: 800,
    color: "rgba(145,145,145,0.09)",
    cancelAutomatically: true,
  });

  return(
    <div
      ref={ripple}
      onPointerDown={event}
      className={'nav-bar-order-btn-wrapper'}
      onClick={() => navigate(RoutePaths.MY_ORDER)}
    >
      <div className={'nav-bar-order-btn-icons-wrapper'}>
        {location?.pathname === RoutePaths?.MY_ORDER ? (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_46_3197)">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.01281 4.19824C6.00433 4.29764 6 4.39823 6 4.49983C6 6.43282 7.567 7.99983 9.5 7.99983H14.5C16.433 7.99983 18 6.43282 18 4.49983C18 4.39823 17.9957 4.29764 17.9872 4.19824C19.0665 4.88992 19.9061 5.91617 20.3654 7.13658C20.75 8.15835 20.75 9.43892 20.75 12.0001V14.0001C20.75 16.5612 20.75 17.8418 20.3654 18.8636C19.7568 20.4807 18.4806 21.7569 16.8635 22.3655C15.8417 22.7501 14.5612 22.7501 12 22.7501C9.43884 22.7501 8.15826 22.7501 7.1365 22.3655C5.51941 21.7569 4.24319 20.4807 3.63456 18.8636C3.25 17.8418 3.25 16.5612 3.25 14.0001V12.0001C3.25 9.43892 3.25 8.15835 3.63456 7.13658C4.09389 5.91617 4.93345 4.88992 6.01281 4.19824ZM13 12C13 11.5858 13.3358 11.25 13.75 11.25H16.75C17.1642 11.25 17.5 11.5858 17.5 12C17.5 12.4142 17.1642 12.75 16.75 12.75H13.75C13.3358 12.75 13 12.4142 13 12ZM13.75 16.25C13.3358 16.25 13 16.5858 13 17C13 17.4142 13.3358 17.75 13.75 17.75H16.75C17.1642 17.75 17.5 17.4142 17.5 17C17.5 16.5858 17.1642 16.25 16.75 16.25H13.75ZM11.2803 10.9697C11.5732 11.2626 11.5732 11.7374 11.2803 12.0303L10.1642 13.1464C9.38317 13.9275 8.11683 13.9275 7.33579 13.1464L6.71967 12.5303C6.42678 12.2374 6.42678 11.7626 6.71967 11.4697C7.01256 11.1768 7.48744 11.1768 7.78033 11.4697L8.39645 12.0858C8.59171 12.281 8.90829 12.281 9.10355 12.0858L10.2197 10.9697C10.5126 10.6768 10.9874 10.6768 11.2803 10.9697ZM11.2803 17.0303C11.5732 16.7374 11.5732 16.2626 11.2803 15.9697C10.9874 15.6768 10.5126 15.6768 10.2197 15.9697L9.10355 17.0858C8.90829 17.281 8.59171 17.281 8.39645 17.0858L7.78033 16.4697C7.48744 16.1768 7.01256 16.1768 6.71967 16.4697C6.42678 16.7626 6.42678 17.2374 6.71967 17.5303L7.33579 18.1464C8.11683 18.9275 9.38317 18.9275 10.1642 18.1464L11.2803 17.0303Z" fill="#00DF5F"/>
              <path d="M7.25 4C7.25 2.48122 8.48122 1.25 10 1.25H14C15.5188 1.25 16.75 2.48122 16.75 4C16.75 5.51878 15.5188 6.75 14 6.75H10C8.48122 6.75 7.25 5.51878 7.25 4Z" fill="#00DF5F"/>
            </g>
            <defs>
              <clipPath id="clip0_46_3197">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_39_2606)">
              <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M6.01281 4.19824C6.00433 4.29764 6 4.39823 6 4.49983C6 6.43282 7.567 7.99983 9.5 7.99983H14.5C16.433 7.99983 18 6.43282 18 4.49983C18 4.39823 17.9957 4.29764 17.9872 4.19824C19.0665 4.88992 19.9061 5.91617 20.3654 7.13658C20.75 8.15835 20.75 9.43892 20.75 12.0001V14.0001C20.75 16.5612 20.75 17.8418 20.3654 18.8636C19.7568 20.4807 18.4806 21.7569 16.8635 22.3655C15.8417 22.7501 14.5612 22.7501 12 22.7501C9.43884 22.7501 8.15826 22.7501 7.1365 22.3655C5.51941 21.7569 4.24319 20.4807 3.63456 18.8636C3.25 17.8418 3.25 16.5612 3.25 14.0001V12.0001C3.25 9.43892 3.25 8.15835 3.63456 7.13658C4.09389 5.91617 4.93345 4.88992 6.01281 4.19824Z" fill="#C6C9D1"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M11.2803 10.9697C11.5732 11.2626 11.5732 11.7374 11.2803 12.0303L10.1642 13.1464C9.38317 13.9275 8.11683 13.9275 7.33579 13.1464L6.71967 12.5303C6.42678 12.2374 6.42678 11.7626 6.71967 11.4697C7.01256 11.1768 7.48744 11.1768 7.78033 11.4697L8.39645 12.0858C8.59171 12.281 8.90829 12.281 9.10355 12.0858L10.2197 10.9697C10.5126 10.6768 10.9874 10.6768 11.2803 10.9697Z" fill="#C6C9D1"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M11.2803 15.9697C11.5732 16.2626 11.5732 16.7374 11.2803 17.0303L10.1642 18.1464C9.38317 18.9275 8.11683 18.9275 7.33579 18.1464L6.71967 17.5303C6.42678 17.2374 6.42678 16.7626 6.71967 16.4697C7.01256 16.1768 7.48744 16.1768 7.78033 16.4697L8.39645 17.0858C8.59171 17.281 8.90829 17.281 9.10355 17.0858L10.2197 15.9697C10.5126 15.6768 10.9874 15.6768 11.2803 15.9697Z" fill="#C6C9D1"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M13 12C13 11.5858 13.3358 11.25 13.75 11.25L16.75 11.25C17.1642 11.25 17.5 11.5858 17.5 12C17.5 12.4142 17.1642 12.75 16.75 12.75H13.75C13.3358 12.75 13 12.4142 13 12Z" fill="#C6C9D1"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M13 17C13 16.5858 13.3358 16.25 13.75 16.25H16.75C17.1642 16.25 17.5 16.5858 17.5 17C17.5 17.4142 17.1642 17.75 16.75 17.75H13.75C13.3358 17.75 13 17.4142 13 17Z" fill="#C6C9D1"/>
              <path d="M7.25 4C7.25 2.48122 8.48122 1.25 10 1.25H14C15.5188 1.25 16.75 2.48122 16.75 4C16.75 5.51878 15.5188 6.75 14 6.75H10C8.48122 6.75 7.25 5.51878 7.25 4Z" fill="#C6C9D1"/>
            </g>
            <defs>
              <clipPath id="clip0_39_2606">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        )}
        {countInOrder > 0 && (
          <NumberTag
            color={NumberTagColor.GREEN}
            number={countInOrder}
            disableAnimation={true}
          />
        )}
      </div>
      <div className={`mobile-subtext ${location?.pathname === RoutePaths?.MY_ORDER && 'green'} noselect`}>
        Мой заказ
      </div>
    </div>
  )
}

export default MyOrderNavButton
import React, {useState} from "react";
import {useMediaQuery} from "react-responsive";
import './../../../../../assets/styles/animation_durations.css'
import {MediaQueries} from "../../../../../constants/enums/MediaQueries";

interface SmallArrowButtonProps {
  onClickAction: () => void;
  isHoveredExternal?: boolean;
  isClickedExternal?: boolean;
}

const SmallArrowButton: React.FC<SmallArrowButtonProps> = ({ onClickAction, isClickedExternal, isHoveredExternal }) => {
  const [isHovered, setHovered] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const isTouchable = useMediaQuery({ query: MediaQueries.TOUCHABLE });

  return(
    <div
      className={'animation-02s-all'}
      onClick={onClickAction}
      onMouseEnter={() => {
        if (!isTouchable) {
          setHovered(true)
        }
      }}
      onMouseLeave={() => {
        if (!isTouchable) {
          setHovered(false)
          setClicked(false)
        }
      }}
      onTouchStart={() => setClicked(true)}
      onTouchEnd={() => setClicked(false)}
      onTouchCancel={() => setClicked(false)}
      onMouseDown={() => {
        if (!isTouchable) {
          setClicked(true)
        }
      }}
      onMouseUp={() => {
        if (!isTouchable) {
          setClicked(false)
        }
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing:'border-box',
        width: '24px',
        height: '24px',
        borderRadius: '12px',
        backgroundColor: (isClicked || isClickedExternal)
          ? 'var(--on-click-ym-green, #00B54D)'
          : (isHovered || isHoveredExternal) ? 'var(--main-ym-green, #00DF5F)' : 'transparent',
        cursor: isClicked || isHovered || isHoveredExternal ? 'pointer' : undefined
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={'animation-01s-all'}
          d="M2.5 9.99999H16.25M16.25 9.99999L10.4167 4.16666M16.25 9.99999L10.4167 15.8333" stroke={isClicked || isHovered || isClickedExternal || isHoveredExternal ? 'white' : '#C6C9D1'}
          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default SmallArrowButton
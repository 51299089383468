import './OrderHistory.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/ReduxStore";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import Loader from "../../shared_components/loader/Loader";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";
import Footer from "../../shared_components/footer/Footer";
import OrderHistoryItem, {CompletedOrderState} from "./order_history_item/OrderHistoryItem";
import {OrderStatus} from "../../../constants/content_types/OrderStatus";
import DetailedOrderHistoryView from "./detailed_order_history_view/DetailedOrderHistoryView";
import {VariationType} from "../../../constants/content_types/FoodInfo";
import {DietTag} from "../../../constants/content_types/DietTag";

const OrderHistoryPage: React.FC = () => {
  const navigate = useNavigate()
  const userState = useSelector((state: RootState) => state.userInfo.userInfo)
  const [isLoading, setLoading] = useState(true)
  const [orders, setOrders] = useState<CompletedOrderState[] | undefined>([])
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  useEffect(() => {
    setTimeout(() => {
      // TODO - replace with actual backend request
      setOrders([
        {
          orderPositions: [
            {
              position: {
                selectedVariationId: '1',
                id: '2',
                restaurantId: '1',
                name: 'Авторский салат с авокадо и гранатом',
                image: 'https://i.imgur.com/RWer6Wh.jpg',
                isOnStartPage: false,
                menuSectionTags: [],
                foodVariations: [
                  {
                    variationId: '1',
                    variationName: 'Стандартный',
                    variationType: VariationType.STANDARD,
                    price: 600,
                    weight: 150,
                    ingredients: 'салатный лист, авокадо, гранат, гренки, петрушка, лук, капуста',
                    CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
                    proteins: 666,
                    fats: 777,
                    carbohydrates: 888,
                    calories: 999,
                    allowedDietsTags: [DietTag.VEGAN, DietTag.VEGETARIAN],
                    restrictedAllergyTags: [],
                  },
                ],
              },
              count: 2
            },
            {
              position: {
                selectedVariationId: '2',
                id: '3',
                restaurantId: '1',
                name: 'Тыквенный суп-пюре',
                image: 'https://i.imgur.com/nLjUw2s.jpg',
                isOnStartPage: false,
                menuSectionTags: [],
                foodVariations: [
                  {
                    variationId: '1',
                    variationName: 'Стандартный',
                    variationType: VariationType.STANDARD,
                    price: 444,
                    weight: 150,
                    ingredients: 'суп',
                    CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
                    proteins: 666,
                    fats: 777,
                    carbohydrates: 888,
                    calories: 999,
                    allowedDietsTags: [DietTag.SPORT, DietTag.HEALTHY_STOMACH],
                    restrictedAllergyTags: [],
                  },
                  {
                    variationId: '2',
                    variationName: 'С семечками',
                    variationType: VariationType.CUSTOM,
                    price: 586,
                    weight: 170,
                    ingredients: 'суп, семечки',
                    CFCB: 'КБЖУ на 100 гр: 339 ккал, 5 гр., 16 гр., 40 гр.<br>КБЖУ на 150 гр: 508,5 ккал, 7,5 гр., 24 гр., 60 гр.;<br>примерно 5 ХЕ',
                    proteins: 222,
                    fats: 333,
                    carbohydrates: 444,
                    calories: 555,
                    allowedDietsTags: [DietTag.DIABETIC, DietTag.LESS_PROTEIN],
                    restrictedAllergyTags: [],
                  }
                ],
              },
              count: 3
            }
          ],
          orderId: '1',
          restaurantId: '1',
          restaurantAddress: 'Дмитровское ш. д 68, пом. 1, Москва',
          orderStatus: OrderStatus.COMPLETED,
          timestamp: new Date().getTime().valueOf(),
          restaurantName: 'Futur Bar'
        }
      ])
      setLoading(false)
    }, 1000)
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 })
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, []);

  const renderMobile = () => {
    return(
      <>
        <div className={'order-history-page-wrapper'} style={{ alignItems: (isLoading || !orders) ? 'center' : 'flex-start'}}>
          {(isLoading || !orders) ? (
            <Loader/>
          ) : (
            <>
              {(orders && orders?.length > 0) ? (
                <div
                  className={'history-page-wrapper'}
                  style={{ maxHeight: window.innerHeight - 60, marginBottom: '80px'}}
                >
                  <div className={'mobile-h2 text-primary'} style={{ marginLeft: '8px', marginBottom: '12px'}}>
                    История заказов
                  </div>
                  {orders && (orders.map(o =>
                    <OrderHistoryItem
                      orderId={o.orderId}
                      restaurantId={o.restaurantId}
                      orderStatus={o.orderStatus}
                      timestamp={o.timestamp}
                      restaurantAddress={o.restaurantAddress}
                      orderPositions={o.orderPositions}
                      restaurantName={o.restaurantName}
                      key={o.orderId}
                    />
                  ))}
                </div>
              ) : (
                <div className={'order-history-page-wrapper'} style={{ height: window.innerHeight }}>
                  <div className={'main-hist-wrapper'}>
                    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_300_11586)">
                        <path d="M27.3333 32.4585C39.5987 32.4585 49.5417 42.4015 49.5417 54.6668C49.5417 66.9322 39.5987 76.8752 27.3333 76.8752C15.068 76.8752 5.125 66.9322 5.125 54.6668C5.125 42.4015 15.068 32.4585 27.3333 32.4585Z" fill="#F3F4F6"/>
                        <path d="M45.0728 74.7017C47.9767 76.0948 51.2305 76.8752 54.6666 76.8752C66.9319 76.8752 76.8749 66.9322 76.8749 54.6668C76.8749 42.4015 66.9319 32.4585 54.6666 32.4585C51.2305 32.4585 47.9767 33.2388 45.0728 34.6319C50.4487 39.6234 53.8125 46.7523 53.8125 54.6668C53.8125 62.5814 50.4487 69.7102 45.0728 74.7017Z" fill="#F3F4F6"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M50.3699 6.47094C50.57 7.87195 49.5965 9.16994 48.1955 9.37008C36.3877 11.0569 28.183 21.9965 29.8699 33.8043C30.07 35.2053 29.0965 36.5033 27.6955 36.7034C26.2945 36.9036 24.9965 35.9301 24.7964 34.5291C22.7093 19.9192 32.8609 6.38371 47.4707 4.29659C48.8717 4.09644 50.1697 5.06994 50.3699 6.47094Z" fill="#C6C9D1"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M48.979 4.5416C50.2448 5.17451 50.7579 6.71373 50.125 7.97955C46.5374 15.1546 47.9437 23.8203 53.6161 29.4927L56.4783 32.3549C57.479 33.3557 57.479 34.9781 56.4783 35.9789C55.4776 36.9796 53.8551 36.9796 52.8544 35.9789L49.9922 33.1166C42.7599 25.8844 40.967 14.8357 45.541 5.68758C46.1739 4.42176 47.7132 3.90869 48.979 4.5416Z" fill="#C6C9D1"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_300_11586">
                          <rect width="82" height="82" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <div className={'hist-text-wrapper'}>
                      <div className={'mobile-h3 gray'}>
                        История заказов пуста
                      </div>
                      <div
                        className={'mobile-main-text gray-sec'}
                        dangerouslySetInnerHTML={{ __html: 'Отличный повод ее пополнить!'}}
                      />
                    </div>
                  </div>
                  <div style={{ bottom: 0, position: "fixed", width: window.innerWidth, padding: '16px', boxSizing: 'border-box'}}>
                    <StandardButton
                      onClickAction={() => navigate(RoutePaths.HOME)}
                      text={'Вернуться к ресторанам'}
                      color={StandardButtonColor.GREEN}
                      iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                      iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                    />
                    <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                      <div className={'bottom-grad'}/>

                    </div>
                  </div>
                </div>
              )}
              <div className={'return-btn-wrapper'}>
                <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                  <div className={'bottom-grad'}/>
                  <StandardButton
                    onClickAction={() => navigate(RoutePaths.HOME)}
                    text={'Вернуться к ресторанам'}
                    color={StandardButtonColor.GREEN}
                    iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                    iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <DetailedOrderHistoryView/>
      </>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <HeaderDesktop withProfileButton={true}/>
          <div
            className={'order-history-wrapper-desktop'}
            style={{
              height: (isLoading || !orders || orders?.length === 0) ? window.innerHeight - 410 : window.innerHeight - 80,
              maxHeight: (isLoading || !orders || orders?.length === 0) ? window.innerHeight - 410 : window.innerHeight - 80,
              backgroundColor: (isLoading || !orders || orders?.length === 0) ? 'white' : 'var(--light-colors-light)'
            }}
          >
            {(isLoading || !orders) ? (
              <Loader/>
            ) : (
              <>
                {(orders && orders?.length > 0) ? (
                  <div
                    className={'order-history-wrapper-desktop-popup'}
                    style={{
                      width: Math.min(784, window.innerWidth / 1.4),
                      maxHeight: window.innerHeight - 200
                    }}
                  >
                    <div className={'top-grad'}/>
                    <div className={'bottom-grad'}/>
                    <div className={'desktop-h2 text-primary'} style={{ marginLeft: '16px' }}>
                      История заказов
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', gap: '8px', width: '100%', boxSizing: "border-box", paddingRight: '12px', paddingTop: '12px', paddingBottom: '16px' }}>
                      {orders && (orders.map(o =>
                        <OrderHistoryItem
                          orderId={o.orderId}
                          restaurantId={o.restaurantId}
                          orderStatus={o.orderStatus}
                          timestamp={o.timestamp}
                          restaurantAddress={o.restaurantAddress}
                          orderPositions={o.orderPositions}
                          restaurantName={o.restaurantName}
                          key={o.orderId}
                        />
                      ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%', boxSizing: 'border-box', marginTop: '8px' }}>
                      <div style={{ width: '300px', marginRight: '16px' }}>
                        <StandardButton
                          onClickAction={() => navigate(RoutePaths.HOME)}
                          text={'Вернуться к ресторанам'}
                          color={StandardButtonColor.GREEN}
                          iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={'history-info-desktop'}>
                    <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M27.3333 32.458C39.5987 32.458 49.5417 42.401 49.5417 54.6663C49.5417 66.9317 39.5987 76.8747 27.3333 76.8747C15.068 76.8747 5.125 66.9317 5.125 54.6663C5.125 42.401 15.068 32.458 27.3333 32.458Z" fill="#F3F4F6"/>
                      <path d="M45.0732 74.7012C47.9772 76.0943 51.231 76.8747 54.6671 76.8747C66.9324 76.8747 76.8754 66.9317 76.8754 54.6663C76.8754 42.401 66.9324 32.458 54.6671 32.458C51.231 32.458 47.9772 33.2383 45.0732 34.6315C50.4492 39.623 53.813 46.7518 53.813 54.6663C53.813 62.5809 50.4492 69.7097 45.0732 74.7012Z" fill="#F3F4F6"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M50.3699 6.47094C50.57 7.87195 49.5965 9.16994 48.1955 9.37008C36.3877 11.0569 28.183 21.9965 29.8699 33.8043C30.07 35.2053 29.0965 36.5033 27.6955 36.7034C26.2945 36.9036 24.9965 35.9301 24.7964 34.5291C22.7093 19.9192 32.8609 6.38371 47.4707 4.29659C48.8717 4.09644 50.1697 5.06994 50.3699 6.47094Z" fill="#C6C9D1"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M48.9795 4.5416C50.2453 5.17451 50.7584 6.71373 50.1255 7.97955C46.5379 15.1546 47.9442 23.8203 53.6166 29.4927L56.4788 32.3549C57.4795 33.3557 57.4795 34.9781 56.4788 35.9789C55.4781 36.9796 53.8556 36.9796 52.8549 35.9789L49.9926 33.1166C42.7604 25.8844 40.9674 14.8357 45.5415 5.68758C46.1744 4.42176 47.7136 3.90869 48.9795 4.5416Z" fill="#C6C9D1"/>
                    </svg>
                    <div className={'info-wrpr'}>
                      <div className={'desktop-h3 text-primary'}>
                        История заказов пуста
                      </div>
                      <div className={'desktop-main-text text-secondary'}>
                        Отличный повод ее пополнить!
                      </div>
                    </div>
                    <div style={{ marginTop: '32px'}}>
                      <StandardButton
                        onClickAction={() => navigate(RoutePaths.HOME)}
                        text={'Вернуться к ресторанам'}
                        color={StandardButtonColor.GREEN}
                        iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                        iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {(isLoading || !orders || orders?.length === 0) && <Footer/>}
        <DetailedOrderHistoryView/>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default OrderHistoryPage
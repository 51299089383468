import './DesktopProfileButton.css'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/ReduxStore";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../standard_button/StandardButton";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../../constants/enums/RoutePaths";
import {displayCommonProfileSheet} from "../../../../redux/profile_bottom_sheet_reducer/ProfileBottomSheetReducer";

const DesktopProfileButton: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return(
    <>
      <StandardButton
        onClickAction={() => {
          if (userInfo.bearerToken !== '') {
            dispatch(displayCommonProfileSheet())
          } else {
            navigate(RoutePaths.LOGIN)
          }
        }}
        text={!userInfo?.bearerToken || (!userInfo?.name && !userInfo?.email) ? 'Войти' : userInfo.name ? userInfo.name : userInfo.email}
        color={StandardButtonColor.GRAY}
        iconType={!userInfo?.bearerToken || (!userInfo?.name && !userInfo?.email) ? StandardButtonIconType.NO_ICON : StandardButtonIconType.AVATAR}
        iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
        notFullWidth={true}
      />
    </>
  )
}

export default DesktopProfileButton
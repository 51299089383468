import './LastProductWarning.css'
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Sheet from "react-modal-sheet";
import {RootState} from "../../../../redux/ReduxStore";
import {hideLastProductWarning} from "../../../../redux/last_product_warning/LastProductWarningReducer";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../../shared_components/buttons/standard_button/StandardButton";
import {removeOrderPosition} from "../../../../redux/current_order_reducer/CurrentOrderReducer";
import {FoodVariation, VariationType} from "../../../../constants/content_types/FoodInfo";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import CloseButton, {CloseButtonSize} from "../../../shared_components/buttons/close_button/CloseButton";

const LastProductWarning: React.FC = () => {
  const dispatch = useDispatch();
  const warningState = useSelector((state: RootState) => state.lastProductWarning)
  const [resizeTimeouts, setResizeTimeouts] = useState<NodeJS.Timeout[]>([])
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})

  useEffect(() => {
    const handleResize = () => {
      dispatch(hideLastProductWarning())
    }

    if (!warningState.isShown) {
      document.body.classList.remove('hidden')
      document.body.classList.remove('warning-all-wrapper');
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    } else {
      document.body.classList.add('hidden')
      document.body.classList.add('warning-all-wrapper')
      setResizeTimeouts([...resizeTimeouts, setTimeout(() => {
        if (warningState.isShown) {
          window.addEventListener('resize', handleResize)
        }
      }, 1000)])
    }

    return() => {
      document.body.classList.remove('warning-all-wrapper')
      document.body.classList.remove('hidden')
      window.removeEventListener('resize', handleResize);
      resizeTimeouts.forEach(clearTimeout)
    }
  }, [warningState.isShown]);

  const resolvedFoodName = useMemo(() => {
    try {
      const selectedVariation = warningState?.position?.foodVariations
        ?.filter(v => v?.variationId === warningState?.position?.selectedVariationId)[0] as FoodVariation

      if (selectedVariation.variationType !== VariationType.STANDARD) {
        return `${warningState?.position?.name} (${selectedVariation?.variationName?.toString().toLowerCase()})`
      }
      return warningState?.position?.name
    } catch (e) {
      return ''
    }
  }, [warningState])

  const renderMobile = () => {
    return(
      <div className={'warning-all-wrapper'}>
        {warningState.isShown && (
          <div
            style={{ position: 'fixed', top: 0, left: 0, width: window.innerWidth, height: window.innerHeight, zIndex: 999 }}
            onClick={(e) => {
              e.preventDefault()
              dispatch(hideLastProductWarning())
            }}
          />
        )}
        <Sheet
          isOpen={warningState.isShown}
          onClose={() => dispatch(hideLastProductWarning())}
          detent={"content-height"}
          snapPoints={[500, 0]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content disableDrag={true} >
              <div className={'warning-sheet-wrapper'}>
                <div className={'mobile-h2 text-primary'}>
                  Убрать {resolvedFoodName}?
                </div>
                <div
                  className={'mobile-main-text text-secondary'}
                  dangerouslySetInnerHTML={{ __html: 'Если вы захотите вернуть порцию в&nbsp;заказ, то&nbsp;придется заново найти это&nbsp;блюдо в&nbsp;меню'}}
                />
                <div className={'btns-wrapper'}>
                  <StandardButton
                    onClickAction={() => {
                      if (warningState?.position) {
                        dispatch(hideLastProductWarning())
                        dispatch(removeOrderPosition(warningState.position))
                      }
                    }}
                    text={'Да, убрать'}
                    color={StandardButtonColor.GRAY}
                    iconType={StandardButtonIconType.NO_ICON}
                    iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                  />
                  <StandardButton
                    onClickAction={() => {
                      if (warningState?.position) {
                        dispatch(hideLastProductWarning())
                      }
                    }}
                    text={'Пока оставим'}
                    color={StandardButtonColor.GREEN}
                    iconType={StandardButtonIconType.NO_ICON}
                    iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                  />
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop/>
        </Sheet>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <div
        className={`product-warning-absolute-wrapper-desktop ${warningState.isShown && 'open'}`}
        onClick={e => {
          if (e.target === e.currentTarget) {
            dispatch(hideLastProductWarning())
          }
        }}
      >
        <div
          className={'product-warning-wrapper-desktop'}
          style={{ width: Math.min(window.innerWidth - 350, 768), maxHeight: window.innerHeight - 300 }}
          onClick={e => e.preventDefault()}
        >
          <div className={'header-and-close-wrapper'}>
            <div className={'desktop-h2'}>
              Убрать {resolvedFoodName}?
            </div>
            <div style={{ marginLeft: '12px'}}>
              <CloseButton
                size={CloseButtonSize.BIG}
                onClickAction={() => dispatch(hideLastProductWarning())}
                defaultColor={'transparent'}
              />
            </div>
          </div>
          <div
            className={'desktop-main-text text-secondary'}
            dangerouslySetInnerHTML={{ __html: 'Если вы захотите вернуть порцию в&nbsp;заказ, то&nbsp;придется заново найти это&nbsp;блюдо в&nbsp;меню'}}
          />
          <div className={'btns-wrapper-desktop'}>
            <StandardButton
              onClickAction={() => {
                if (warningState?.position) {
                  dispatch(hideLastProductWarning())
                  dispatch(removeOrderPosition(warningState.position))
                }
              }}
              text={'Да, убрать'}
              color={StandardButtonColor.GRAY}
              iconType={StandardButtonIconType.NO_ICON}
              iconPosition={StandardButtonIconPosition.AFTER_TEXT}
            />
            <StandardButton
              onClickAction={() => {
                if (warningState?.position) {
                  dispatch(hideLastProductWarning())
                }
              }}
              text={'Пока оставим'}
              color={StandardButtonColor.GREEN}
              iconType={StandardButtonIconType.NO_ICON}
              iconPosition={StandardButtonIconPosition.AFTER_TEXT}
            />
          </div>
        </div>
      </div>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default LastProductWarning
import './RestaurantInfoCard.css'
import React, {useMemo} from "react";
import {MediumCheck} from "../../../../constants/content_types/RestaurantInfo";
import RestaurantInfoButton from "../restaurant_info_button/RestaurantInfoButton";
import {RestaurantInfoTag} from "../../../../constants/content_types/RestaurantInfoTag";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";
import TagButton, {TagButtonType} from "../../../shared_components/buttons/tag_button/TagButton";

interface RestaurantInfoCardProps {
  name: string;
  image: string;
  address: string;
  description: string;
  openingTime: Date;
  closingTime: Date;
  mediumCheck: MediumCheck;
  infoTags: RestaurantInfoTag[]
  rate: number;
}

const RestaurantInfoCard: React.FC<RestaurantInfoCardProps> = ({ address, description, name, openingTime, closingTime, rate, mediumCheck, image, infoTags}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })
  const isTablet = useMediaQuery({ query: MediaQueries.TABLET })

  const formattedRate = useMemo(() => rate?.toFixed(1), [rate])
  const formattedTime = useMemo(() => {
    let oh = Math.floor(openingTime?.getHours()).toString()
    let om = Math.floor(openingTime.getMinutes()).toString()
    let ch = Math.floor(closingTime?.getHours()).toString()
    let cm = Math.floor(closingTime.getMinutes()).toString()

    if (oh.length < 2) {
      oh = '0' + oh
    }
    if (ch.length < 2) {
      ch = '0' + ch
    }
    if (om.length < 2) {
      om = '0' + om
    }
    if (cm.length < 2) {
      cm = '0' + cm
    }

    return `${oh}:${om} -<br/>${ch}:${cm}`
  }, [openingTime, closingTime])

  const renderMobile = () => {
    return(
      <div className={'restaurant-info-card-wrapper'} id={'restaurant-info-card-wrapper'} >
        <div className={'mobile-h2'}>{name}</div>
        <div className={'img-and-cards-wrapper'}>
          <img src={image} className={'rest-img'} alt={name} style={{width: `${(window.innerWidth - 32 - 8) / 2}px`}}/>
          <div className={'cards-wrapper'}>
            <div className={'card green'}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.209 2.4796C10.331 2.10412 10.8622 2.10412 10.9842 2.4796L12.6859 7.71679C12.7404 7.88471 12.8969 7.9984 13.0735 7.9984L18.5802 7.9984C18.975 7.9984 19.1391 8.5036 18.8197 8.73566L14.3647 11.9724C14.2219 12.0762 14.1621 12.2602 14.2166 12.4281L15.9183 17.6653C16.0403 18.0407 15.6106 18.353 15.2912 18.1209L10.8361 14.8842C10.6933 14.7804 10.4999 14.7804 10.357 14.8842L5.90202 18.1209C5.58262 18.353 5.15286 18.0407 5.27486 17.6653L6.97653 12.4281C7.03109 12.2602 6.97132 12.0762 6.82848 11.9724L2.37346 8.73566C2.05406 8.5036 2.21821 7.9984 2.61301 7.9984L8.11972 7.9984C8.29628 7.9984 8.45276 7.88471 8.50732 7.71679L10.209 2.4796Z" fill="white"/>
              </svg>
              <div className={'rate'}>{formattedRate}</div>
            </div>
            <div className={'card mobile-squares white green'}>{mediumCheck === MediumCheck.SMALL ? '₽' : mediumCheck === MediumCheck.MEDIUM ? '₽₽' : '₽₽₽'}</div>
            <div className={'card mobile-squares white green'} dangerouslySetInnerHTML={{__html: formattedTime}}></div>
            <div className={'card gray'}>
              <RestaurantInfoButton
                infoTags={infoTags}
                address={address}
                description={description}
                name={name}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <div className={'restaurant-info-card-wrapper-desktop'} id={'restaurant-info-card-wrapper'}>
        <div className={'desktop-h2 text-primary'}>{name}</div>
        <div className={`img-and-cards-wrapper-desktop ${isTablet && 'tablet'}`}>
          <img src={image} className={'rest-img-desktop'} alt={name} style={{width: `${isTablet ? 256 : 400}px`}}/>
          <div className={'squares-wrapper-desktop'}>
            <div className={`card-desktop ${isTablet && 'tablet'} green`}>
              {isTablet ? (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.209 2.4796C10.331 2.10412 10.8622 2.10412 10.9842 2.4796L12.6859 7.71679C12.7404 7.88471 12.8969 7.9984 13.0735 7.9984L18.5802 7.9984C18.975 7.9984 19.1391 8.5036 18.8197 8.73566L14.3647 11.9724C14.2219 12.0762 14.1621 12.2602 14.2166 12.4281L15.9183 17.6653C16.0403 18.0407 15.6106 18.353 15.2912 18.1209L10.8361 14.8842C10.6933 14.7804 10.4999 14.7804 10.357 14.8842L5.90202 18.1209C5.58262 18.353 5.15286 18.0407 5.27486 17.6653L6.97653 12.4281C7.03109 12.2602 6.97132 12.0762 6.82848 11.9724L2.37346 8.73566C2.05406 8.5036 2.21821 7.9984 2.61301 7.9984L8.11972 7.9984C8.29628 7.9984 8.45276 7.88471 8.50732 7.71679L10.209 2.4796Z" fill="white"/>
                </svg>
              ) : (
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.4159 2.52197C13.6366 2.00955 14.3631 2.00955 14.5838 2.52197L17.3204 8.87672C17.4125 9.09046 17.6139 9.23682 17.8456 9.25832L24.735 9.89729C25.2906 9.94881 25.5151 10.6398 25.0959 11.008L19.8979 15.5744C19.723 15.728 19.6461 15.9648 19.6972 16.1919L21.2185 22.9415C21.3412 23.4858 20.7534 23.9128 20.2737 23.628L14.3244 20.0954C14.1243 19.9766 13.8753 19.9766 13.6752 20.0954L7.72601 23.628C7.2463 23.9128 6.65852 23.4858 6.78119 22.9415L8.30243 16.1919C8.3536 15.9648 8.27665 15.728 8.10182 15.5744L2.90375 11.008C2.48461 10.6398 2.70912 9.94881 3.26464 9.89729L10.154 9.25832C10.3858 9.23682 10.5872 9.09046 10.6793 8.87672L13.4159 2.52197Z" fill="white"/>
                </svg>
              )}
              <div className={'desktop-squares'} style={{ color: 'white', fontSize: isTablet ? '18px' : undefined}}>{formattedRate}</div>
            </div>
            <div className={`card-desktop ${isTablet && 'tablet'} desktop-squares`} dangerouslySetInnerHTML={{__html: formattedTime}} style={{ fontSize: isTablet ? '18px' : undefined}}></div>
            <div className={`card-desktop ${isTablet && 'tablet'} desktop-squares`} style={{ fontSize: isTablet ? '18px' : undefined}}>{mediumCheck === MediumCheck.SMALL ? '₽' : mediumCheck === MediumCheck.MEDIUM ? '₽₽' : '₽₽₽'}</div>
          </div>
          <div className={`restaurant-info-desktop ${isTablet && 'tablet'}`}>
            <div className={'info-tags-wrapper-desktop'}>
              <div className={'top-grad'}/>
              {infoTags.map((t, i) =>
                <TagButton
                  white={true}
                  onClickAction={() => t}
                  isActive={false}
                  text={t}
                  key={`${t}-info-tag-1-${i}`}
                  type={TagButtonType.DESKTOP}
                  disabled={true}
                />
              )}
            </div>
            <div className={'desktop-main-text text-primary'} style={{ overflowY: "scroll", boxSizing: "border-box", paddingTop: '12px', paddingBottom: '12px' }}>
              {description}
            </div>
            <div className={'desktop-subtext text-secondary'} style={{ position: 'relative', display: 'flex', width: '100%'}}>
              <div className={'bottom-grad'}/>
              {address}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default RestaurantInfoCard
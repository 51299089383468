import React from "react";
import MDSpinner from "react-md-spinner";

const Loader: React.FC = () => {
  return(
    <div style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
      <MDSpinner singleColor={'#00DF5F'} size={44} />
    </div>
  )
}

export default Loader
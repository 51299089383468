import './OrderPage.css'
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/ReduxStore";
import {getTotalOrderPrice, getTotalProductCountInOrder} from "../../../redux/current_order_reducer/OrderOperations";
import OrderProductInfoCard from "./order_product_info_card/OrderProductInfoCard";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";
import PlainInput from "../../shared_components/inputs/plain_input/PlainInput";
import LastProductWarning from "./last_product_warning/LastProductWarning";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import {useNavigate} from "react-router-dom";
import {OrderStatus} from "../../../constants/content_types/OrderStatus";
import Footer from "../../shared_components/footer/Footer";
import {setNewOrderStatus, setOrderRestaurantInfo} from "../../../redux/current_order_reducer/CurrentOrderReducer";
import Loader from "../../shared_components/loader/Loader";
import OrderHistoryItem from "../order_history_page/order_history_item/OrderHistoryItem";
import DetailedOrderHistoryView from "../order_history_page/detailed_order_history_view/DetailedOrderHistoryView";

const OrderPage: React.FC = () => {
  const orderState = useSelector((state: RootState) => state.order.orderState)
  const orderStatus = useSelector((state: RootState) => state.order.orderStatus)
  const order = useSelector((state: RootState) => state.order)

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [comment, setComment] = useState<string | undefined>()
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const totalCountInOrder = useMemo(() => {
    return getTotalProductCountInOrder(orderState)
  }, [orderState])

  const totalOrderPrice = useMemo(() => {
    return getTotalOrderPrice(orderState)
  }, [orderState])

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0 })
      document.body.classList.add('hidden')
      const t = setTimeout(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('hidden')
      }, 300)

      return () => {
        clearTimeout(t)
        document.body.classList.remove('hidden')
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resolvedHeader = useMemo(() => {
    const productsCount = totalCountInOrder.toString()
    const lastChar = productsCount?.slice(-1) || '0'

    if (productsCount?.length === 2 && productsCount[0] === '1') {
      return `В вашем заказе ${productsCount} блюд:`
    } else {
      if ('056789'.includes(lastChar)) {
        return `В вашем заказе ${productsCount} блюд:`
      } else if ('234'.includes(lastChar)) {
        return `В вашем заказе ${productsCount} блюда:`
      } else {
        return `В вашем заказе ${productsCount} блюдо:`
      }
    }
  }, [totalCountInOrder])

  const renderMobile = () => {
    return(
      <div
        className={`order-page-wrapper ${totalCountInOrder === 0 && 'empty hidden'}`}
        style={{ height: totalCountInOrder === 0 ? windowHeight : undefined }}
      >
        {totalCountInOrder === 0 ? (
          <div className={'empty-order-wrapper'}>
            <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_272_9093)">
                <path d="M27.3333 32.4585C39.5987 32.4585 49.5417 42.4015 49.5417 54.6668C49.5417 66.9322 39.5987 76.8752 27.3333 76.8752C15.068 76.8752 5.125 66.9322 5.125 54.6668C5.125 42.4015 15.068 32.4585 27.3333 32.4585Z" fill="#F3F4F6"/>
                <path d="M45.0728 74.7017C47.9767 76.0948 51.2305 76.8752 54.6666 76.8752C66.9319 76.8752 76.8749 66.9322 76.8749 54.6668C76.8749 42.4015 66.9319 32.4585 54.6666 32.4585C51.2305 32.4585 47.9767 33.2388 45.0728 34.6319C50.4487 39.6234 53.8125 46.7523 53.8125 54.6668C53.8125 62.5814 50.4487 69.7102 45.0728 74.7017Z" fill="#F3F4F6"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M50.3699 6.47094C50.57 7.87195 49.5965 9.16994 48.1955 9.37008C36.3877 11.0569 28.183 21.9965 29.8699 33.8043C30.07 35.2053 29.0965 36.5033 27.6955 36.7034C26.2945 36.9036 24.9965 35.9301 24.7964 34.5291C22.7093 19.9192 32.8609 6.38371 47.4707 4.29659C48.8717 4.09644 50.1697 5.06994 50.3699 6.47094Z" fill="#C6C9D1"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M48.979 4.5416C50.2448 5.17451 50.7579 6.71373 50.125 7.97955C46.5374 15.1546 47.9437 23.8203 53.6161 29.4927L56.4783 32.3549C57.479 33.3557 57.479 34.9781 56.4783 35.9789C55.4776 36.9796 53.8551 36.9796 52.8544 35.9789L49.9922 33.1166C42.7599 25.8844 40.967 14.8357 45.541 5.68758C46.1739 4.42176 47.7132 3.90869 48.979 4.5416Z" fill="#C6C9D1"/>
              </g>
              <defs>
                <clipPath id="clip0_272_9093">
                  <rect width="82" height="82" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div className={'empty-order-text-wrapper'}>
              <div className={'mobile-h3 text-primary text-center'}>В заказе пока пусто</div>
              <div className={'mobile-main-text text-secondary text-center'}
                   dangerouslySetInnerHTML={{__html: 'Выберите что‑нибудь в<br>меню ресторана и нажмите<br>«Добавить в заказ»'}}
              />
            </div>
          </div>
        ) : (
          <>
            {(() => {
              switch (orderStatus) {
                case OrderStatus.PREPARING:
                  return(
                    <>
                      <div className={'position-cards-wrapper'} style={{ maxHeight: windowHeight - 128, minHeight: windowHeight - 128 }}>
                        <div className={'mobile-h2 text-primary order-page-header'}>
                          {resolvedHeader}
                        </div>
                        {orderState?.map(pos => (
                          <OrderProductInfoCard
                            key={`${pos?.position?.id}-${pos?.position?.selectedVariationId}-pos-order`}
                            positionInfo={pos}
                          />
                        ))}
                        <PlainInput
                          placeholderText={'Комментарий официанту'}
                          onInputChange={(c) => setComment(c)}
                          validationFunc={() => true}
                          isSubmitButtonClicked={false}
                          onFocus={() => true}
                          multiline={true}
                          blurOnEnter={true}
                        />
                      </div>
                      <div className={'checkout-order-btn-wrapper'}>
                        <div className={'bottom-grad'} style={{ width: window.innerWidth - 16 }}/>
                        <StandardButton
                          onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.SENDING))}
                          text={`Заказать на ${totalOrderPrice} ₽`}
                          color={StandardButtonColor.GREEN}
                          iconType={StandardButtonIconType.WHITE_RIGHT_ARROW}
                          iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                        />
                      </div>
                    </>
                  )
                case OrderStatus.SENDING:
                  return (
                    <>
                      <div className={'order-sending-info-wrapper'} style={{ maxHeight: windowHeight - 128, minHeight: windowHeight - 128 }}>
                        <Loader/>
                        <div className={'order-sending-info-text-wrapper'}>
                          <div className={'mobile-h3 text-primary'}>
                            Отправляем ваш заказ...
                          </div>
                          <div className={'mobile-main-text text-tetriary'} style={{ textAlign: 'center'}}>
                            Подождите несколько минут, пока персонал примет заказ. Не уходите из-за стола – к вам может подойти официант
                          </div>
                        </div>
                      </div>
                      <div className={'checkout-order-btn-wrapper'} style={{ marginBottom: '32px' }}>
                        <div className={'bottom-grad'} style={{ width: window.innerWidth - 16 }}/>
                        <StandardButton
                          onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.PREPARING))}
                          text={'Отменить'}
                          color={StandardButtonColor.GRAY}
                          iconType={StandardButtonIconType.NO_ICON}
                          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                        />
                      </div>
                    </>
                  )
                case OrderStatus.ACCEPTED:
                case OrderStatus.CHANGED_AND_ACCEPTED:
                  return (
                    <>
                      <div className={'order-accepted-info-wrapper'} style={{ maxHeight: windowHeight - 196, minHeight: windowHeight - 196 }}>
                        <div className={'hdr-wrapper'}>
                          <div
                            className={'mobile-h2 text-primary'}
                            style={{ maxWidth: '220px', marginLeft: '8px'}}
                            dangerouslySetInnerHTML={{ __html: `${orderStatus === OrderStatus.ACCEPTED ? 'Ваш заказ принят' : 'Ваш заказ изменен и\u00A0принят'}`}}
                          />
                          <div style={{ marginTop: '4px'}}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.25 13L9.75 16.5L17.75 8.5" stroke="#00DF5F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </div>
                        </div>
                        <div className={'waiter-info-wrapper'} style={{ marginLeft: '8px'}}>
                          {orderStatus === OrderStatus.CHANGED_AND_ACCEPTED && (
                            <div className={'mobile-main-text text-secondary'}>
                              Официант внёс изменения в список блюд.
                            </div>
                          )}
                          <div
                            className={'mobile-main-text text-secondary'}
                            dangerouslySetInnerHTML={{__html: 'Заказ можно оплатить сейчас или позже. Позовите официанта,<br>если у вас есть вопросы или нужно изменить заказ'}}
                          />
                        </div>
                        <OrderHistoryItem
                          orderId={''}
                          restaurantId={order?.restaurantId || ''}
                          orderStatus={order?.orderStatus}
                          timestamp={order.timestamp}
                          restaurantName={order?.restaurantName || ''}
                          restaurantAddress={order?.restaurantAddress || ''}
                          orderPositions={order?.orderState}
                        />
                      </div>
                      <div className={'checkout-order-btn-wrapper'} style={{ marginBottom: '32px' }}>
                        <StandardButton
                          onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.PREPARING))}
                          text={'Оплачу наличными'}
                          color={StandardButtonColor.GRAY}
                          iconType={StandardButtonIconType.NO_ICON}
                          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                        />
                        <StandardButton
                          onClickAction={() => true}
                          text={'Оплатить картой'}
                          color={StandardButtonColor.GREEN}
                          iconType={StandardButtonIconType.NO_ICON}
                          iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                        />
                      </div>
                    </>
                  )
                case OrderStatus.DECLINED:
                  return (
                    <div
                      className={'order-sending-info-wrapper'}
                      style={{ maxHeight: windowHeight - 128, minHeight: windowHeight - 128 }}
                    >
                      <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_318_11086)">
                          <path d="M4.27051 40.9999C4.27051 61.2849 20.7147 77.7291 40.9997 77.7291C61.2846 77.7291 77.7288 61.2849 77.7288 40.9999C77.7288 20.715 61.2846 4.27075 40.9997 4.27075C20.7147 4.27075 4.27051 20.715 4.27051 40.9999Z" fill="#F3F4F6"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M26.2314 38.6825C26.2279 38.6899 26.2244 38.6972 26.2209 38.7044L26.2262 38.6935L26.229 38.6877L26.2314 38.6825Z" fill="#C6C9D1"/>
                          <path d="M26.2209 38.7044L26.2314 38.6825L26.229 38.6877L26.2262 38.6935L26.2209 38.7044L26.241 38.6666C26.266 38.621 26.3116 38.5411 26.3779 38.4383C26.5133 38.2284 26.7184 37.9485 26.9882 37.6751C27.532 37.1241 28.1961 36.7292 29.0416 36.7292C29.8872 36.7292 30.5513 37.1241 31.095 37.6751C31.3649 37.9485 31.57 38.2284 31.7053 38.4383C31.7716 38.5411 31.8173 38.621 31.8422 38.6666L31.8623 38.7044C32.4759 39.9663 33.9929 40.5013 35.2639 39.8991C36.5428 39.2931 37.0883 37.7651 36.4823 36.4861L36.3382 36.2066C36.2609 36.0653 36.1527 35.8783 36.0124 35.6608C35.7347 35.2301 35.3159 34.6559 34.7427 34.0751C33.6005 32.9177 31.7021 31.6042 29.0416 31.6042C26.3811 31.6042 24.4827 32.9177 23.3405 34.0751C22.7673 34.6559 22.3486 35.2301 22.0708 35.6608L21.6009 36.4861C20.9949 37.7651 21.5404 39.2931 22.8193 39.8991C24.0903 40.5013 25.6073 39.9663 26.2209 38.7044Z" fill="#C6C9D1"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M50.1484 38.6825C50.1449 38.6899 50.1414 38.6972 50.1379 38.7044L50.1432 38.6935L50.146 38.6877L50.1484 38.6825Z" fill="#C6C9D1"/>
                          <path d="M50.1379 38.7044L50.1484 38.6825L50.146 38.6877L50.1432 38.6935L50.1379 38.7044L50.158 38.6666C50.183 38.621 50.2286 38.5411 50.2949 38.4383C50.4303 38.2284 50.6354 37.9485 50.9052 37.6751C51.449 37.1241 52.1131 36.7292 52.9586 36.7292C53.8042 36.7292 54.4683 37.1241 55.012 37.6751C55.2818 37.9485 55.4869 38.2284 55.6223 38.4383C55.6886 38.5411 55.7343 38.621 55.7592 38.6666L55.7793 38.7044C56.3929 39.9663 57.9099 40.5013 59.1809 39.8991C60.4598 39.2931 61.0053 37.7651 60.3993 36.4861L60.2552 36.2066C60.1779 36.0653 60.0697 35.8783 59.9294 35.6608C59.6517 35.2301 59.2329 34.6559 58.6597 34.0751C57.5175 32.9177 55.6191 31.6042 52.9586 31.6042C50.2981 31.6042 48.3997 32.9177 47.2575 34.0751C46.6843 34.6559 46.2656 35.2301 45.9878 35.6608L45.5179 36.4861C44.9119 37.7651 45.4574 39.2931 46.7363 39.8991C48.0073 40.5013 49.5243 39.9663 50.1379 38.7044Z" fill="#C6C9D1"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M50.1035 59.521C43.9917 56.4646 38.0076 56.4648 31.8956 59.521C30.6298 60.1539 29.0906 59.6409 28.4577 58.3751C27.8247 57.1093 28.3377 55.5701 29.6035 54.9371C37.1582 51.1595 44.8408 51.1591 52.3957 54.9372C53.6615 55.5702 54.1745 57.1094 53.5415 58.3752C52.9085 59.641 51.3692 60.154 50.1035 59.521Z" fill="#C6C9D1"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_318_11086">
                            <rect width="82" height="82" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                      <div className={'order-sending-info-text-wrapper'} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className={'mobile-h3 text-primary'}>
                          Заказ отклонен или изменен
                        </div>
                        <div
                          className={'mobile-main-text text-tetriary'} style={{ textAlign: 'center'}}
                          dangerouslySetInnerHTML={{ __html: 'Не переживайте – официант<br>сейчас подойдет и поможет<br>решить проблему'}}
                        />
                      </div>
                    </div>
                  )
              }
            })()}
          </>
        )}
        {(orderStatus === OrderStatus.ACCEPTED || orderStatus === OrderStatus.CHANGED_AND_ACCEPTED)
          && <DetailedOrderHistoryView/>
        }
        <LastProductWarning/>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        <HeaderDesktop withProfileButton={true} withBackButton={true} />
        {totalCountInOrder === 0 ? (
          <div className={'empty-order-wrapper-desktop'} style={{ minHeight: window.innerHeight - 329 }}>
            <div className={'empty-order-info-desktop'}>
              <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.3333 32.458C39.5987 32.458 49.5417 42.401 49.5417 54.6663C49.5417 66.9317 39.5987 76.8747 27.3333 76.8747C15.068 76.8747 5.125 66.9317 5.125 54.6663C5.125 42.401 15.068 32.458 27.3333 32.458Z" fill="#F3F4F6"/>
                <path d="M45.0732 74.7012C47.9772 76.0943 51.231 76.8747 54.6671 76.8747C66.9324 76.8747 76.8754 66.9317 76.8754 54.6663C76.8754 42.401 66.9324 32.458 54.6671 32.458C51.231 32.458 47.9772 33.2383 45.0732 34.6315C50.4492 39.623 53.813 46.7518 53.813 54.6663C53.813 62.5809 50.4492 69.7097 45.0732 74.7012Z" fill="#F3F4F6"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M50.3699 6.47094C50.57 7.87195 49.5965 9.16994 48.1955 9.37008C36.3877 11.0569 28.183 21.9965 29.8699 33.8043C30.07 35.2053 29.0965 36.5033 27.6955 36.7034C26.2945 36.9036 24.9965 35.9301 24.7964 34.5291C22.7093 19.9192 32.8609 6.38371 47.4707 4.29659C48.8717 4.09644 50.1697 5.06994 50.3699 6.47094Z" fill="#C6C9D1"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M48.9795 4.5416C50.2453 5.17451 50.7584 6.71373 50.1255 7.97955C46.5379 15.1546 47.9442 23.8203 53.6166 29.4927L56.4788 32.3549C57.4795 33.3557 57.4795 34.9781 56.4788 35.9789C55.4781 36.9796 53.8556 36.9796 52.8549 35.9789L49.9926 33.1166C42.7604 25.8844 40.9674 14.8357 45.5415 5.68758C46.1744 4.42176 47.7136 3.90869 48.9795 4.5416Z" fill="#C6C9D1"/>
              </svg>
              <div className={'info-wrpr'}>
                <div className={'desktop-h3 text-primary'} style={{ textAlign: 'center', marginBottom: '16px'}}>
                  В заказе пока пусто
                </div>
                <div
                  style={{ textAlign: 'center' }}
                  className={'desktop-main-text text-secondary'}
                  dangerouslySetInnerHTML={{ __html: 'Выберите что‑нибудь в меню ресторана<br>и нажмите «Добавить в заказ»'}}
                />
              </div>
              <div style={{ marginTop: '32px'}}>
                <StandardButton
                  onClickAction={() => navigate(RoutePaths.HOME)}
                  text={'Вернуться к ресторанам'}
                  color={StandardButtonColor.GREEN}
                  iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                  iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {(() => {
              switch (orderStatus) {
                case OrderStatus.PREPARING:
                  return (
                    <div
                      className={'order-page-wrapper-desktop'}
                      style={{ minHeight: window.innerHeight, maxHeight: window.innerHeight }}
                    >
                      <div
                        className={'order-wrapper-desktop-popup'}
                        style={{
                          width: Math.min(1168, window.innerWidth / 1.2),
                          maxHeight: window.innerHeight - 130,
                        }}
                      >
                        <div className={'desktop-h2 greetings'} style={{ position: 'relative', display: 'flex', width: '100%' }}>
                          <div className={'top-grad'} style={{width: Math.min(1168 - 64, window.innerWidth / 1.2 - 64)}}/>
                          {resolvedHeader}
                        </div>
                        <div className={'order-food-pos-desktop-wrapper'}>
                          {orderState?.map(pos => (
                            <OrderProductInfoCard
                              key={`${pos?.position?.id}-${pos?.position?.selectedVariationId}-pos-order`}
                              positionInfo={pos}
                            />
                          ))}
                          <div style={{ maxWidth: Math.min(1168, window.innerWidth / 1.2) - 68}}>
                            <PlainInput
                              placeholderText={'Комментарий официанту'}
                              onInputChange={(c) => setComment(c)}
                              validationFunc={() => true}
                              isSubmitButtonClicked={false}
                              onFocus={() => true}
                              multiline={true}
                              blurOnEnter={true}
                            />
                          </div>
                        </div>
                        <div className={'order-btn-desktop-wrapper'}>
                          <div style={{ marginRight: '16px'}}>
                            <StandardButton
                              onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.SENDING))}
                              text={`Заказать на ${totalOrderPrice} ₽`}
                              color={StandardButtonColor.GREEN}
                              iconType={StandardButtonIconType.WHITE_RIGHT_ARROW}
                              iconPosition={StandardButtonIconPosition.AFTER_TEXT}
                            />
                          </div>
                        </div>
                        <div className={'bottom-grad'} style={{width: Math.min(1168 - 64, window.innerWidth / 1.2 - 64)}}/>
                      </div>
                    </div>
                  );
                case OrderStatus.SENDING:
                  return (
                    <div
                      className={'order-page-wrapper-desktop'}
                      style={{ paddingTop: '130px', backgroundColor: 'white', paddingBottom: '80px', minHeight: window.innerHeight - 333 }}
                    >
                      <div className={'order-sending-wrapper-desktop'}>
                        <Loader/>
                        <div className={'order-sending-info-wrapper-desktop'}>
                          <div className={'desktop-h3 text-primary'}>
                            Отправляем ваш заказ...
                          </div>
                          <div
                            className={'desktop-main-text text-tetriary'}
                            style={{ textAlign: 'center'}}
                            dangerouslySetInnerHTML={{ __html: 'Подождите несколько минут, пока персонал примет заказ.<br>Не уходите из-за стола – к вам может подойти официант'}}
                          />
                          <div style={{ marginTop: '32px', marginBottom: '48px' }}>
                            <StandardButton
                              onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.PREPARING))}
                              text={'Отменить'}
                              color={StandardButtonColor.GRAY}
                              iconType={StandardButtonIconType.NO_ICON}
                              iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                case OrderStatus.ACCEPTED:
                case OrderStatus.CHANGED_AND_ACCEPTED:
                  return (
                    <div
                      className={'order-page-wrapper-desktop'}
                      style={{ minHeight: window.innerHeight, maxHeight: window.innerHeight }}
                    >
                      <div
                        className={'order-wrapper-desktop-popup'}
                        style={{
                          width: Math.min(784, window.innerWidth / 1.4),
                          maxHeight: window.innerHeight - 200,
                          paddingLeft: '16px'
                        }}
                      >
                        <div className={'accepted-order-wrapper'}>
                          <div className={'hdr-wrapper'} style={{ marginLeft: '16px' }}>
                            <div className={'desktop-h2 text-primary'}>
                              {orderStatus === OrderStatus.ACCEPTED ? 'Ваш заказ принят' : 'Ваш заказ изменен и принят'}
                            </div>
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.9375 21.875L17.0625 28L31.0625 14" stroke="#00DF5F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </div>
                          <div className={'waiter-info-wrapper'} style={{ marginLeft: '16px' }}>
                            {orderStatus === OrderStatus.CHANGED_AND_ACCEPTED && (
                              <div className={'mobile-main-text text-secondary'}>
                                Официант внёс изменения в список блюд.
                              </div>
                            )}
                            <div
                              className={'mobile-main-text text-secondary'}
                              dangerouslySetInnerHTML={{__html: 'Заказ можно оплатить сейчас или позже. Позовите официанта,<br>если у вас есть вопросы или нужно изменить заказ'}}
                            />
                          </div>
                          <OrderHistoryItem
                            orderId={''}
                            restaurantId={order?.restaurantId || ''}
                            orderStatus={order?.orderStatus}
                            timestamp={order.timestamp}
                            restaurantName={order?.restaurantName || ''}
                            restaurantAddress={order?.restaurantAddress || ''}
                            orderPositions={order?.orderState}
                            withButton={true}
                          />
                        </div>
                        <div className={'order-btn-desktop-wrapper'} style={{ marginTop: '124px' }}>
                          <div>
                            <StandardButton
                              onClickAction={() => dispatch(setNewOrderStatus(OrderStatus.PREPARING))}
                              text={'Оплачу наличными'}
                              color={StandardButtonColor.GRAY}
                              iconType={StandardButtonIconType.NO_ICON}
                              iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                            />
                          </div>
                          <div style={{ marginRight: '16px'}}>
                            <StandardButton
                              onClickAction={() => true}
                              text={'Оплатить картой'}
                              color={StandardButtonColor.GREEN}
                              iconType={StandardButtonIconType.NO_ICON}
                              iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                case OrderStatus.DECLINED:
                  return (
                    <div
                      className={'order-page-wrapper-desktop'}
                      style={{ paddingTop: '100px', backgroundColor: 'white', paddingBottom: '80px', minHeight: window.innerHeight - 333 }}
                    >
                      <div className={'order-sending-wrapper-desktop'}>
                        <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_349_11321)">
                            <path d="M27.3333 32.458C39.5987 32.458 49.5417 42.401 49.5417 54.6663C49.5417 66.9317 39.5987 76.8747 27.3333 76.8747C15.068 76.8747 5.125 66.9317 5.125 54.6663C5.125 42.401 15.068 32.458 27.3333 32.458Z" fill="#F3F4F6"/>
                            <path d="M45.0732 74.7012C47.9772 76.0943 51.231 76.8747 54.6671 76.8747C66.9324 76.8747 76.8754 66.9317 76.8754 54.6663C76.8754 42.401 66.9324 32.458 54.6671 32.458C51.231 32.458 47.9772 33.2383 45.0732 34.6315C50.4492 39.623 53.813 46.7518 53.813 54.6663C53.813 62.5809 50.4492 69.7097 45.0732 74.7012Z" fill="#F3F4F6"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M50.3699 6.47094C50.57 7.87195 49.5965 9.16994 48.1955 9.37008C36.3877 11.0569 28.183 21.9965 29.8699 33.8043C30.07 35.2053 29.0965 36.5033 27.6955 36.7034C26.2945 36.9036 24.9965 35.9301 24.7964 34.5291C22.7093 19.9192 32.8609 6.38371 47.4707 4.29659C48.8717 4.09644 50.1697 5.06994 50.3699 6.47094Z" fill="#C6C9D1"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M48.9795 4.5416C50.2453 5.17451 50.7584 6.71373 50.1255 7.97955C46.5379 15.1546 47.9442 23.8203 53.6166 29.4927L56.4788 32.3549C57.4795 33.3557 57.4795 34.9781 56.4788 35.9789C55.4781 36.9796 53.8556 36.9796 52.8549 35.9789L49.9926 33.1166C42.7604 25.8844 40.9674 14.8357 45.5415 5.68758C46.1744 4.42176 47.7136 3.90869 48.9795 4.5416Z" fill="#C6C9D1"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_349_11321">
                              <rect width="82" height="82" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <div className={'order-sending-info-wrapper-desktop'}>
                          <div className={'desktop-h3 text-primary'}>
                            Заказ отклонен или изменен
                          </div>
                          <div
                            className={'desktop-main-text text-tetriary'}
                            style={{ textAlign: 'center'}}
                            dangerouslySetInnerHTML={{ __html: 'Не переживайте – официант сейчас подойдет<br>и поможет решить проблему'}}
                          />
                        </div>
                      </div>
                    </div>
                  );
              }
            })()}
          </>
        )}
        <LastProductWarning/>
        {(totalCountInOrder === 0 || orderStatus !== OrderStatus.PREPARING &&
          orderStatus !== OrderStatus.ACCEPTED &&
          orderStatus !== OrderStatus.CHANGED_AND_ACCEPTED) &&
          <Footer/>
        }
        {(orderStatus === OrderStatus.ACCEPTED || orderStatus === OrderStatus.CHANGED_AND_ACCEPTED)
          && <DetailedOrderHistoryView/>
        }
      </>
    )
  }

  useEffect(() => {
    // TODO - replace with actual backend request
    const getRandomOrderStatus = (): OrderStatus => {
      const randomIndex = Math.floor(Math.random() * 3);
      if (randomIndex === 0) {
        return OrderStatus.ACCEPTED;
      } else if (randomIndex === 1) {
        return OrderStatus.CHANGED_AND_ACCEPTED;
      } else {
        return OrderStatus.DECLINED;
      }
    }

    let t: NodeJS.Timeout
    if (orderStatus === OrderStatus.SENDING) {
      t = setTimeout(() => {
        dispatch(setOrderRestaurantInfo('Futur Bar', 'Дмитровское ш. д 68, пом. 1, Москва'))
        dispatch(setNewOrderStatus(getRandomOrderStatus()))
      }, 5000)
    } else if (orderStatus === OrderStatus.DECLINED) {
      const randomIndex = Math.floor(Math.random() * 2);

      if (randomIndex == 1) {
        dispatch(setNewOrderStatus(OrderStatus.PREPARING))
      }
    }

    return () => clearTimeout(t)
  }, [orderStatus]);

  return isMobile ? renderMobile() : renderDesktop()
}

export default OrderPage
import React, {CSSProperties} from "react";

interface QrAimCornerProps {
  rotation: number
  additionalStyle: CSSProperties
}

const QrAimCorner: React.FC<QrAimCornerProps> = ({ rotation, additionalStyle }) => {
  return(
    <div style={{...additionalStyle, transform: `rotate(${rotation}deg)`, width: '56px', height: '56px' }}>
      <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27" height="6" fill="black" fillOpacity="0.35"/>
        <rect x="6" y="6" width="27" height="6" transform="rotate(90 6 6)" fill="black" fillOpacity="0.35"/>
        <path d="M57 3H19C10.1634 3 3 10.1634 3 19V57" stroke="#00DF5F" strokeWidth="6"/>
      </svg>
    </div>
  )
}

export default QrAimCorner
import './NotFoundPage.css'
import React, {useEffect} from "react";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../shared_components/buttons/standard_button/StandardButton";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import Footer from "../../shared_components/footer/Footer";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE})


  useEffect(() => {
    window.scrollTo({ top: 0 })
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, []);

  const renderMobile = () => {
    return(
      <div className={'not-found-page-wrapper'} style={{ height: window.innerHeight }}>
        <div className={'main-not-found-wrapper'}>
          <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_351_12361)">
              <path d="M12.2335 57.6692L24.3132 69.749C32.6526 78.0883 46.1733 78.0883 54.5126 69.749L70.9911 53.2704C74.1925 50.069 75.9922 45.7278 75.9947 41.2003L76.0035 25.6359C76.0096 14.7772 67.2053 5.9729 56.3466 5.97901L40.7822 5.98776C36.2547 5.99031 31.9134 7.78997 28.712 10.9914L12.2335 27.4699C3.89418 35.8092 3.89417 49.3299 12.2335 57.6692Z" fill="#F3F4F6"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M39.7031 25.1919C41.1184 25.1919 42.2656 26.3392 42.2656 27.7544V56.7458C42.2656 58.161 41.1184 59.3083 39.7031 59.3083C38.2879 59.3083 37.1406 58.161 37.1406 56.7458V27.7544C37.1406 26.3392 38.2879 25.1919 39.7031 25.1919Z" fill="#C6C9D1"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M22.6445 42.25C22.6445 40.8348 23.7918 39.6875 25.207 39.6875H54.1984C55.6136 39.6875 56.7609 40.8348 56.7609 42.25C56.7609 43.6652 55.6136 44.8125 54.1984 44.8125H25.207C23.7918 44.8125 22.6445 43.6652 22.6445 42.25Z" fill="#C6C9D1"/>
            </g>
            <defs>
              <clipPath id="clip0_351_12361">
                <rect width="82" height="82" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div className={'not-found-text-wrapper'}>
            <div className={'mobile-h3 gray'}>
              Такой страницы нет
            </div>
            <div
              className={'mobile-main-text gray-sec'}
              dangerouslySetInnerHTML={{ __html: 'Ошибка 404. Скорее всего,<br>страница устарела и была удалена,<br> или в ссылке ошибка'}}
            />
          </div>
        </div>
        <div style={{ bottom: 0, position: "fixed", width: window.innerWidth, padding: '16px', boxSizing: 'border-box'}}>
          <StandardButton
            onClickAction={() => navigate(RoutePaths.HOME)}
            text={'Вернуться к ресторанам'}
            color={StandardButtonColor.GREEN}
            iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
            iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
          />
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        <HeaderDesktop withProfileButton={true} />
        <div className={'not-found-page-wrapper-desktop'} style={{ minHeight: window.innerHeight - 329}}>
          <div className={'not-found-info-desktop'}>
            <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_351_12571)">
                <path d="M12.2335 57.6687L24.3132 69.7485C32.6526 78.0878 46.1733 78.0878 54.5126 69.7485L70.9911 53.27C74.1925 50.0686 75.9922 45.7273 75.9947 41.1998L76.0035 25.6354C76.0096 14.7767 67.2053 5.97241 56.3466 5.97852L40.7822 5.98728C36.2547 5.98982 31.9134 7.78948 28.712 10.9909L12.2335 27.4694C3.89418 35.8087 3.89417 49.3294 12.2335 57.6687Z" fill="#F3F4F6"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M39.7031 25.1914C41.1184 25.1914 42.2656 26.3387 42.2656 27.7539V56.7453C42.2656 58.1605 41.1184 59.3078 39.7031 59.3078C38.2879 59.3078 37.1406 58.1605 37.1406 56.7453V27.7539C37.1406 26.3387 38.2879 25.1914 39.7031 25.1914Z" fill="#C6C9D1"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.6445 42.25C22.6445 40.8348 23.7918 39.6875 25.207 39.6875H54.1984C55.6136 39.6875 56.7609 40.8348 56.7609 42.25C56.7609 43.6652 55.6136 44.8125 54.1984 44.8125H25.207C23.7918 44.8125 22.6445 43.6652 22.6445 42.25Z" fill="#C6C9D1"/>
              </g>
              <defs>
                <clipPath id="clip0_351_12571">
                  <rect width="82" height="82" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <div className={'info-wrpr'}>
              <div className={'desktop-h3 text-primary'} style={{ textAlign: 'center', marginBottom: '16px'}}>
                Такой страницы нет
              </div>
              <div
                style={{ textAlign: 'center' }}
                className={'desktop-main-text text-secondary'}
                dangerouslySetInnerHTML={{ __html: 'Ошибка 404. Скорее всего, страница устарела и была<br>удалена, или в ссылке ошибка'}}
              />
            </div>
            <div style={{ marginTop: '32px'}}>
              <StandardButton
                onClickAction={() => navigate(RoutePaths.HOME)}
                text={'Вернуться к ресторанам'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.WHITE_LEFT_ARROW}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </div>
        </div>
        <Footer/>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default NotFoundPage
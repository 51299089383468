import React from "react";
import './RestaurantFoodSection.css'
import {FoodPositionInfo} from "../../../../constants/content_types/FoodInfo";
import FoodCard, {FoodCardLocation} from "../../../shared_components/food_card/FoodCard";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../../constants/enums/MediaQueries";

interface RestaurantFoodSectionProps {
  sectionName: string;
  positions: FoodPositionInfo[]
}

const RestaurantFoodSection: React.FC<RestaurantFoodSectionProps> = ({ sectionName, positions}) => {
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  const renderMobile = () => {
    return(
      <div className={'food-section-wrapper'}>
        <div className={'mobile-h2 text-primary'}>{sectionName}</div>
        <div className={'food-grid'}>
          {positions?.map(p =>
            <FoodCard location={FoodCardLocation.RESTAURANT_PAGE} info={p} key={`${p.id}rest-food-sec-inf`}/>
          )}
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <div className={'food-section-wrapper-desktop'}>
        <div className={'desktop-h3 text-primary'}>{sectionName}</div>
        <div className={'food-wrapper-desktop'}>
          {positions?.map(p =>
            <FoodCard location={FoodCardLocation.RESTAURANT_PAGE} info={p} key={`${p.id}rest-food-sec-inf`}/>
          )}
        </div>
      </div>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default RestaurantFoodSection
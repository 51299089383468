import './RestaurantPage.css'
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../constants/enums/RoutePaths";
import {RestaurantInfo} from "../../../constants/content_types/RestaurantInfo";
import Loader from "../../shared_components/loader/Loader";
import SquareButton, {SquareButtonIconType} from "../../shared_components/buttons/square_button/SquareButton";
import SearchInput from "../../shared_components/inputs/search_input/SearchInput";
import {CSS} from "react-spring";
import RestaurantInfoCard from "./restaurant_info_card/RestaurantInfoCard";
import TagButton, {TagButtonType} from "../../shared_components/buttons/tag_button/TagButton";
import {Element, Events, Link, scrollSpy} from "react-scroll";
import {MenuSectionTag} from "../../../constants/content_types/MenuSectionTag";
import PreferencesButton from "./preferences_button/PreferencesButton";
import ScrollBackButton from "../../shared_components/buttons/scroll_back_button/ScrollBackButton";
import RestaurantFoodSection from "./restaurant_food_section/RestaurantFoodSection";
import {rest1, rest2, rest3} from "./MockRestaurantPageData";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/enums/MediaQueries";
import Footer from "../../shared_components/footer/Footer";
import HeaderDesktop from "../../shared_components/header/HeaderDesktop";

const RestaurantPage: React.FC = () => {
  const restaurantId = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [restaurantInfo, setRestaurantInfo] = useState<RestaurantInfo>()
  const [activeMenuSection, setActiveMenuSection] = useState<MenuSectionTag>()
  const [isSynthetic, setSynthetic] = useState(false)
  const [isScrollBackButtonDisplayed, setIsScrollBackButtonDisplayed] = useState(false)
  const isMobile = useMediaQuery({ query: MediaQueries.NORMAL_MOBILE })

  useEffect(() => {
    Events.scrollEvent.register('begin', (to) => {
      if (isMobile) {
        setSynthetic(true)
      }
      setActiveMenuSection(to as MenuSectionTag)
    });
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
    };
  }, []);

  useEffect(() => {
    if (!restaurantId) {
      navigate(RoutePaths.NOT_FOUND)
    }

    const id = restaurantId.id

    let t: any;
    switch (id) {
      case '1':
        t = setTimeout(() => setRestaurantInfo(rest1), 1000)
        break;
      case '2':
        t = setTimeout(() => setRestaurantInfo(rest2), 1000)
        break;
      case '3':
        t = setTimeout(() => setRestaurantInfo(rest3), 1000)
        break;
    }

    return () => clearTimeout(t)
  }, [restaurantId])

  const tagsRef = useRef<HTMLDivElement>(null);
  const dummyTagsRef = useRef<HTMLDivElement>(null);
  const [tagsPosition, setTagsPosition] = useState<CSS.Property.Position>('relative')

  useEffect(() => {
    const handleScroll = () => {
      const dpos = dummyTagsRef?.current?.getBoundingClientRect()?.top || 0
      const pos = tagsRef?.current?.getBoundingClientRect()?.top || 0

      if (isMobile) {
        if (dpos <= 104 && pos <= 64) {
          setTagsPosition('fixed')
        }
        if (dpos >= 64 && pos === 64){
          setTagsPosition('relative')
        }
      } else {
        if (dpos <= 80 + (document.getElementById('tags-scroll-wrapper')?.getBoundingClientRect().height || 0) &&
          pos <= 80 && (document.getElementById('tags-scroll-wrapper')?.getBoundingClientRect().height || 0) > 0) {
          setTagsPosition('fixed')
        }
        if (dpos >= 80 && pos === 80) {
          setTagsPosition('relative')
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 2) {
        setIsScrollBackButtonDisplayed(true)
      } else {
        setIsScrollBackButtonDisplayed(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById('tags-scroll-wrapper');
    const element = document.getElementById(`${activeMenuSection}-search-tag`);

    if (container && element) {
      const elementLeft = element.offsetLeft;
      const elementWidth = element.offsetWidth;
      const containerWidth = container.offsetWidth;

      container.scrollTo({
        left: elementLeft - (containerWidth - elementWidth) / 2,
        behavior: 'smooth'
      });
    }
  }, [activeMenuSection]);

  useEffect(() => {
    window.scrollTo({ top: 0 })
    setTagsPosition('relative')
    setTimeout(() => setTagsPosition('relative'), 300)
    setTimeout(() => window.scrollTo({ top: 0 }), 300)
  }, []);

  const renderMobile = () => {
    return(
      <div className={`restaurant-page-wrapper ${restaurantInfo && 'loaded'}`}>
        <ScrollBackButton isDisplayed={isScrollBackButtonDisplayed} onClickAdditionalAction={() => setSynthetic(false)}/>
        {!restaurantInfo ? (
          <div style={{ height: window.innerHeight, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Loader/>
          </div>
        ) : (
          <>
            <div className={'top-container'}>
              <SquareButton onClickAction={() => navigate(RoutePaths.HOME)}  iconType={SquareButtonIconType.ARROW_BACK}/>
              <SearchInput onInputChange={() => true} placeholder={'Название блюда...'} />
              <PreferencesButton/>
            </div>
            <div style={{ overflowY: 'scroll', display: 'flex', flexDirection: 'column', flexShrink: 0 }}>
              <RestaurantInfoCard
                infoTags={restaurantInfo.restaurantInfoTags}
                closingTime={restaurantInfo.closingTime}
                openingTime={restaurantInfo.openingTime}
                image={restaurantInfo.image}
                mediumCheck={restaurantInfo.mediumCheck}
                name={restaurantInfo.name}
                rate={restaurantInfo.rate}
                address={restaurantInfo.address}
                description={restaurantInfo.description}
              />
              <div
                id={'tags-scroll-wrapper'}
                style={{ position: tagsPosition }}
                className={`tags-scroll-wrapper animation-02s-all ${tagsPosition === 'fixed' && 'shadowed'}`}
                ref={tagsRef}
              >
                {restaurantInfo?.menuSectionsTags.map((tag, i) => (
                  <Link
                    id={`${tag}-search-tag`}
                    to={`${tag}`}
                    spy={true}
                    smooth={true}
                    offset={-104}
                    duration={tagsPosition === 'fixed' ? 250 : 250 * (i + 1)}
                    style={{ flexShrink: 0 }}
                    key={`${tag}-search-tag`}
                    onSetActive={() => {
                      if (!isSynthetic) {
                        setActiveMenuSection(tag)
                      }
                    }}
                  >
                    <Element name={`${tag}-element-horizontal`}>
                      <TagButton
                        onClickAction={() => true}
                        isActive={activeMenuSection === tag}
                        text={tag}
                        type={TagButtonType.MOBILE}
                      />
                    </Element>
                  </Link>
                ))}
              </div>
              <div style={{height: tagsPosition === 'fixed' ? '40px' : 0, width: '100%', display: 'flex'}} ref={dummyTagsRef}/>
              {restaurantInfo?.menuSectionsTags.map(tag => (
                <Element name={tag} key={`${tag}-mn-sctn`} onTouchStart={() => setSynthetic(false)}>
                  <RestaurantFoodSection sectionName={tag} positions={restaurantInfo?.foodPositions.filter(p => p?.menuSectionTags?.includes(tag))} />
                </Element>
              ))}
            </div>
          </>
        )}
      </div>
    )
  }

  const renderDesktop = () => {
    return(
      <>
        <HeaderDesktop
          withSearchBar={true}
          withProfileButton={true}
          withPreferencesButton={true}
          searchBar={<SearchInput onInputChange={() => true} placeholder={'Название блюда...'} />}
        />
        <div className={'restaurant-page-wrapper-desktop'}>
          {!restaurantInfo ? (
            <div style={{ height: window.innerHeight - 410, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Loader/>
            </div>
          ) : (
            <>
              <div className={'rest-base-flex-wrapper-desktop'}>
                <RestaurantInfoCard
                  infoTags={restaurantInfo.restaurantInfoTags}
                  closingTime={restaurantInfo.closingTime}
                  openingTime={restaurantInfo.openingTime}
                  image={restaurantInfo.image}
                  mediumCheck={restaurantInfo.mediumCheck}
                  name={restaurantInfo.name}
                  rate={restaurantInfo.rate}
                  address={restaurantInfo.address}
                  description={restaurantInfo.description}
                />
              </div>
              <div
                id={'tags-scroll-wrapper'}
                style={{ position: tagsPosition }}
                className={`tags-scroll-wrapper-desktop animation-02s-all ${tagsPosition === 'fixed' && 'shadowed'}`}
                ref={tagsRef}
              >
                <div className={'tags-scroll-internal-wrapper-desktop'}>
                  {restaurantInfo?.menuSectionsTags.map((tag, i) => (
                    <Link
                      id={`${tag}-search-tag`}
                      to={`${tag}`}
                      spy={true}
                      smooth={true}
                      offset={-(80 + (document.getElementById('tags-scroll-wrapper')?.getBoundingClientRect().height || 0))}
                      duration={tagsPosition === 'fixed' ? 250 : 250 * (i + 1)}
                      style={{ flexShrink: 0 }}
                      key={`${tag}-search-tag`}
                      onSetActive={() => {
                        if (!isSynthetic) {
                          setActiveMenuSection(tag)
                        }
                      }}
                    >
                      <TagButton
                        onClickAction={() => true}
                        isActive={activeMenuSection === tag}
                        text={tag}
                        type={TagButtonType.MOBILE}
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <div
                style={{
                  height: tagsPosition === 'fixed'
                    ? (document.getElementById('tags-scroll-wrapper')?.getBoundingClientRect().height || 0)
                    : 0,
                  width: '100%',
                  display: 'flex'
                }}
                ref={dummyTagsRef}
              />
              <div className={'rest-base-flex-wrapper-desktop'}>
                <div className={'rest-food-section-desktop-wrapper'}>
                  {restaurantInfo?.menuSectionsTags.map(tag => (
                    <Element name={tag} key={`${tag}-mn-sctn`} onScroll={() => setSynthetic(false)}>
                      <RestaurantFoodSection sectionName={tag} positions={restaurantInfo?.foodPositions.filter(p => p?.menuSectionTags?.includes(tag))} />
                    </Element>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <Footer/>
      </>
    )
  }

  return isMobile ? renderMobile() : renderDesktop()
}

export default RestaurantPage

import './RestaurantInfoButton.css'
import React, {useEffect, useState} from "react";
import Sheet from "react-modal-sheet";
import StandardButton, {
  StandardButtonColor,
  StandardButtonIconPosition,
  StandardButtonIconType
} from "../../../shared_components/buttons/standard_button/StandardButton";
import {RestaurantInfoTag} from "../../../../constants/content_types/RestaurantInfoTag";
import TagButton, {TagButtonType} from "../../../shared_components/buttons/tag_button/TagButton";
import {useLocation} from "react-router-dom";

interface RestaurantInfoButtonProps {
  name: string;
  description: string;
  address: string;
  infoTags: RestaurantInfoTag[]
}

const RestaurantInfoButton: React.FC<RestaurantInfoButtonProps> = ({ name, description, address, infoTags }) => {
  const [isOpened, setOpened] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (!isOpened) {
      document.body.classList.remove('hidden');
      document.body.classList.remove('rest-info-btn-all-wrapper');
    } else {
      document.body.classList.add('hidden');
      document.body.classList.add('rest-info-btn-all-wrapper');
    }

    return () => {
      document.body.classList.remove('hidden');
      document.body.classList.remove('rest-info-btn-all-wrapper');
    }
  }, [isOpened]);

  useEffect(() => {
    const handleResize = () => {
      setOpened(false)
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setOpened(false)
  }, [location?.pathname]);

  return(
    <>
      {isOpened && (
        <div
          style={{ position: 'fixed', top: 0, left: 0, width: window.innerWidth, height: window.innerHeight, zIndex: 10 }}
          onClick={(e) => {
            e.preventDefault()
            setOpened(false)
          }}
        />
      )}
      <div className={'card gray'} onClick={() => setOpened(true)}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_100_4358)">
            <path d="M2.6665 16C2.6665 23.3638 8.63604 29.3334 15.9998 29.3334C23.3636 29.3334 29.3332 23.3638 29.3332 16C29.3332 8.63622 23.3636 2.66669 15.9998 2.66669C8.63604 2.66669 2.6665 8.63622 2.6665 16Z" stroke="#C6C9D1" strokeWidth="2" strokeLinecap="round"/>
            <path d="M16 21.3334L16 15.3334" stroke="#C6C9D1" strokeWidth="2" strokeLinecap="round"/>
            <path d="M16 11.3333L16 11.332" stroke="#C6C9D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_100_4358">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <Sheet
        layoutScroll={false}
        isOpen={isOpened}
        onClose={() => setOpened(false)}
        detent={"content-height"}
        snapPoints={[window.innerHeight * 0.85, 0]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content disableDrag={true}>
            <div className={'restaurant-info-sheet-wrapper'}>
              <div className={'mobile-h2'} style={{ marginBottom: '16px' }}>{name}</div>
              <div className={'tags-and-text-info-wrapper'}>
                <div className={'rest-info-tags-wrapper'}>
                  {infoTags.map((t, i) =>
                    <TagButton
                      onClickAction={() => t}
                      isActive={false}
                      text={t}
                      key={`${t}-info-tag-${i}`}
                      type={TagButtonType.DESKTOP}
                      disabled={true}
                    />
                  )}
                  <div className={'top-grad'} style={{ width: window.innerWidth - 32 }}/>
                </div>
                <div
                  className={'mobile-main-text gray rest-description-text-wrapper'}
                  style={{ maxHeight: window.innerHeight * 0.85 - 300 }}
                >
                  {description}
                </div>
              </div>
              <div className={'mobile-subtext address-wrapper'}>
                {address}
                <div className={'bottom-grad'} style={{ width: window.innerWidth - 40}}/>
              </div>
              <StandardButton
                onClickAction={() => {
                  setOpened(false)
                  const divElement = document.getElementById('restaurant-info-card-wrapper');
                  if (divElement) {
                    const height = divElement.getBoundingClientRect().height;
                    window.scrollTo({
                      top: height + 2,
                      behavior: 'smooth',
                    });
                  }
                }}
                text={'К меню'}
                color={StandardButtonColor.GREEN}
                iconType={StandardButtonIconType.WHITE_DOWN_ARROW}
                iconPosition={StandardButtonIconPosition.BEFORE_TEXT}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop/>
      </Sheet>
    </>
  )
}

export default RestaurantInfoButton